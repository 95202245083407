import { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import { VOLUNTEER_NAME } from "components/WelcomeModal";
import { useVolunteersDetail } from "global-state/contexts/VolunteerContext";
import { splitFirstName } from "utils/getFirstName";


interface IScriptsProps {
  isScriptActive: boolean;
  voterName: any;
  candidateInfo: any;
  finalScript:string;
  selectedOption:string | undefined;
  setSelectedOption:React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ContactByPhoneScript = ({
  isScriptActive,
  voterName,
  candidateInfo,
  finalScript,
  selectedOption,
  setSelectedOption
}: IScriptsProps) => {

  // const volunteerName = localStorage.getItem(VOLUNTEER_NAME);
  const {fullName} = useVolunteersDetail();
 
  return (
    <div
      className={
        isScriptActive
          ? "contactAction-cardLeft scroll-y flex-1"
          : "contactAction-cardLeft scroll-y flex-0"
      }
    >
      <div className="card-header d-flex justify-content-between">
        <h4 className="card-title text-primary"> Script</h4>
      </div>

      <div className="card-body">
        <div className="card scroll-y script-wrapper textEditor">
          {selectedOption === "defaultScript" && (
            <>
              <p> Hi, is <span className="text-bg"> {voterName} </span> available? </p>
              <p>
                Hi,<span className="text-bg"> {voterName} </span>,my name is <span className="text-bg"> {fullName} </span> and I'm
                volunteering for
                <span className="text-bg"> {candidateInfo?.data?.name} </span> who is running for <span className="text-bg"> {candidateInfo?.data.position} </span>
                 , right here in{" "}
                <span className="text-bg"> {candidateInfo?.data.city} </span>. How are you doing today?
              </p>
              <p>
                The reason I'm calling today is because we have a really
                important election upcoming for <span className="text-bg"> {candidateInfo?.data?.position} </span>.{" "}
              </p>
            
              <p>Can we count on your support for <span className="text-bg">{candidateInfo?.data?.name}</span>?</p>
              <p>Thank you so much for your time today!</p>
            </>
          )}
          {selectedOption === "customScript" && (
            <>
              {
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(finalScript),
                  }}
                />
              }
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactByPhoneScript;