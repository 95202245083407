import { Link } from "react-router-dom";
import ByPhoneIcon from "../img/svg/ByPhoneIcon";
import DoorToDoorIcon from "../img/svg/DoorToDoorIcon";
import Confetti from "img/svg/confetti";


function Welcome() {
  return (
    <div className="wrapper">
      <main className="outer-container">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 mb-4">
            <h2 className="mb-2 text-center">
                    Welcome
                    <span className="welcome-text">
                      {" "}
                      Volunteer, Jenny !{" "}
                      {/* <span className="placeholder-glow">
                        <span className="placeholder col-3"></span>
                      </span> */}
                    </span>
                  </h2>
              <p className="text-center">Reach out to voters. Click either method to get started.</p>
            
            <div className="row mt-5">
              <div className="col-md-6">
                  <Link to="/template/voters/door-to-door" className="card card-flush shadow-2 card-contactVoter">
                      <div className="card-body">

                          <div className="iconBox">
                              <ByPhoneIcon />
                          </div>
                          <h4 className="mt-3 mb-0"> By Phone </h4>

                      </div>
                  </Link>

              </div>
              
              <div className="col-md-6">
                  <Link to="/template/voters/contact-by-phone" className="card card-flush shadow-2 card-contactVoter">
                      <div className="card-body">
                      <div className="iconBox">
                              <DoorToDoorIcon />
                          </div>

                          <h4 className="mt-3 mb-0">Door to Door </h4>

                      </div>
                  </Link>

              </div>
              
            </div>

            <Link to="/candidate/add-voter" className="btn btn-primary rounded-pill mt-3" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> Congratulations </Link> 
            
            </div>
          </div>

        </div>

              {/* Congratulations Modal */}
              <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
         
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body text-center px-4">
                <Confetti />
                <h2 className="mt-3"> Welcome, Volunteer </h2>
                
              </div>

              <div className="modal-footer justify-content-center pb-4">
                <Link to="" className="btn btn-primary rounded-pill"> Continue </Link>

              </div>
            </div>
          </div>
        </div>
      </main>

    </div>

  );
}

export default Welcome;
