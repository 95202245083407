import React, { useEffect, createRef, useRef } from "react";
import lottie from 'lottie-web';
import { Link } from "react-router-dom";
import animationData from "../../img/lotties/success.json";

interface Props {
  
    onClickModal: () => void;
  }

const VerificationDisabled = ( {onClickModal}: Props) => {
    const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(element.current) // add this
       lottie.loadAnimation({
         animationData,
         container: element.current,
         loop: false
       });
       return () => lottie.destroy(); 
  }, [element]); 
  return (
    <div className="card-body text-center pt-4">
      <img
        src={require("../../img/call-disabled.png")}
        width={56}
        height={56}
        alt="leave alert"
      />
      {/* <div className="lottee-container" ref={element} style={{width: 56 , height: 56}}> </div> */}
      
      <h2 className="mt-4"> Autodialer Deactivated </h2>
      <p className="mb-6 body-3 text-muted">
      You will no longer be able to use auto dialer services, but you may continue with manually contacting voters.
      </p>

      <div className="w-100">
        <Link to="" className="btn btn-primary btn-xs rounded-pill w-100" onClick={onClickModal}> Continue </Link>
      </div>
    </div>
  )
}

export default VerificationDisabled
