

const PhoneOutlineIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
<path d="M1 3.16667C1 2.24619 1.74619 1.5 2.66667 1.5H5.39937C5.75806 1.5 6.07651 1.72953 6.18994 2.06981L7.43811 5.81434C7.56926 6.20777 7.39115 6.63776 7.02022 6.82322L5.13917 7.76375C6.05771 9.80101 7.69898 11.4423 9.73625 12.3608L10.6768 10.4798C10.8622 10.1088 11.2922 9.93074 11.6857 10.0619L15.4302 11.3101C15.7705 11.4235 16 11.7419 16 12.1006V14.8333C16 15.7538 15.2538 16.5 14.3333 16.5H13.5C6.59644 16.5 1 10.9036 1 4V3.16667Z" stroke="#3B3B4F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default PhoneOutlineIcon
