

const EyeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M3.46647 0.83429C3.15405 0.521871 2.64752 0.521871 2.3351 0.83429C2.02268 1.14671 2.02268 1.65324 2.3351 1.96566L13.5351 13.1657C13.8475 13.4781 14.354 13.4781 14.6665 13.1657C14.9789 12.8532 14.9789 12.3467 14.6665 12.0343L13.4879 10.8558C14.718 9.8739 15.652 8.53686 16.1347 6.99995C15.1153 3.75428 12.0831 1.39998 8.50095 1.39998C7.2021 1.39998 5.97554 1.70951 4.89101 2.25884L3.46647 0.83429ZM6.87528 4.24311L8.08639 5.45421C8.21862 5.41884 8.3576 5.39998 8.50098 5.39998C9.38464 5.39998 10.101 6.11632 10.101 6.99998C10.101 7.14336 10.0821 7.28234 10.0467 7.41457L11.2579 8.62568C11.5394 8.14924 11.701 7.59348 11.701 6.99998C11.701 5.23266 10.2683 3.79998 8.50098 3.79998C7.90748 3.79998 7.35172 3.96155 6.87528 4.24311Z" fill="#0B45A5"/>
<path d="M10.464 12.3573L8.3005 10.1938C6.69288 10.0944 5.40654 8.80807 5.30716 7.20045L2.36872 4.26201C1.70129 5.05776 1.18588 5.98533 0.867188 7.00001C1.88661 10.2457 4.91883 12.6 8.50094 12.6C9.17826 12.6 9.83593 12.5158 10.464 12.3573Z" fill="#0B45A5"/>
</svg>
  )
}

export default EyeIcon
