export const getYearMonthDateTime = (date: string) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const monthIndex = dateObj.getMonth(); // Months are 0-indexed
    const day = dateObj.getDate();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const formattedMinutes =
      String(minutes).length === 1 ? "0" + minutes : minutes;
    // const seconds = dateObj.getUTCSeconds();
    const amPm = hours >= 12 ? "PM" : "AM";
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const formattedHours = hours % 12 || 12;
    return `${day} ${monthNames[monthIndex]}, ${year}-${formattedHours}:${formattedMinutes} ${amPm}`;
  };