import React from 'react'

const LocationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M6.63604 3.29131C10.1508 -0.223406 15.8492 -0.223407 19.364 3.29131C22.8787 6.80603 22.8787 12.5045 19.364 16.0192L13 22.3832L6.63604 16.0192C3.12132 12.5045 3.12132 6.80603 6.63604 3.29131ZM13 12.2267C14.4202 12.2267 15.5714 11.0754 15.5714 9.65527C15.5714 8.23511 14.4202 7.08385 13 7.08385C11.5798 7.08385 10.4286 8.23511 10.4286 9.65527C10.4286 11.0754 11.5798 12.2267 13 12.2267Z" fill="#0B45A5"/>
</svg>
  )
}

export default LocationIcon
