import { useLocation } from "react-router-dom";

const NavbarTitle = ({ title = "" }: { title: string }) => {

  const location = useLocation();
  const { pathname } = location;
  if (pathname.split("/").length > 3) {
    title = pathname.replace("/", " ");
  } else {
    title = pathname.replaceAll("/", " ");
  }

    return (
      <span className="page-title" aria-current="page">
        {title}
      </span>
    );
  };
  
  export default NavbarTitle;