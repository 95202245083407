import { Link } from "react-router-dom";

function Login() {
  return (
    <div className="wrapper bg-white">
      <main className="outer-container">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="formContents mt-2">
                <div className="formContents-header text-center">
                  <h1> Welcome back! </h1>
                  <p>
                  Enter your email address and password to log back in to your account.
                  </p>
                </div>
                <div className="formContents-body">
                  <form>
                    <div className="mb-3 required">
                      <label htmlFor="exampleInputEmail1" className="form-label">
                        Email{" "}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter your email"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                      {/* error msg */}
                      {/* <div id="emailHelp" className="form-text text-danger"> Email Not Found </div> */}
                    </div>
                    <div className="mb-3 required">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter your password"
                        id="exampleInputPassword1"
                      />
                    </div>
                    
                    <button
                      type="submit"
                      className="btn btn-primary w-100 rounded-pill mt-2"
                    >
                      {" "}
                      Login{" "}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-link w-100 rounded-pill mt-2"
                    >
                      {" "}
                      Forgot Password?{" "}
                    </button>
                    
                  
                  </form>

                
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

    </div>

  );
}

export default Login;
