import React from 'react'

const Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="146" height="36" viewBox="0 0 146 36" fill="none">
<path d="M15.4277 4.61322C16.3181 4.61322 17.0467 5.33369 17.0467 6.23227C17.0467 7.13085 16.3262 7.85132 15.4277 7.85132C14.5291 7.85132 13.8086 7.13085 13.8086 6.23227C13.8086 5.33369 14.5291 4.61322 15.4277 4.61322Z" fill="url(#paint0_linear_1501_2729)"/>
<path d="M12.8753 17.2366C13.0499 17.117 13.1351 16.9913 13.2394 16.8352C13.6121 16.2741 14.2491 15.91 14.9667 15.9048C16.0998 15.9057 17.0211 16.8194 17.0462 17.9558C17.0465 17.9703 17.0467 17.9848 17.0467 17.9994V18.0017C17.0476 19.1593 16.1184 20.0962 14.9702 20.0953C14.2468 20.0946 13.615 19.7237 13.2413 19.162L13.2411 19.1617C13.1417 19.0132 13.0613 18.8931 12.8967 18.7764C12.6314 18.5885 12.3679 18.5945 12.0848 18.601L12.0268 18.6022C11.9923 18.6029 11.9574 18.6034 11.9222 18.6034C11.0001 18.6026 10.2024 19.0313 9.68876 19.8002C9.1131 20.6674 8.12938 21.2301 7.02103 21.2381C5.24768 21.2366 3.81004 19.7864 3.80859 17.9973C3.80715 16.2083 5.24238 14.7605 7.01573 14.7619C8.13296 14.7628 9.10878 15.3361 9.68579 16.2043C10.2007 16.974 10.9991 17.404 11.9212 17.4048C11.9723 17.4048 12.0226 17.4059 12.0721 17.4069C12.3521 17.4129 12.6101 17.4183 12.8753 17.2366Z" fill="url(#paint1_linear_1501_2729)"/>
<path d="M17.0467 29.7678C17.0467 28.8692 16.3181 28.1487 15.4277 28.1487C14.5291 28.1487 13.8086 28.8692 13.8086 29.7678C13.8086 30.6663 14.5291 31.3868 15.4277 31.3868C16.3262 31.3868 17.0467 30.6663 17.0467 29.7678Z" fill="url(#paint2_linear_1501_2729)"/>
<path d="M11.7538 6.73436C11.2402 6.29261 10.4512 6.3545 10.0049 6.87154C9.55873 7.38859 9.6141 8.17677 10.1277 8.61855C10.3801 8.83569 10.6978 8.93332 11.0156 8.91656C11.4874 8.87954 11.9066 9.02642 12.2636 9.33341C12.7162 9.72276 12.9264 10.27 12.8537 10.864C12.7697 11.5324 13.0146 12.224 13.5549 12.6963C14.4254 13.4451 15.7431 13.3417 16.4995 12.4654C17.2558 11.589 17.1633 10.2727 16.2928 9.52391C15.7443 9.05219 15.0236 8.92082 14.3738 9.1025C13.7962 9.26222 13.2229 9.13559 12.7703 8.74624C12.4134 8.43925 12.214 8.04637 12.1728 7.57572C12.1511 7.26696 12.0149 6.95899 11.7538 6.73436Z" fill="url(#paint3_linear_1501_2729)"/>
<path d="M10.0049 29.1285C10.4512 29.6455 11.2402 29.7074 11.7538 29.2657C12.0149 29.0411 12.1511 28.7331 12.1728 28.4243C12.214 27.9537 12.4134 27.5608 12.7703 27.2538C13.2229 26.8645 13.7962 26.7378 14.3738 26.8975C15.0236 27.0792 15.7443 26.9479 16.2928 26.4761C17.1633 25.7274 17.2558 24.411 16.4995 23.5347C15.7431 22.6583 14.4254 22.555 13.5549 23.3037C13.0146 23.7761 12.7697 24.4677 12.8537 25.1361C12.9264 25.73 12.7162 26.2773 12.2636 26.6666C11.9066 26.9736 11.4874 27.1205 11.0156 27.0835C10.6978 27.0667 10.3801 27.1644 10.1277 27.3815C9.6141 27.8233 9.55873 28.6115 10.0049 29.1285Z" fill="url(#paint4_linear_1501_2729)"/>
<path d="M12.9682 13.9418C13.481 14.3844 13.5365 15.1739 13.0911 15.6917C12.6457 16.2095 11.858 16.2713 11.3452 15.8286C11.0931 15.6111 10.9496 15.3114 10.9191 14.9946C10.886 14.5225 10.6787 14.1296 10.3223 13.822C9.8703 13.4319 9.29796 13.3049 8.72128 13.4647C8.07266 13.6466 7.35243 13.5067 6.80538 13.0422C5.93612 12.292 5.84347 10.9735 6.59835 10.0959C7.35323 9.21827 8.66878 9.11508 9.53802 9.86527C10.0857 10.3379 10.3222 11.0313 10.2386 11.7008C10.1661 12.2957 10.3761 12.8439 10.8281 13.234C11.1845 13.5416 11.6025 13.6807 12.0741 13.6519C12.3827 13.6277 12.7074 13.7168 12.9682 13.9418Z" fill="url(#paint5_linear_1501_2729)"/>
<path d="M13.0911 20.3084C13.5365 20.8261 13.481 21.6156 12.9682 22.0582C12.7074 22.2833 12.3827 22.3724 12.0741 22.3482C11.6025 22.3194 11.1845 22.4584 10.8281 22.766C10.3761 23.1561 10.1661 23.7043 10.2386 24.2993C10.3222 24.9687 10.0857 25.6621 9.53802 26.1348C8.66878 26.885 7.35323 26.7818 6.59835 25.9042C5.84347 25.0266 5.93612 23.7081 6.80538 22.9579C7.35243 22.4934 8.07266 22.3535 8.72128 22.5353C9.29796 22.6951 9.8703 22.5681 10.3223 22.178C10.6787 21.8705 10.886 21.4775 10.9191 21.0055C10.9496 20.6887 11.0931 20.389 11.3452 20.1714C11.858 19.7288 12.6457 19.7906 13.0911 20.3084Z" fill="url(#paint6_linear_1501_2729)"/>
<path d="M20.5723 31.3868C19.6819 31.3868 18.9533 30.6663 18.9533 29.7678C18.9533 28.8692 19.6738 28.1487 20.5723 28.1487C21.4709 28.1487 22.1914 28.8692 22.1914 29.7678C22.1914 30.6663 21.4709 31.3868 20.5723 31.3868Z" fill="url(#paint7_linear_1501_2729)"/>
<path d="M23.1247 18.7634C22.9501 18.883 22.8649 19.0088 22.7606 19.1648C22.3879 19.726 21.7509 20.0901 21.0333 20.0953C19.9002 20.0943 18.9789 19.1807 18.9538 18.0442C18.9535 18.0297 18.9533 18.0152 18.9533 18.0006V17.9983C18.9524 16.8407 19.8816 15.9038 21.0298 15.9048C21.7532 15.9054 22.385 16.2763 22.7587 16.8381L22.7589 16.8384C22.8583 16.9868 22.9387 17.1069 23.1033 17.2236C23.3686 17.4116 23.6321 17.4055 23.9152 17.3991L23.9732 17.3978C24.0077 17.3971 24.0426 17.3966 24.0778 17.3966C24.9999 17.3974 25.7976 16.9687 26.3112 16.1999C26.8869 15.3327 27.8706 14.7699 28.979 14.7619C30.7523 14.7634 32.19 16.2137 32.1914 18.0027C32.1928 19.7917 30.7576 21.2396 28.9843 21.2381C27.867 21.2372 26.8912 20.6639 26.3142 19.7958C25.7993 19.0261 25.0009 18.596 24.0788 18.5953C24.0277 18.5952 23.9774 18.5941 23.9279 18.5931C23.6479 18.5872 23.3899 18.5817 23.1247 18.7634Z" fill="url(#paint8_linear_1501_2729)"/>
<path d="M18.9533 6.23228C18.9533 7.13085 19.6819 7.85132 20.5723 7.85132C21.4709 7.85132 22.1914 7.13085 22.1914 6.23228C22.1914 5.3337 21.4709 4.61323 20.5723 4.61323C19.6738 4.61323 18.9533 5.3337 18.9533 6.23228Z" fill="url(#paint9_linear_1501_2729)"/>
<path d="M24.2462 29.2657C24.7598 29.7074 25.5488 29.6455 25.9951 29.1285C26.4413 28.6114 26.3859 27.8233 25.8723 27.3815C25.6199 27.1643 25.3022 27.0667 24.9844 27.0835C24.5126 27.1205 24.0934 26.9736 23.7364 26.6666C23.2838 26.2773 23.0736 25.73 23.1463 25.136C23.2303 24.4677 22.9854 23.776 22.4451 23.3037C21.5746 22.5549 20.2569 22.6583 19.5005 23.5347C18.7442 24.411 18.8367 25.7274 19.7072 26.4761C20.2557 26.9478 20.9764 27.0792 21.6262 26.8975C22.2038 26.7378 22.7771 26.8644 23.2297 27.2538C23.5866 27.5608 23.786 27.9537 23.8272 28.4243C23.8489 28.7331 23.9851 29.041 24.2462 29.2657Z" fill="url(#paint10_linear_1501_2729)"/>
<path d="M25.9951 6.87153C25.5488 6.35448 24.7598 6.29259 24.2462 6.73434C23.9851 6.95898 23.8489 7.26694 23.8272 7.5757C23.786 8.04636 23.5866 8.43924 23.2297 8.74623C22.7771 9.13557 22.2038 9.2622 21.6262 9.10249C20.9764 8.9208 20.2557 9.05217 19.7072 9.5239C18.8367 10.2726 18.7442 11.589 19.5005 12.4653C20.2569 13.3417 21.5746 13.4451 22.4451 12.6963C22.9854 12.224 23.2303 11.5324 23.1463 10.864C23.0736 10.27 23.2838 9.72274 23.7364 9.3334C24.0934 9.02641 24.5126 8.87953 24.9844 8.91654C25.3022 8.93331 25.6199 8.83567 25.8723 8.61853C26.3859 8.17675 26.4413 7.38857 25.9951 6.87153Z" fill="url(#paint11_linear_1501_2729)"/>
<path d="M23.0318 22.0582C22.519 21.6156 22.4635 20.8261 22.9089 20.3083C23.3543 19.7905 24.142 19.7288 24.6548 20.1714C24.9069 20.3889 25.0504 20.6887 25.0809 21.0054C25.114 21.4775 25.3213 21.8704 25.6777 22.178C26.1297 22.5681 26.702 22.6951 27.2787 22.5353C27.9273 22.3535 28.6476 22.4934 29.1946 22.9579C30.0639 23.7081 30.1565 25.0266 29.4017 25.9042C28.6468 26.7818 27.3312 26.8849 26.462 26.1348C25.9143 25.6621 25.6778 24.9687 25.7614 24.2992C25.8339 23.7043 25.6239 23.1561 25.1719 22.766C24.8155 22.4584 24.3975 22.3194 23.9259 22.3482C23.6173 22.3724 23.2926 22.2833 23.0318 22.0582Z" fill="url(#paint12_linear_1501_2729)"/>
<path d="M22.9089 15.6917C22.4635 15.1739 22.519 14.3844 23.0318 13.9418C23.2926 13.7167 23.6173 13.6276 23.9259 13.6518C24.3975 13.6807 24.8155 13.5416 25.1719 13.234C25.6239 12.8439 25.8339 12.2957 25.7614 11.7008C25.6778 11.0313 25.9143 10.3379 26.462 9.86525C27.3312 9.11506 28.6468 9.21825 29.4017 10.0958C30.1565 10.9734 30.0639 12.292 29.1946 13.0421C28.6476 13.5066 27.9273 13.6465 27.2787 13.4647C26.702 13.3049 26.1297 13.4319 25.6777 13.822C25.3213 14.1296 25.114 14.5225 25.0809 14.9946C25.0504 15.3114 24.9069 15.6111 24.6548 15.8286C24.142 16.2712 23.3543 16.2095 22.9089 15.6917Z" fill="url(#paint13_linear_1501_2729)"/>
<path d="M42.9472 21.7681C42.2233 21.7681 41.5519 21.6377 40.9329 21.3768C40.3233 21.1063 39.79 20.7343 39.3329 20.2609C38.8757 19.7778 38.5233 19.2222 38.2757 18.5942C38.0281 17.9565 37.9043 17.2754 37.9043 16.5507C37.9043 15.8261 38.0281 15.1498 38.2757 14.5218C38.5233 13.8841 38.8757 13.3285 39.3329 12.8551C39.79 12.3817 40.3233 12.0097 40.9329 11.7391C41.5519 11.4686 42.2233 11.3333 42.9472 11.3333C43.7567 11.3333 44.4567 11.4686 45.0472 11.7391C45.6376 12.0097 46.1614 12.3768 46.6186 12.8406L45.5043 13.971C45.2091 13.6425 44.8472 13.3865 44.4186 13.2029C43.9995 13.0193 43.5091 12.9275 42.9472 12.9275C42.4519 12.9275 41.9995 13.0145 41.59 13.1884C41.1805 13.3623 40.8233 13.6135 40.5186 13.942C40.2233 14.2609 39.9948 14.6425 39.8329 15.087C39.671 15.5314 39.59 16.0193 39.59 16.5507C39.59 17.0821 39.671 17.5701 39.8329 18.0145C39.9948 18.4589 40.2233 18.8454 40.5186 19.1739C40.8233 19.4928 41.1805 19.7391 41.59 19.9131C41.9995 20.087 42.4519 20.1739 42.9472 20.1739C43.5567 20.1739 44.071 20.0821 44.49 19.8986C44.9186 19.7053 45.2805 19.4445 45.5757 19.116L46.69 20.2464C46.2329 20.7198 45.6948 21.0918 45.0757 21.3623C44.4567 21.6329 43.7472 21.7681 42.9472 21.7681Z" fill="#0B45A5"/>
<path d="M51.0324 21.7681C50.4134 21.7681 49.8515 21.6087 49.3467 21.2899C48.8515 20.971 48.4562 20.5411 48.161 20C47.8753 19.4493 47.7324 18.8358 47.7324 18.1594C47.7324 17.4734 47.8753 16.8599 48.161 16.3189C48.4562 15.7681 48.8515 15.3333 49.3467 15.0145C49.8515 14.686 50.4134 14.5218 51.0324 14.5218C51.5562 14.5218 52.0181 14.6377 52.4181 14.8696C52.8277 15.0918 53.1515 15.401 53.3896 15.7971C53.6277 16.1932 53.7467 16.6425 53.7467 17.1449V19.1449C53.7467 19.6474 53.6277 20.0966 53.3896 20.4928C53.161 20.8889 52.842 21.2029 52.4324 21.4348C52.0229 21.657 51.5562 21.7681 51.0324 21.7681ZM51.2896 20.2609C51.8705 20.2609 52.3372 20.0628 52.6896 19.6667C53.0515 19.2705 53.2324 18.7633 53.2324 18.1449C53.2324 17.7295 53.1515 17.3623 52.9896 17.0435C52.8277 16.7246 52.5991 16.4783 52.3039 16.3044C52.0181 16.1208 51.68 16.029 51.2896 16.029C50.9086 16.029 50.5705 16.1208 50.2753 16.3044C49.9896 16.4783 49.761 16.7246 49.5896 17.0435C49.4277 17.3623 49.3467 17.7295 49.3467 18.1449C49.3467 18.5604 49.4277 18.9275 49.5896 19.2464C49.761 19.5652 49.9896 19.8164 50.2753 20C50.5705 20.1739 50.9086 20.2609 51.2896 20.2609ZM53.1324 21.6232V19.7536L53.4039 18.058L53.1324 16.3768V14.6667H54.7039V21.6232H53.1324Z" fill="#0B45A5"/>
<path d="M56.8764 21.6232V14.6667H58.4478V21.6232H56.8764ZM61.4335 21.6232V17.4783C61.4335 17.0145 61.2907 16.657 61.005 16.4058C60.7193 16.1449 60.3669 16.0145 59.9478 16.0145C59.6621 16.0145 59.405 16.0725 59.1764 16.1884C58.9574 16.3044 58.7812 16.4734 58.6478 16.6957C58.5145 16.9082 58.4478 17.1691 58.4478 17.4783L57.8335 17.1304C57.8335 16.599 57.9478 16.1401 58.1764 15.7536C58.405 15.3672 58.7145 15.0676 59.105 14.8551C59.4954 14.6329 59.9335 14.5218 60.4193 14.5218C60.905 14.5218 61.3431 14.628 61.7335 14.8406C62.124 15.0532 62.4335 15.3527 62.6621 15.7391C62.8907 16.1256 63.005 16.5894 63.005 17.1304V21.6232H61.4335ZM65.9907 21.6232V17.4783C65.9907 17.0145 65.8478 16.657 65.5621 16.4058C65.2764 16.1449 64.924 16.0145 64.505 16.0145C64.2288 16.0145 63.9764 16.0725 63.7478 16.1884C63.5193 16.3044 63.3383 16.4734 63.205 16.6957C63.0716 16.9082 63.005 17.1691 63.005 17.4783L62.1193 17.1304C62.1669 16.599 62.3193 16.1401 62.5764 15.7536C62.8335 15.3672 63.1621 15.0676 63.5621 14.8551C63.9716 14.6329 64.4193 14.5218 64.905 14.5218C65.4002 14.5218 65.8478 14.628 66.2478 14.8406C66.6478 15.0532 66.9669 15.3527 67.205 15.7391C67.4431 16.1256 67.5621 16.5894 67.5621 17.1304V21.6232H65.9907Z" fill="#0B45A5"/>
<path d="M73.2811 21.7681C72.7573 21.7681 72.2906 21.657 71.8811 21.4348C71.4716 21.2029 71.1478 20.8889 70.9097 20.4928C70.6811 20.0966 70.5668 19.6474 70.5668 19.1449V17.1449C70.5668 16.6425 70.6859 16.1932 70.9239 15.7971C71.162 15.401 71.4859 15.0918 71.8954 14.8696C72.3049 14.6377 72.7668 14.5218 73.2811 14.5218C73.9001 14.5218 74.4573 14.686 74.9525 15.0145C75.4573 15.3333 75.8525 15.7681 76.1382 16.3189C76.4335 16.8599 76.5811 17.4734 76.5811 18.1594C76.5811 18.8358 76.4335 19.4493 76.1382 20C75.8525 20.5411 75.4573 20.971 74.9525 21.2899C74.4573 21.6087 73.9001 21.7681 73.2811 21.7681ZM69.6097 24.5218V14.6667H71.1811V16.5362L70.9097 18.2319L71.1811 19.9131V24.5218H69.6097ZM73.0239 20.2609C73.4049 20.2609 73.7382 20.1739 74.0239 20C74.3192 19.8164 74.5478 19.5652 74.7097 19.2464C74.8811 18.9275 74.9668 18.5604 74.9668 18.1449C74.9668 17.7295 74.8811 17.3623 74.7097 17.0435C74.5478 16.7246 74.3192 16.4783 74.0239 16.3044C73.7382 16.1208 73.4049 16.029 73.0239 16.029C72.643 16.029 72.3049 16.1208 72.0097 16.3044C71.7144 16.4783 71.4859 16.7246 71.3239 17.0435C71.162 17.3623 71.0811 17.7295 71.0811 18.1449C71.0811 18.5604 71.162 18.9275 71.3239 19.2464C71.4859 19.5652 71.7144 19.8164 72.0097 20C72.3049 20.1739 72.643 20.2609 73.0239 20.2609Z" fill="#0B45A5"/>
<path d="M81.1965 21.7681C80.5774 21.7681 80.0155 21.6087 79.5108 21.2899C79.0155 20.971 78.6203 20.5411 78.3251 20C78.0394 19.4493 77.8965 18.8358 77.8965 18.1594C77.8965 17.4734 78.0394 16.8599 78.3251 16.3189C78.6203 15.7681 79.0155 15.3333 79.5108 15.0145C80.0155 14.686 80.5774 14.5218 81.1965 14.5218C81.7203 14.5218 82.1822 14.6377 82.5822 14.8696C82.9917 15.0918 83.3155 15.401 83.5536 15.7971C83.7917 16.1932 83.9108 16.6425 83.9108 17.1449V19.1449C83.9108 19.6474 83.7917 20.0966 83.5536 20.4928C83.3251 20.8889 83.006 21.2029 82.5965 21.4348C82.187 21.657 81.7203 21.7681 81.1965 21.7681ZM81.4536 20.2609C82.0346 20.2609 82.5013 20.0628 82.8536 19.6667C83.2155 19.2705 83.3965 18.7633 83.3965 18.1449C83.3965 17.7295 83.3155 17.3623 83.1536 17.0435C82.9917 16.7246 82.7632 16.4783 82.4679 16.3044C82.1822 16.1208 81.8441 16.029 81.4536 16.029C81.0727 16.029 80.7346 16.1208 80.4394 16.3044C80.1536 16.4783 79.9251 16.7246 79.7536 17.0435C79.5917 17.3623 79.5108 17.7295 79.5108 18.1449C79.5108 18.5604 79.5917 18.9275 79.7536 19.2464C79.9251 19.5652 80.1536 19.8164 80.4394 20C80.7346 20.1739 81.0727 20.2609 81.4536 20.2609ZM83.2965 21.6232V19.7536L83.5679 18.058L83.2965 16.3768V14.6667H84.8679V21.6232H83.2965Z" fill="#0B45A5"/>
<path d="M87.0548 21.6232V14.6667H88.6262V21.6232H87.0548ZM87.8405 13.3913C87.5738 13.3913 87.35 13.2995 87.169 13.116C86.9976 12.9324 86.9119 12.7053 86.9119 12.4348C86.9119 12.1643 86.9976 11.9372 87.169 11.7536C87.35 11.5701 87.5738 11.4783 87.8405 11.4783C88.1167 11.4783 88.3405 11.5701 88.5119 11.7536C88.6833 11.9372 88.769 12.1643 88.769 12.4348C88.769 12.7053 88.6833 12.9324 88.5119 13.116C88.3405 13.2995 88.1167 13.3913 87.8405 13.3913Z" fill="#0B45A5"/>
<path d="M93.6744 24.6667C92.9506 24.6667 92.3173 24.5314 91.7744 24.2609C91.2315 24 90.7934 23.628 90.4601 23.1449L91.4887 22.1015C91.7649 22.4493 92.0792 22.7102 92.4315 22.8841C92.7839 23.058 93.2077 23.1449 93.703 23.1449C94.322 23.1449 94.8125 22.9807 95.1744 22.6522C95.5363 22.3333 95.7173 21.8937 95.7173 21.3333V19.6232L95.9887 18.087L95.7173 16.5362V14.6667H97.2887V21.3333C97.2887 22 97.1363 22.5797 96.8315 23.0725C96.5268 23.5749 96.103 23.9662 95.5601 24.2464C95.0173 24.5266 94.3887 24.6667 93.6744 24.6667ZM93.603 21.4783C92.9934 21.4783 92.4411 21.3285 91.9458 21.029C91.4601 20.7198 91.0744 20.2995 90.7887 19.7681C90.5125 19.2367 90.3744 18.6425 90.3744 17.9855C90.3744 17.3285 90.5125 16.7391 90.7887 16.2174C91.0744 15.6957 91.4601 15.285 91.9458 14.9855C92.4411 14.6763 92.9934 14.5218 93.603 14.5218C94.1458 14.5218 94.622 14.6329 95.0315 14.8551C95.4411 15.0773 95.7601 15.3865 95.9887 15.7826C96.2173 16.1691 96.3315 16.6232 96.3315 17.1449V18.8551C96.3315 19.3672 96.2125 19.8213 95.9744 20.2174C95.7458 20.6135 95.4268 20.9227 95.0173 21.1449C94.6077 21.3672 94.1363 21.4783 93.603 21.4783ZM93.9173 19.971C94.2982 19.971 94.6315 19.8889 94.9173 19.7247C95.203 19.5604 95.422 19.3333 95.5744 19.0435C95.7363 18.744 95.8173 18.3961 95.8173 18C95.8173 17.6039 95.7363 17.2609 95.5744 16.971C95.422 16.6715 95.203 16.4396 94.9173 16.2754C94.6315 16.1111 94.2982 16.029 93.9173 16.029C93.5363 16.029 93.1982 16.1111 92.903 16.2754C92.6173 16.4396 92.3934 16.6715 92.2315 16.971C92.0696 17.2609 91.9887 17.6039 91.9887 18C91.9887 18.3865 92.0696 18.7295 92.2315 19.029C92.3934 19.3285 92.6173 19.5604 92.903 19.7247C93.1982 19.8889 93.5363 19.971 93.9173 19.971Z" fill="#0B45A5"/>
<path d="M104.163 21.6232V17.6087C104.163 17.1449 104.015 16.7633 103.72 16.4638C103.434 16.1643 103.063 16.0145 102.606 16.0145C102.301 16.0145 102.03 16.0821 101.791 16.2174C101.553 16.3527 101.368 16.5411 101.234 16.7826C101.101 17.0242 101.034 17.2995 101.034 17.6087L100.42 17.2609C100.42 16.7295 100.534 16.2609 100.763 15.8551C100.991 15.4396 101.306 15.116 101.706 14.8841C102.115 14.6425 102.572 14.5218 103.077 14.5218C103.591 14.5218 104.049 14.657 104.449 14.9275C104.849 15.1884 105.163 15.5314 105.391 15.9565C105.62 16.372 105.734 16.8068 105.734 17.2609V21.6232H104.163ZM99.4629 21.6232V14.6667H101.034V21.6232H99.4629Z" fill="#0B45A5"/>
<path d="M112.391 21.6232V20.1739H115.263C115.767 20.1739 116.158 20.0145 116.434 19.6957C116.71 19.3768 116.848 19.0097 116.848 18.5942C116.848 18.314 116.786 18.0532 116.663 17.8116C116.539 17.5701 116.358 17.3768 116.12 17.2319C115.882 17.087 115.596 17.0145 115.263 17.0145H112.391V15.5652H115.02C115.448 15.5652 115.791 15.4541 116.048 15.2319C116.305 15.0097 116.434 14.6812 116.434 14.2464C116.434 13.8116 116.305 13.4831 116.048 13.2609C115.791 13.0387 115.448 12.9275 115.02 12.9275H112.391V11.4783H115.063C115.739 11.4783 116.301 11.6039 116.748 11.8551C117.196 12.1063 117.529 12.43 117.748 12.8261C117.967 13.2222 118.077 13.6474 118.077 14.1015C118.077 14.6329 117.929 15.1015 117.634 15.5073C117.348 15.9131 116.924 16.2416 116.363 16.4928L116.491 15.971C117.12 16.2319 117.61 16.599 117.963 17.0725C118.315 17.5459 118.491 18.1015 118.491 18.7391C118.491 19.2609 118.363 19.7391 118.105 20.1739C117.858 20.6087 117.486 20.9614 116.991 21.2319C116.505 21.4928 115.905 21.6232 115.191 21.6232H112.391ZM111.263 21.6232V11.4783H112.905V21.6232H111.263Z" fill="#0B45A5"/>
<path d="M120.364 21.6232V14.6667H121.935V21.6232H120.364ZM121.935 17.7536L121.392 17.4783C121.392 16.599 121.583 15.8889 121.964 15.3478C122.354 14.7971 122.94 14.5218 123.721 14.5218C124.064 14.5218 124.373 14.5846 124.649 14.7102C124.925 14.8358 125.183 15.0387 125.421 15.3189L124.392 16.3913C124.268 16.2561 124.13 16.1594 123.978 16.1015C123.825 16.0435 123.649 16.0145 123.449 16.0145C123.011 16.0145 122.649 16.1546 122.364 16.4348C122.078 16.715 121.935 17.1546 121.935 17.7536Z" fill="#0B45A5"/>
<path d="M129.272 21.7681C128.653 21.7681 128.091 21.6087 127.586 21.2899C127.091 20.971 126.696 20.5411 126.4 20C126.115 19.4493 125.972 18.8358 125.972 18.1594C125.972 17.4734 126.115 16.8599 126.4 16.3189C126.696 15.7681 127.091 15.3333 127.586 15.0145C128.091 14.686 128.653 14.5218 129.272 14.5218C129.796 14.5218 130.258 14.6377 130.658 14.8696C131.067 15.0918 131.391 15.401 131.629 15.7971C131.867 16.1932 131.986 16.6425 131.986 17.1449V19.1449C131.986 19.6474 131.867 20.0966 131.629 20.4928C131.4 20.8889 131.081 21.2029 130.672 21.4348C130.262 21.657 129.796 21.7681 129.272 21.7681ZM129.529 20.2609C130.11 20.2609 130.577 20.0628 130.929 19.6667C131.291 19.2705 131.472 18.7633 131.472 18.1449C131.472 17.7295 131.391 17.3623 131.229 17.0435C131.067 16.7246 130.839 16.4783 130.543 16.3044C130.258 16.1208 129.919 16.029 129.529 16.029C129.148 16.029 128.81 16.1208 128.515 16.3044C128.229 16.4783 128 16.7246 127.829 17.0435C127.667 17.3623 127.586 17.7295 127.586 18.1449C127.586 18.5604 127.667 18.9275 127.829 19.2464C128 19.5652 128.229 19.8164 128.515 20C128.81 20.1739 129.148 20.2609 129.529 20.2609ZM131.372 21.6232V19.7536L131.643 18.058L131.372 16.3768V14.6667H132.943V21.6232H131.372Z" fill="#0B45A5"/>
<path d="M135.13 21.6232V14.6667H136.702V21.6232H135.13ZM135.916 13.3913C135.649 13.3913 135.425 13.2995 135.244 13.116C135.073 12.9324 134.987 12.7053 134.987 12.4348C134.987 12.1643 135.073 11.9372 135.244 11.7536C135.425 11.5701 135.649 11.4783 135.916 11.4783C136.192 11.4783 136.416 11.5701 136.587 11.7536C136.759 11.9372 136.844 12.1643 136.844 12.4348C136.844 12.7053 136.759 12.9324 136.587 13.116C136.416 13.2995 136.192 13.3913 135.916 13.3913Z" fill="#0B45A5"/>
<path d="M143.593 21.6232V17.6087C143.593 17.1449 143.445 16.7633 143.15 16.4638C142.864 16.1643 142.493 16.0145 142.035 16.0145C141.731 16.0145 141.459 16.0821 141.221 16.2174C140.983 16.3527 140.797 16.5411 140.664 16.7826C140.531 17.0242 140.464 17.2995 140.464 17.6087L139.85 17.2609C139.85 16.7295 139.964 16.2609 140.193 15.8551C140.421 15.4396 140.735 15.116 141.135 14.8841C141.545 14.6425 142.002 14.5218 142.507 14.5218C143.021 14.5218 143.478 14.657 143.878 14.9275C144.278 15.1884 144.593 15.5314 144.821 15.9565C145.05 16.372 145.164 16.8068 145.164 17.2609V21.6232H143.593ZM138.893 21.6232V14.6667H140.464V21.6232H138.893Z" fill="#0B45A5"/>
<defs>
<linearGradient id="paint0_linear_1501_2729" x1="10.4276" y1="4.61322" x2="10.4276" y2="31.3868" gradientUnits="userSpaceOnUse">
<stop stopColor="#0B45A5"/>
<stop offset="1" stopColor="#97BBF8" stopOpacity="0.96"/>
</linearGradient>
<linearGradient id="paint1_linear_1501_2729" x1="10.4276" y1="4.61322" x2="10.4276" y2="31.3868" gradientUnits="userSpaceOnUse">
<stop stopColor="#0B45A5"/>
<stop offset="1" stopColor="#97BBF8" stopOpacity="0.96"/>
</linearGradient>
<linearGradient id="paint2_linear_1501_2729" x1="10.4276" y1="4.61322" x2="10.4276" y2="31.3868" gradientUnits="userSpaceOnUse">
<stop stopColor="#0B45A5"/>
<stop offset="1" stopColor="#97BBF8" stopOpacity="0.96"/>
</linearGradient>
<linearGradient id="paint3_linear_1501_2729" x1="10.4276" y1="4.61322" x2="10.4276" y2="31.3868" gradientUnits="userSpaceOnUse">
<stop stopColor="#0B45A5"/>
<stop offset="1" stopColor="#97BBF8" stopOpacity="0.96"/>
</linearGradient>
<linearGradient id="paint4_linear_1501_2729" x1="10.4276" y1="4.61322" x2="10.4276" y2="31.3868" gradientUnits="userSpaceOnUse">
<stop stopColor="#0B45A5"/>
<stop offset="1" stopColor="#97BBF8" stopOpacity="0.96"/>
</linearGradient>
<linearGradient id="paint5_linear_1501_2729" x1="10.4276" y1="4.61322" x2="10.4276" y2="31.3868" gradientUnits="userSpaceOnUse">
<stop stopColor="#0B45A5"/>
<stop offset="1" stopColor="#97BBF8" stopOpacity="0.96"/>
</linearGradient>
<linearGradient id="paint6_linear_1501_2729" x1="10.4276" y1="4.61322" x2="10.4276" y2="31.3868" gradientUnits="userSpaceOnUse">
<stop stopColor="#0B45A5"/>
<stop offset="1" stopColor="#97BBF8" stopOpacity="0.96"/>
</linearGradient>
<linearGradient id="paint7_linear_1501_2729" x1="25.5724" y1="31.3868" x2="25.5724" y2="4.61323" gradientUnits="userSpaceOnUse">
<stop stopColor="#0B45A5"/>
<stop offset="1" stopColor="#97BBF8" stopOpacity="0.96"/>
</linearGradient>
<linearGradient id="paint8_linear_1501_2729" x1="25.5724" y1="31.3868" x2="25.5724" y2="4.61323" gradientUnits="userSpaceOnUse">
<stop stopColor="#0B45A5"/>
<stop offset="1" stopColor="#97BBF8" stopOpacity="0.96"/>
</linearGradient>
<linearGradient id="paint9_linear_1501_2729" x1="25.5724" y1="31.3868" x2="25.5724" y2="4.61323" gradientUnits="userSpaceOnUse">
<stop stopColor="#0B45A5"/>
<stop offset="1" stopColor="#97BBF8" stopOpacity="0.96"/>
</linearGradient>
<linearGradient id="paint10_linear_1501_2729" x1="25.5724" y1="31.3868" x2="25.5724" y2="4.61323" gradientUnits="userSpaceOnUse">
<stop stopColor="#0B45A5"/>
<stop offset="1" stopColor="#97BBF8" stopOpacity="0.96"/>
</linearGradient>
<linearGradient id="paint11_linear_1501_2729" x1="25.5724" y1="31.3868" x2="25.5724" y2="4.61323" gradientUnits="userSpaceOnUse">
<stop stopColor="#0B45A5"/>
<stop offset="1" stopColor="#97BBF8" stopOpacity="0.96"/>
</linearGradient>
<linearGradient id="paint12_linear_1501_2729" x1="25.5724" y1="31.3868" x2="25.5724" y2="4.61323" gradientUnits="userSpaceOnUse">
<stop stopColor="#0B45A5"/>
<stop offset="1" stopColor="#97BBF8" stopOpacity="0.96"/>
</linearGradient>
<linearGradient id="paint13_linear_1501_2729" x1="25.5724" y1="31.3868" x2="25.5724" y2="4.61323" gradientUnits="userSpaceOnUse">
<stop stopColor="#0B45A5"/>
<stop offset="1" stopColor="#97BBF8" stopOpacity="0.96"/>
</linearGradient>
</defs>
</svg>
  )
}

export default Logo
