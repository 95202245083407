import React from "react";

const CampaignDetails = () => {
  return (
    <div className="campaign-details mt-5">
      <h4> Campaign Details </h4>
      <p className="body-4 text-muted">View the candidate and campaign details</p>

      <hr className="mt-3 mb-3" />
      <div className="row">
        <div className="col-md-4">
          <span className="body-2 fs-7"> Candidate Name </span>
        </div>
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-8">
              <span className="body-4"> Elenor Parker </span>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-3 mb-3" />
      <div className="row">
        <div className="col-md-4">
          <span className="body-2 fs-7"> Candidate Position </span>
        </div>
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-8">
              <span className="body-4"> City Counselor </span>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-3 mb-3" />
      <div className="row">
        <div className="col-md-4">
          <span className="body-2 fs-7"> Election Date </span>
        </div>
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-8">
              <span className="body-4"> 11/7/23 </span>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-3 mb-3" />
    </div>
  );
};

export default CampaignDetails;
