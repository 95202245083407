export const replaceMultipleWords = (
    inputString: string | undefined,
    replacements: { [key: string]: string | undefined }
  ): string => {
    let result = inputString || "";
    for (const [wordToReplace, replacement] of Object.entries(replacements)) {
      // Check if the replacement is defined
      if (replacement !== undefined) {
        // Create a regular expression to match the word to be replaced
        // const regex = new RegExp("\\b" + wordToReplace + "\\b", "gi");
        const regex = new RegExp(`(?<=<select[^>]*>\\s*<option>\\s*)${wordToReplace}(?=\\s*<\/option>\\s*<\/select>)`, "gi");
        // Replace all occurrences of the word with the replacement
        result = result.replace(regex, replacement);
      }
    }
    return result;
  };