import { NavLink } from "react-router-dom";

const SideNavbar: React.FC = () => {
  const handleMiniSidenav = () => {
    document.body.classList.toggle("mini-sidenav");
  };

  return (
    <div className="side-navbar active-nav sidenav-verticle" id="sidebarNav">
      <a href="/" className="sidebar-brand mb-lg-5">
        {" "}
      </a>
      <button
        className="btn p-0 sidebar-toggle d-none d-lg-block"
        onClick={handleMiniSidenav}
      ></button>
      <ul className="nav flex-column text-white w-100 mt-4">
        <li>
          <NavLink
            to="/by-phone"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            <span className="sidebar-link">
              <div className="link-title">
                <span className="nav-icon icon-home-1x"> </span>
                <span className="link-text"> By Phone </span>
              </div>
            </span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/door-to-door"
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            <span className="sidebar-link">
              <div className="link-title">
                <span className="nav-icon icon-dollar"> </span>
                <span className="link-text"> Door to Door </span>
              </div>
            </span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SideNavbar;
