import React from 'react'

const InfoIconDanger = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <path d="M5 10C6.32608 10 7.59785 9.47322 8.53553 8.53553C9.47322 7.59785 10 6.32608 10 5C10 3.67392 9.47322 2.40215 8.53553 1.46447C7.59785 0.526784 6.32608 0 5 0C3.67392 0 2.40215 0.526784 1.46447 1.46447C0.526784 2.40215 0 3.67392 0 5C0 6.32608 0.526784 7.59785 1.46447 8.53553C2.40215 9.47322 3.67392 10 5 10ZM4.25 7.25C4.25 7.05109 4.32902 6.86032 4.46967 6.71967C4.61032 6.57902 4.80109 6.5 5 6.5C5.19891 6.5 5.38968 6.57902 5.53033 6.71967C5.67098 6.86032 5.75 7.05109 5.75 7.25C5.75 7.44891 5.67098 7.63968 5.53033 7.78033C5.38968 7.92098 5.19891 8 5 8C4.80109 8 4.61032 7.92098 4.46967 7.78033C4.32902 7.63968 4.25 7.44891 4.25 7.25ZM4.508 2.41C4.52887 2.29472 4.58957 2.19043 4.67949 2.11533C4.76941 2.04022 4.88284 1.99908 5 1.99908C5.11716 1.99908 5.23059 2.04022 5.32051 2.11533C5.41043 2.19043 5.47113 2.29472 5.492 2.41L5.5 2.5V5L5.492 5.09C5.47113 5.20528 5.41043 5.30957 5.32051 5.38468C5.23059 5.45978 5.11716 5.50092 5 5.50092C4.88284 5.50092 4.76941 5.45978 4.67949 5.38468C4.58957 5.30957 4.52887 5.20528 4.508 5.09L4.5 5V2.5L4.508 2.41Z" fill="#DE3715"/>
    </svg>
  )
}

export default InfoIconDanger
