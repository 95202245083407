import React from 'react'

const Calling = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M13.9431 2.46291C13.9068 2.37511 13.853 2.2928 13.7819 2.22126C13.7809 2.2202 13.7798 2.21914 13.7787 2.21808C13.6431 2.08329 13.4563 2 13.25 2H10.25C9.83579 2 9.5 2.33579 9.5 2.75C9.5 3.16421 9.83579 3.5 10.25 3.5H11.4393L8.96967 5.96967C8.67678 6.26256 8.67678 6.73744 8.96967 7.03033C9.26256 7.32322 9.73744 7.32322 10.0303 7.03033L12.5 4.56066V5.75C12.5 6.16421 12.8358 6.5 13.25 6.5C13.6642 6.5 14 6.16421 14 5.75V2.75C14 2.64831 13.9798 2.55134 13.9431 2.46291Z" fill="#2E78F1"/>
<path d="M2 2.75C2 2.33579 2.33579 2 2.75 2H4.36465C4.73128 2 5.04418 2.26506 5.10445 2.6267L5.65893 5.95361C5.71306 6.27838 5.54904 6.60048 5.25455 6.74773L4.09344 7.32828C4.93067 9.40874 6.59126 11.0693 8.67172 11.9066L9.25227 10.7455C9.39952 10.451 9.72162 10.2869 10.0464 10.3411L13.3733 10.8955C13.7349 10.9558 14 11.2687 14 11.6353V13.25C14 13.6642 13.6642 14 13.25 14H11.75C6.36522 14 2 9.63478 2 4.25V2.75Z" fill="#2E78F1"/>
</svg>
  )
}

export default Calling
