import PhoneDropped from "img/svg/PhoneDropped";

interface ICallEndedProps {
  isManuallyHungUp: boolean
  totalCallDuration: string | null
}

const CallEnded = ({ isManuallyHungUp, totalCallDuration }: ICallEndedProps) => {
  
  return (
    <>
      <div className="callBox-status">
        <PhoneDropped />
        <div className="callBox-status-body">
          <div className="callBox-status-text"> Call Ended</div>

          {!isManuallyHungUp &&
            <div className="callBox-status-duration">
              {totalCallDuration ??
                <>
                  {/*  if not connected to call */}
                  <span className="text-danger"> Couldn’t connect </span>
                </>
              }
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default CallEnded;


