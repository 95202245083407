import InfoIconSecondaryOutline from "img/svg/InfoIconSecondaryOutline";
import { Tooltip } from "bootstrap";
import { useEffect } from "react";
import { Link } from "react-router-dom";

interface Props {
  onClickModal: () => void;
}
const PHONE_NOT_VERIFIED_TOOLTIP = `Verifying your phone will allow you to reach more voters per hour by removing the need to manually dial each phone number.`;

const VerifyNumber = ({ onClickModal }: Props) => {

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  return (
    <div className="d-flex align-items-center gap-1 bg-white shadow-2 px-2 py-1 rounded-1">
      <Link
        to=""
        data-bs-html="true"
        data-bs-custom-class="custom-tooltip"
        className="text-left"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        data-bs-title={PHONE_NOT_VERIFIED_TOOLTIP}
      >
        <InfoIconSecondaryOutline />
      </Link>
      <span className="body-4 mb-0"> Phone no. unverified. </span>
      <button
        className="btn btn-link no-hover btn-xs px-0 py-0"
        onClick={onClickModal}
      >
        Verify now
      </button>
    </div>
  );
};

export default VerifyNumber;
