import { useQuery } from "@tanstack/react-query";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateAuthAxiosInstance from "utils/authAxios";

interface IVerificationCodeForm {
  cleanAndCloseTwilioCard: () => void
  handleRetryValidation: () => void
  setStep: React.Dispatch<React.SetStateAction<string>>
  phoneNumber: string | null
  validationCode: string | null
}

const VerificationCodeForm = ({
  cleanAndCloseTwilioCard,
  handleRetryValidation,
  setStep,
  phoneNumber,
  validationCode
}: IVerificationCodeForm) => {
  const authAxios = CreateAuthAxiosInstance()

  const [statusCallCount, setStatusCallCount] = useState<number>(0)
  const maxCallCount = 18

  const {
    data: verificationStatusData,
    isLoading,
    isFetching,
    refetch
  } = useQuery({
    queryKey: ['volunteerPhoneNumberVerificationStatus'],
    queryFn: () => authAxios.get(
      `${process.env.REACT_APP_COMMUNICATION_BASE_URL}/api/v1/phone-numbers/volunteer/verification-status`
    ),
    enabled: false
  })

  useEffect(() => {
    const fetchedVerificationStatus = verificationStatusData?.data?.verificationStatus         //pending, failed, verified, undefined
    if (fetchedVerificationStatus === 'verified' || fetchedVerificationStatus === 'failed') {
      setStep(fetchedVerificationStatus === 'verified' ? 'success' : 'failed')
    }
  }, [verificationStatusData, isLoading, isFetching])

  useEffect(() => {
    refetch()
    const intervalId = setInterval(() => {
      if (statusCallCount < maxCallCount) {
        setStatusCallCount(prev => prev + 1);
      } else {
        setStep('failed')
        clearInterval(intervalId);
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
      if (verificationStatusData) queryClient.removeQueries({ queryKey: ['volunteerPhoneNumberVerificationStatus'], exact: true })
    }
  }, [statusCallCount])

  return (
    <>
      <div className="card-title mb-0">
        <span className="body-2">Validation code</span>
        <h4></h4>
        <p className="body-3">
          Campaign Brain will call {phoneNumber} for phone number
          verification. Input the code to verify.
        </p>
        <button
          className="btn btn-link btn-xs no-hover py-2 px-0"
          onClick={handleRetryValidation}
        >
          Edit number
        </button>
      </div>
      <div className="card-body">
        <form action="">
          <div className="d-flex gap-2 px-5 pb-4">
            {!!(validationCode?.trim()) &&
              stringToArray(validationCode)?.map((digit, index) => (
                <input
                  className="form-control bg-grey"
                  key={index}
                  type="text"
                  maxLength={1}
                  value={digit}
                  disabled
                />
              ))}
          </div>

          <CountDownComponent setStep={setStep} />

          <div className="px-4 pt-4 text-center">
            <p className="text-muted fs-8">
              If you do not want to verify the phone now, <br />
              please click on &nbsp;
              <Link
                to=""
                onClick={cleanAndCloseTwilioCard}
              >
                Back
              </Link>
              &nbsp;to continue without verification
            </p>
          </div>
        </form>
      </div>
    </>
  );
};

export default VerificationCodeForm;

const stringToArray = (input: string) => input.trim().split('', 6)

const formattedCountDown = (retryCountdown: number) => {
  const minutes = Math.floor(retryCountdown / 60);
  const seconds = retryCountdown % 60;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

  return `${minutes}:${formattedSeconds}`;
};

const CountDownComponent = ({
  setStep
}: {
  setStep: React.Dispatch<React.SetStateAction<string>>
}) => {
  const [retryCountdown, setRetryCountdown] = useState<number>(60);

  useEffect(() => {
    const retryCountDownID = setInterval(() => {
      if (retryCountdown > 0) {
        setRetryCountdown(prev => prev - 1)
      }
      else {
        clearInterval(retryCountDownID)
      }
    }, 1000)

    return () => clearInterval(retryCountDownID)
  }, [retryCountdown])

  return (
    <div className="text-center">
      <p className="body-4 text-muted">
        Didn’t get a call?&nbsp;
        {retryCountdown > 0 ? (
          <span>Retry in {formattedCountDown(retryCountdown)}</span>
        ) : (
          <Link to=""
            onClick={() => setStep('retry')}
          >
            Retry
          </Link>
        )}
      </p>
    </div>
  )
}



