import React from 'react'

const VoiceMail = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="7" viewBox="0 0 14 7" fill="none">
    <path d="M3.22266 6.66699C2.38932 6.66699 1.6811 6.37544 1.09799 5.79233C0.514878 5.20921 0.223101 4.50077 0.222656 3.66699C0.222656 2.83366 0.514434 2.12544 1.09799 1.54233C1.68155 0.959214 2.38977 0.667437 3.22266 0.666992C4.05599 0.666992 4.76443 0.95877 5.34799 1.54233C5.93154 2.12588 6.2231 2.8341 6.22266 3.66699C6.22266 3.96699 6.17821 4.25588 6.08932 4.53366C6.00043 4.81144 5.87821 5.0781 5.72266 5.33366H8.05599C7.90043 5.0781 7.77821 4.81144 7.68932 4.53366C7.60043 4.25588 7.55599 3.96699 7.55599 3.66699C7.55599 2.83366 7.84777 2.12544 8.43132 1.54233C9.01488 0.959214 9.7231 0.667437 10.556 0.666992C11.3893 0.666992 12.0978 0.95877 12.6813 1.54233C13.2649 2.12588 13.5564 2.8341 13.556 3.66699C13.556 4.50033 13.2644 5.20877 12.6813 5.79233C12.0982 6.37588 11.3898 6.66744 10.556 6.66699H3.22266ZM3.22266 5.33366C3.68932 5.33366 4.08377 5.17255 4.40599 4.85033C4.72821 4.5281 4.88932 4.13366 4.88932 3.66699C4.88932 3.20033 4.72821 2.80588 4.40599 2.48366C4.08377 2.16144 3.68932 2.00033 3.22266 2.00033C2.75599 2.00033 2.36154 2.16144 2.03932 2.48366C1.7171 2.80588 1.55599 3.20033 1.55599 3.66699C1.55599 4.13366 1.7171 4.5281 2.03932 4.85033C2.36154 5.17255 2.75599 5.33366 3.22266 5.33366ZM10.556 5.33366C11.0227 5.33366 11.4171 5.17255 11.7393 4.85033C12.0615 4.5281 12.2227 4.13366 12.2227 3.66699C12.2227 3.20033 12.0615 2.80588 11.7393 2.48366C11.4171 2.16144 11.0227 2.00033 10.556 2.00033C10.0893 2.00033 9.69488 2.16144 9.37266 2.48366C9.05043 2.80588 8.88932 3.20033 8.88932 3.66699C8.88932 4.13366 9.05043 4.5281 9.37266 4.85033C9.69488 5.17255 10.0893 5.33366 10.556 5.33366Z" fill="white"/>
    </svg>
  )
}

export default VoiceMail
