import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
// import toastNotify from "utils/toastNotify";

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  // if (!isAuthenticated) {
  //   toastNotify("success", "Email verified");
  // }
  useEffect(() => {
    (() => {
      // localStorage.clear();
      loginWithRedirect();
    })();
  }, [loginWithRedirect]);

  return <div></div>;
};

export default Login;
