import InfoIconSecondaryOutline from "img/svg/InfoIconSecondaryOutline";
import { Tooltip } from "bootstrap";
import { useEffect } from "react";
import { Link } from "react-router-dom";

interface Props {
  onClickModal: () => void;
}

const VerifyNumber = ({ onClickModal }: Props) => {
  const phoneNotVerifiedTooltip = `Verifying your phone will allow you to reach more voters per hour by removing the need to manually dial each phone number.`;

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  return (
    <div className="d-felx align-items-center bg-white shadow-2 px-2 py-1 rounded-1">
      <Link
        to=""
        data-bs-html="true"
        data-bs-custom-class="custom-tooltip"
        className="text-left"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        data-bs-title={phoneNotVerifiedTooltip}
      >
        {" "}
        <InfoIconSecondaryOutline />
      </Link>{" "}
      <span className="body-4 mb-0"> Phone no. unverified. </span>
      <button
        className="btn btn-link no-hover btn-xs px-0 py-0"
        onClick={onClickModal}
      >
        {" "}
        Verify now
      </button>
      {/* <span className="text-primary body-5">Your caller ID is (233) 509-3431 </span> */}
    </div>
  );
};

export default VerifyNumber;
