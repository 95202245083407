import React from 'react'

const PhoneFilledIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
<path d="M2.5 2.75C2.5 2.33579 2.83579 2 3.25 2H4.86465C5.23128 2 5.54418 2.26506 5.60445 2.6267L6.15893 5.95361C6.21306 6.27838 6.04904 6.60048 5.75455 6.74773L4.59344 7.32828C5.43067 9.40874 7.09126 11.0693 9.17172 11.9066L9.75227 10.7455C9.89952 10.451 10.2216 10.2869 10.5464 10.3411L13.8733 10.8955C14.2349 10.9558 14.5 11.2687 14.5 11.6353V13.25C14.5 13.6642 14.1642 14 13.75 14H12.25C6.86522 14 2.5 9.63478 2.5 4.25V2.75Z" fill="white"/>
</svg>
  )
}

export default PhoneFilledIcon
