import { GoogleMap, Marker, InfoWindowF } from "@react-google-maps/api";
import LocationIconSeconary from "img/svg/locationIconSecondary";
import { useState, useMemo, useEffect } from "react";
import {
  IInfoWindow,
  IVotersDetails,
  VOTERS_LIMIT,
  areArraysEqual,
} from "./DoortoDoor";

const iconBase = require("../../../../img/marker-circle.svg").default;
const iconBaseActive = require("../../../../img/marker-selected.svg").default;
const iconBaseSuccessActive =
  require("../../../../img/marker-success-selected.svg").default;

const Map = ({
  votersCoordinates,
  activeCoordinates,
  votersDetails,
  hasVisitedVoters,
  setActiveAddressIndex,
}: {
  votersCoordinates: number[][];
  votersDetails: IVotersDetails[][];
  activeCoordinates: number[];
  hasVisitedVoters: boolean[];
  setActiveAddressIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [mapReference, setMapReference] = useState<google.maps.Map>();

  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState<IInfoWindow>();

  const onLoad = (map: google.maps.Map) => {
    setMapReference(map);
  };

  const handleMarkerClick = (
    id: number,
    lat: number,
    lng: number,
    voters: IVotersDetails[]
  ) => {
    // mapReference?.setZoom(15.5);
    // mapReference?.getCenter();

    if (areArraysEqual([lat, lng], activeCoordinates)) {
      mapReference?.panTo({ lat, lng });
      setInfoWindowData(undefined);
      setActiveAddressIndex(-1);
    } else {
      mapReference?.panTo({ lat, lng });
      setInfoWindowData({ id, voters });
      setActiveAddressIndex(id);
    }
  };

  const handleMarkerHover = (id: number, voters: IVotersDetails[]) => {
    setInfoWindowData({ id, voters });
    setIsInfoWindowOpen(true);
  };

  const center = useMemo(
    () => ({
      lat: votersCoordinates.length ? votersCoordinates[0][0] : 42.310256,
      lng: votersCoordinates.length ? votersCoordinates[0][1] : -71.244859,
    }),
    [votersCoordinates]
  );

  useEffect(() => {
    if (activeCoordinates.length) {
      mapReference?.panTo({
        lat: activeCoordinates[0],
        lng: activeCoordinates[1],
      });
    }
  }, [activeCoordinates]);

  return (
    <GoogleMap
      zoom={13}
      center={center}
      mapContainerClassName="map-address"
      onLoad={onLoad}
      options={{
        mapId: "b6f67a5f5b0edd9f",
      }}
    >
      {/* <MarkerF position={center} /> */}

      {votersCoordinates.map(
        ([lat, lng], index) =>
            <Marker
              label={{
                text: String(index + 1),
                className: areArraysEqual([lat, lng], activeCoordinates)
                  ? "marker-label text-white fw-bold"
                  : "marker-label text-primary fw-bold",
              }}
              icon={{
                url:
                  hasVisitedVoters[index] &&
                  areArraysEqual([lat, lng], activeCoordinates)
                    ? iconBaseSuccessActive
                    : areArraysEqual([lat, lng], activeCoordinates)
                    ? iconBaseActive
                    : iconBase,
              }}
              onMouseOut={() => {
                setInfoWindowData(undefined);
                setIsInfoWindowOpen(false);
              }}
              onMouseOver={() => {
                const voters = votersDetails[index];
                handleMarkerHover(index, voters);
              }}
              onClick={() => {
                const voters = votersDetails[index];
                handleMarkerClick(index, lat, lng, voters);
              }}
              position={{ lat, lng }}
              key={index}
            >
              {isInfoWindowOpen && index === infoWindowData?.id && (
                <InfoWindowF>
                  <div>
                    {infoWindowData?.voters.map((info) => (
                      <div className="d-flex markerList gap-2">
                        <div className="markerList-icon">
                          <LocationIconSeconary />
                        </div>
                        <div className="markerList-body">
                          <span className="location body-5">
                            {" "}
                            {info.address}{" "}
                          </span>
                          <ul className="list-unstyled m-0">
                            <li className="markerList-item">
                              <span className="voter-name"> {info.name}</span>
                              <div className="voter-info">
                                <span>
                                  {" "}
                                  {info.sex}{" "}
                                  <span className="dot bg-neutral-n3 ms-1 me-1"></span>{" "}
                                  {info.age}{" "}
                                  <span className="dot bg-neutral-n3 ms-1 me-1"></span>{" "}
                                  {info.partyAffiliation}{" "}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </InfoWindowF>
              )}
            </Marker>
      )}
    </GoogleMap>
  );
};

export default Map;
