import "./styles/css/main.css";
import "./styles/css/campaignBrainFonts.css";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";
import DataRouter from "DataRouter";

function App() {
  return (
    <>
      <ToastContainer />
      <DataRouter />
    </>
  );
}

export default App;
