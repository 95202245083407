import React from 'react'

const ByPhoneIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="32" viewBox="0 0 40 32" fill="none">
<path d="M37.8911 18.1836L38.6388 17.323C39.1147 16.8464 39.382 16.2008 39.382 15.5277C39.382 14.8547 39.1147 14.2091 38.6388 13.7324C38.6133 13.707 36.6364 12.1885 36.6364 12.1885C36.1619 11.7376 35.5316 11.4865 34.8765 11.4874C34.2214 11.4883 33.5919 11.7412 33.1187 12.1934L31.5525 13.5109C30.2741 12.9827 29.1128 12.2075 28.1354 11.2299C27.1579 10.2524 26.3835 9.09164 25.8566 7.81442L27.1713 6.25576C27.6246 5.78338 27.8782 5.15475 27.8793 4.50055C27.8803 3.84635 27.6288 3.21689 27.177 2.74304C27.177 2.74304 25.6544 0.771748 25.629 0.746317C25.1602 0.275232 24.5245 0.00720401 23.8594 0.000143108C23.1943 -0.0069178 22.5531 0.247556 22.0743 0.708581L21.1294 1.52893C15.5468 7.99489 27.5682 19.9039 34.2584 19.6897C34.934 19.6936 35.6035 19.5624 36.2274 19.3037C36.8513 19.045 37.417 18.6641 37.8911 18.1836Z" fill="#26DAC2"/>
<path d="M37.755 9.0238C37.5371 9.0238 37.3281 8.93737 37.174 8.78353C37.0199 8.62968 36.9333 8.42103 36.9333 8.20346C36.9316 6.46344 36.2384 4.79518 35.006 3.5648C33.7736 2.33442 32.1027 1.64243 30.3598 1.64069C30.1419 1.64069 29.9329 1.55426 29.7788 1.40042C29.6247 1.24657 29.5381 1.03791 29.5381 0.820346C29.5381 0.602777 29.6247 0.394118 29.7788 0.240274C29.9329 0.0864291 30.1419 0 30.3598 0C32.5383 0.00238852 34.6269 0.867446 36.1674 2.40538C37.7078 3.9433 38.5743 6.0285 38.5767 8.20346C38.5767 8.42103 38.4901 8.62968 38.336 8.78353C38.1819 8.93737 37.9729 9.0238 37.755 9.0238ZM35.2899 8.20346C35.2899 6.89804 34.7705 5.64609 33.8459 4.72303C32.9213 3.79996 31.6673 3.28138 30.3598 3.28138C30.1419 3.28138 29.9329 3.36781 29.7788 3.52166C29.6247 3.6755 29.5381 3.88416 29.5381 4.10173C29.5381 4.3193 29.6247 4.52796 29.7788 4.6818C29.9329 4.83565 30.1419 4.92207 30.3598 4.92207C31.2315 4.92207 32.0675 5.26779 32.6839 5.88317C33.3003 6.49855 33.6465 7.33318 33.6465 8.20346C33.6465 8.42103 33.7331 8.62968 33.8872 8.78353C34.0413 8.93737 34.2503 9.0238 34.4682 9.0238C34.6862 9.0238 34.8952 8.93737 35.0493 8.78353C35.2034 8.62968 35.2899 8.42103 35.2899 8.20346Z" fill="#26DAC2"/>
<path d="M12.3075 15.7536C15.2206 15.7536 17.5821 13.329 17.5821 10.3381C17.5821 7.34725 15.2206 4.92267 12.3075 4.92267C9.39437 4.92267 7.03284 7.34725 7.03284 10.3381C7.03284 13.329 9.39437 15.7536 12.3075 15.7536Z" fill="#5688FF"/>
<path d="M0 32C0 25.0213 5.51024 19.3639 12.3075 19.3639C19.1047 19.3639 24.6149 25.0213 24.6149 32H0Z" fill="#5688FF"/>
</svg>
  )
}

export default ByPhoneIcon
