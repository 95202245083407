import { useState } from "react";
import VerificationNumberForm from "./components/VerificationNumberForm";
import VerificationCodeForm from "./components/VerificationCodeForm";
import VerificationSuccess from "./components/VerificationSuccess";
import VerificationFailed from "./components/VerificationFailed";
import VerificationRetry from "./components/VerificationRetry";

interface ITwilioCardProps {
  modalStatus: boolean;
  onClickModal: () => void;
}

enum VerificationStep {
  PHONE = 'phone',
  OTP = 'otp',
  SUCCESS = 'success',
  FAILED = 'failed',
  RETRY = 'retry'
}

const TwilioCard = ({ modalStatus, onClickModal }: ITwilioCardProps) => {
  const [step, setStep] = useState<string>(VerificationStep.PHONE)        //phone,otp,success,failed, retry
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null)
  const [validationCode, setValidationCode] = useState<string | null>(null)

  const handleRetryValidation = () => {
    setStep(VerificationStep.PHONE)
    setPhoneNumber(null)
    setValidationCode(null)
  }

  const cleanAndCloseTwilioCard = () => {
    handleRetryValidation()
    onClickModal()
  }

  return (
    <>
      <div className={modalStatus ? "modal fade show" : "modal fade"} style={{ zIndex: 2000 }}>
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="card card-flush card-twilio shadow-6">
              {step === VerificationStep.PHONE &&
                <VerificationNumberForm
                  cleanAndCloseTwilioCard={cleanAndCloseTwilioCard}
                  setStep={setStep}
                  setPhoneNumberFinal={setPhoneNumber}
                  setValidationCode={setValidationCode}
                />
              }
              {step === VerificationStep.OTP &&
                <VerificationCodeForm
                  cleanAndCloseTwilioCard={cleanAndCloseTwilioCard}
                  handleRetryValidation={handleRetryValidation}
                  setStep={setStep}
                  phoneNumber={phoneNumber}
                  validationCode={validationCode}
                />
              }
              {step === VerificationStep.SUCCESS &&
                <VerificationSuccess
                  cleanAndCloseTwilioCard={cleanAndCloseTwilioCard}
                />
              }
              {step === VerificationStep.FAILED &&
                <VerificationFailed
                  cleanAndCloseTwilioCard={cleanAndCloseTwilioCard}
                  handleRetryValidation={handleRetryValidation}
                />
              }
              {step === VerificationStep.RETRY &&
                <VerificationRetry
                  phoneNumber={phoneNumber}
                  setStep={setStep}
                  setValidationCode={setValidationCode}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwilioCard;




