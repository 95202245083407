import { useAuth0 } from "@auth0/auth0-react";
import TopNavbar from "components/common/navbar/TopNavbar";
import { IVolunteerData } from "components/WelcomeModal";
import SideNavbar from "components/common/navbar/SideNavbar";
import HomePage from "components/pages/HomePage";
import LogIn from "components/pages/LogIn";
import ContactbyPhone from "components/pages/voters/ContactByPhone/ContactbyPhone";
import DoortoDoor from "components/pages/voters/door-to-door/DoortoDoor";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import AccountDeleted from "templates/AccountDeleted";
import Login from "templates/Login";
import Welcome from "templates/Welcome";
import VolunteerProfile from "templates/profile/VolunteerProfile";
import ContactByPhone from "templates/voters/ContactByPhone";
import ContactByPhoneCall from "templates/voters/ContactByPhoneCall";
import DoorToDoor from "templates/voters/DoorToDoor";

const ProtectedRoutes = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  const IS_AUTHENTICATED = "isAuthenticated";

  const isAuthenticatedLocalStorage = localStorage.getItem(IS_AUTHENTICATED);
  const isAuthenticatedUser = isAuthenticatedLocalStorage
    ? JSON.parse(isAuthenticatedLocalStorage)
    : isAuthenticated;
  if (isAuthenticated) {
    localStorage.setItem(IS_AUTHENTICATED, JSON.stringify(true));
  }
  return isAuthenticatedUser ? <Outlet /> : <Navigate to="/login" />;
};

const AppLayout = () => {
  return (
    <>
      <TopNavbar />
      <SideNavbar />
      {/* <Outlet/> */}
    </>
  );
};

const DataRouter = () => {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <LogIn />,
    },

    {
      path: "/",
      element: (
        <>
          <HomePage />
        </>
      ),
    },
    // protected Routes
    {
      element: (
        <>
          <ProtectedRoutes />
          <AppLayout />
        </>
      ),

      children: [
        {
          path: "/by-phone",
          element: <ContactbyPhone />,
        },
        {
          path: "/door-to-door",
          element: <DoortoDoor />,
        },
        {
          path: "/template/login",
          element: <Login />,
        },
        {
          path: "/template/welcome",
          element: <Welcome />,
        },
        {
          path: "/template/voters/contact-by-phone",
          element: <ContactByPhone />,
        },
        {
          path: "/template/voters/door-to-door",
          element: <DoorToDoor />,
        },
        {
          path: "/template/profile",
          element: <VolunteerProfile />,
        },
        {
          path: "/template/account-deleted",
          element: <AccountDeleted />,
        },
        {
          path: "/template/voters/contact-by-phone-call",
          element: <ContactByPhoneCall />,
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
};
export default DataRouter;
