import SideNavbar from "components/common/navbar/SideNavbar";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import HelpIcon from "img/svg/helpIcon";
import PhoneOutlineIcon from "img/svg/phoneOutlineIcon";
import { Link } from "react-router-dom";
import EyeIcon from "img/svg/eyeIcon";
import WarningIcon from "img/svg/warningIcon";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getYearMonthDateTime } from "utils/getDateAndTime";
import {
  IVotersResponse,
  useContactByPhone,
  votersResponseFormSchema,
} from "./useContactByPhone";
import TwilioCard from "./components/twilio-phone-number-verification-modal/twilio-card/TwilioCard";
import CallerId from "./components/CallerId";
import VerifyNumber from "./components/VerifyNumber";
import { PhoneComponent } from "./components/phone-component/PhoneComponent";
import { useState } from "react";
import VerificationDisabled from "./components/twilio-phone-number-verification-modal/twilio-card/components/VerificationDisabled";
import ContactByPhoneScript from "./components/script/ContactByPhoneScript";
import { useVolunteersDetail } from "global-state/contexts/VolunteerContext";

const ContactbyPhone = () => {
  const {
    votersToContactByPhone,
    activeIndex,
    votersCount,
    isScriptActive,
    buttonText,
    voterStatusTooltip,
    losTooltip,
    submitRef,
    VOTER_STATUS,
    LEVEL_OF_SUPPORT,
    selectedVoterStatus,
    selectedLevelOfSupport,
    handleScript,
    setSelectedVoterStatus,
    setSelectedLevelOfSupport,
    submitVoterResponse,
    isActivePopup,
    autoDialerStatus,
    verifiedPhoneNumber,
    volunteerDetailQueryStatus,
    handleModalClick,
    handleCancelModal,
    handlePatchCallConcurrency,
    candidateInfo,
    finalScript,
    selectedOption,
    setSelectedOption
  } = useContactByPhone();

  const {
    register,
    handleSubmit,
    reset,
    // formState: { errors },
  } = useForm<IVotersResponse>({
    resolver: yupResolver(votersResponseFormSchema) as any,
  });

  const handleSkip = async () => {
    await submitVoterResponse({
      levelOfSupport: "",
      voterStatus: VOTER_STATUS[0],
      contactStatus: 'skipped'
    });
    reset();
  };


  const [parentCallSId, setParentCallSId] = useState<string | null>(null);
  const handleParentCallSId = (acceptCallSId: string | null) => {
    setParentCallSId(acceptCallSId);
  }


  return (
    <div className="d-flex align-items-stretch">
      <div className={isActivePopup ? "wrapper active-cont active-popup" : "wrapper active-cont"}>
        {volunteerDetailQueryStatus !== 'success' ? (
          <LoadingAnimation />
        ) : (
          <>
            {isActivePopup && (
              <>
                {(autoDialerStatus === null || autoDialerStatus === 'Rejected') && (
                  <TwilioCard
                    modalStatus={isActivePopup}
                    onClickModal={handleCancelModal}
                  />
                )}

                {(autoDialerStatus === 'Inactive' ||
                  autoDialerStatus === 'Disabled' ||
                  autoDialerStatus === 'Blocked'
                ) && (
                    <VerificationDisabled
                      modalStatus={isActivePopup}
                      onClickModal={handleCancelModal}
                    />
                  )}
              </>
            )}
          </>
        )}
        <main>
          <div className="container">
            {/* Daily goal updates */}
            <div className="row">
              <div className="col-lg-3 col-12">
                {autoDialerStatus !== 'Inactive' &&
                  autoDialerStatus !== 'Disabled' &&
                  autoDialerStatus !== 'Blocked' && (
                    <>
                      {autoDialerStatus === 'Integrated' ? (
                        <CallerId phoneNumber={verifiedPhoneNumber} />
                      ) : (
                        <VerifyNumber onClickModal={handleModalClick} />
                      )}
                    </>
                  )}
              </div>
              {/* Daily goal updates */}
              <div className="row justify-content-between mt-3">
                <div className="col-md-5">
                  <div className="alert px-0">
                    <div className="d-flex gap-4 voterGoal">
                      <div className="voter-goal-item">
                        <span className="ms-0">
                          <span className="dot bg-success-s2"> </span>
                          <span className="fw-bold">
                            {votersCount?.phoneCalls || 0}
                            &nbsp;
                            Voters Contacted By Phone
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="d-flex justify-content-end gap-2">
                    {autoDialerStatus !== 'Inactive' &&
                      autoDialerStatus !== 'Disabled' &&
                      autoDialerStatus !== 'Blocked' && (
                        <PhoneComponent
                          receiverId={votersToContactByPhone[activeIndex]?.id || null}
                          receiverName={votersToContactByPhone[activeIndex]?.name || null}
                          receiverType="voter"

                          autoDialerStatus={autoDialerStatus}
                          phoneNumber={votersToContactByPhone[activeIndex]?.phoneNumber || null}

                          parentCallSId={parentCallSId}
                          handleParentCallSId={handleParentCallSId}
                          submitVoterButtonRef={submitRef}
                          handlePatchCallConcurrency={handlePatchCallConcurrency}
                        />
                      )}
                    <button
                      className="btn btn-info btn-xs rounded-pill"
                      data-bs-toggle="modal"
                      data-bs-target="#skipModal"
                      type="button"
                      disabled={
                        selectedLevelOfSupport.length ||
                          selectedVoterStatus.length
                          ? true
                          : false
                      }
                    // onClick={() =>
                    //   votersToContactByPhone &&
                    //   setVoterId(votersToContactByPhone[activeIndex]?.id)
                    // }
                    >
                      Skip voter
                    </button>
                    <button
                      className="btn btn-primary rounded-pill btn-xs"
                      ref={submitRef}
                      type="submit"
                      onClick={() => {
                        // votersToContactByPhone &&
                        //   setVoterId(
                        //     votersToContactByPhone[activeIndex]?.id
                        //   );

                        if (submitRef.current) {
                          console.log("inside submit")
                          submitRef.current.click()
                        }
                      }}
                      disabled={
                        selectedLevelOfSupport.length ||
                          selectedVoterStatus.length
                          ? false
                          : true
                      }
                    >
                      Save and call next
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Scripts and Response */}
            <div className="row">
              <div className="col-md-3">
                <div className="card card-fullHeight mt-3 shadow-2">
                  <div className="card-body scroll-y">
                    <div className="basic-info">
                      <div className="avatar m-auto border-0">
                        <span className="name fw-bold">MM</span>
                      </div>

                      <p className="mb-0 mt-2 fw-bold text-center">

                        {votersToContactByPhone &&
                          votersToContactByPhone[activeIndex]?.name}
                      </p>
                      <p className="body-4 fw-bold text-center text-muted">
                        {votersToContactByPhone &&
                          votersToContactByPhone[activeIndex]?.sex}
                      </p>
                      <p className="mb-4 fw-bold text-center">

                        <PhoneOutlineIcon />
                        <span className="ms-2">
                          {(votersToContactByPhone &&
                            votersToContactByPhone[activeIndex]?.phoneNumber) ||
                            "Not Set"}
                        </span>
                      </p>
                    </div>
                    <hr className="mb-4"></hr>
                    {/* voter Details */}
                    <div className="voter-details">
                      <h4 className="body-5"> Voter Details </h4>
                      <div className="row">
                        <div className="col-4 text-muted d-flex">Address</div>
                        <div className="col d-flex align-items-center">
                          <span className="body-4">
                            {votersToContactByPhone &&
                              votersToContactByPhone[activeIndex]?.address}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 text-muted d-flex">Age</div>
                        <div className="col d-flex align-items-center">
                          <span className="body-4">
                            {votersToContactByPhone &&
                              votersToContactByPhone[activeIndex]?.age}
                          </span>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-4 text-muted d-flex">
                          Party Affiliation
                        </div>
                        <div className="col d-flex align-items-center">
                          <span className="body-4">
                            {votersToContactByPhone &&
                              votersToContactByPhone[activeIndex]
                                ?.partyAffiliation}
                          </span>
                        </div>
                      </div>
                    </div>
                    <h4 className="body-5"> Contact History </h4>
                    {votersToContactByPhone[activeIndex]?.voterContactHistories
                      ?.length > 0 ? (
                      votersToContactByPhone[
                        activeIndex
                      ]?.voterContactHistories.map((history, index) => (
                        <div className="contact-history" key={index}>
                          <div className="timeline timeline-sm">
                            {/* Assuming each contact has multiple voterContactHistories */}
                            {/* {contact.voterContactHistories?.map(
                              (history, historyIndex) => ( */}
                            <div className="timeline-item pb-4" key={index}>
                              <div className="timeline-step">
                                <span> </span>
                              </div>
                              <div className="timeline-content">
                                <h4 className="mb-1 body-4">
                                  Marked as
                                  <span className="text-success body-5">
                                    {/* Display status or levelOfSupport */}
                                    {history.status || history.levelOfSupport}
                                  </span>
                                </h4>
                                <p className="text-muted fs-8">
                                  Called on
                                  {
                                    getYearMonthDateTime(
                                      history.updatedAt
                                    ).split("-")[0]
                                  }
                                  <span className="dot bg-neutral-n3 ms-1">
                                  </span>
                                  {
                                    getYearMonthDateTime(
                                      history.updatedAt
                                    ).split("-")[1]
                                  }
                                </p>
                                <p className="text-muted fs-8">
                                  By {history.contactorType}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="d-flex gap-2 flex-row align-items-center">
                        <InfoIconSecondary />
                        <p className="m-0 text-muted fs-7">
                          No tasks available for today
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-9">
                <div className="contactAction flex-row card card-flush card-fullHeight mt-3 shadow-2">
                  {/* script */}
                  <ContactByPhoneScript isScriptActive={isScriptActive} voterName={votersToContactByPhone[activeIndex]?.name} candidateInfo={candidateInfo} finalScript={finalScript} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                  <div className={isScriptActive ? "card-divider" : ""}></div>
                  {/* voter response */}
                  <div className="contactAction-cardRight flex-1">
                    <div className="card-header">
                      <h4 className="card-title"> Voter Response</h4>
                      <button
                        className="btn btn-link btn-sm p-1 ps-0 d-flex align-items-center gap-1"
                        onClick={handleScript}
                      >
                        <EyeIcon /> {buttonText}
                      </button>
                    </div>
                    <form
                      onSubmit={handleSubmit(async (data) => {
                        console.log("form data data", data)
                        await submitVoterResponse(data);
                        setSelectedVoterStatus("");
                        setSelectedLevelOfSupport("");
                        reset();
                      })}
                    >
                      <div className="card-body">
                        <p className="body-4 text-muted">
                          Select either voter status or level of support
                        </p>

                        <div className="voter-status">
                          <div className="d-flex justify-content-between">
                            <label className="body-5"> Voter Status </label>
                            <div className="card-info">
                              <Link
                                to=""
                                data-bs-html="true"
                                data-bs-custom-class="custom-tooltip"
                                className="text-left"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title={voterStatusTooltip}
                              >
                                <HelpIcon />
                              </Link>
                            </div>
                          </div>
                          <div className="row">
                            {VOTER_STATUS.map((status, index) => (
                              <div className="col-md-6" key={index}>
                                <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                  <input
                                    type="radio"
                                    className="btn-check form-check-input"
                                    id={status}
                                    value={status}
                                    {...register("voterStatus")}
                                    autoComplete="off"
                                    checked={selectedVoterStatus === status}
                                    onClick={() => {
                                      reset();
                                      setSelectedLevelOfSupport("");
                                      status === selectedVoterStatus
                                        ? setSelectedVoterStatus("")
                                        : setSelectedVoterStatus(status);
                                    }}
                                  />
                                  <label
                                    className="btn btn-outline-primary w-100 ps-5 text-start"
                                    htmlFor={status}
                                  >
                                    {status}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="d-flex align-items-center py-3 mb-3">
                            <hr className="w-100" />
                            <div className="px-4 text-muted">or</div>
                            <hr className="w-100" />
                          </div>
                        </div>
                        <div className="level-of-support">
                          <div className="d-flex justify-content-between">
                            <label className="body-5"> Level of Support </label>
                            <div className="card-info">
                              <Link
                                to=""
                                data-bs-html="true"
                                data-bs-custom-class="custom-tooltip"
                                className="text-left"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title={losTooltip}
                              >
                                <HelpIcon />
                              </Link>
                            </div>
                          </div>
                          <div className="row">
                            {LEVEL_OF_SUPPORT.map((support, index) => (
                              <div className="col-md-6" key={index}>
                                <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                  <input
                                    type="radio"
                                    className="btn-check form-check-input"
                                    id={support}
                                    autoComplete="off"
                                    value={support}
                                    checked={support === selectedLevelOfSupport}
                                    {...register("levelOfSupport")}
                                    onClick={() => {
                                      reset();
                                      setSelectedVoterStatus("");
                                      support === selectedLevelOfSupport
                                        ? setSelectedLevelOfSupport("")
                                        : setSelectedLevelOfSupport(support);
                                    }}
                                  />
                                  <label
                                    className="btn btn-outline-primary w-100 ps-5 text-start"
                                    htmlFor={support}
                                  >
                                    {support}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <button ref={submitRef} type='submit' hidden />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* All attempted */}
            {/* <div className="row">
              <div className="card card-flush card-fullHeight mt-3 shadow-2">
                <div className="noData d-flex gap-2 align-items-center">
                    <SuccessIcon />
                    <h1 className="mt-6">All voters contacted </h1>
                    <p> <span className="fw-bold"> 10/10 </span> voters attempted today. Continue to complete other tasks</p>
                    <Link to="/dashboard" className="btn btn-primary rounded-pill px-5"> Continue to Homepage</Link>
                </div>
              </div>
            </div> */}
          </div>
        </main>
      </div>

      {/* Skip Modal */}
      <div
        className="modal fade"
        id="skipModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <WarningIcon />
              <h2 className="mt-3">
                Do you want to skip '
                {votersToContactByPhone &&
                  votersToContactByPhone[activeIndex]?.name}
                '?
              </h2>
              <p>
                By skipping, all details entered for this voter from this
                contact will be erased.
              </p>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-link btn-sm p-1 ps-0"
                data-bs-toggle="modal"
                data-bs-target="#skipModal"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary rounded-pill btn-xs"
                onClick={() => {
                  handleSkip();
                }}
                data-bs-dismiss="modal"
              >
                Yes, I Skip
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* when user go to next page without confirming Modal */}
      <div
        className="modal fade"
        id="leaveModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Do you want to leave this page </h2>
              <p>
                By leaving, all details entered for this voter from this contact
                will be erased.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link btn-sm p-1 ps-0"
                data-bs-toggle="modal"
                data-bs-target="#skipModal"
              >
                Cancel
              </button>
              <button className="btn btn-primary rounded-pill btn-xs">
                Yes, Leave
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactbyPhone;

const LoadingAnimation = () => {
  return (
    <div
      className="card-body"
      style={{
        zIndex: '100000',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
      }}>
      <div className="text-center px-5">
        <div className="loader-demo-box">
          <div className="jumping-dots-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <span className="body-4 fs-5">Loading...</span>
      </div>
    </div>
  )
}