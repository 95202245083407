const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.14835 1.15128C1.61698 0.682647 2.37677 0.682647 2.8454 1.15128L7.99687 6.30275L13.1483 1.15128C13.617 0.682647 14.3768 0.682647 14.8454 1.15128C15.314 1.61991 15.314 2.3797 14.8454 2.84833L9.69393 7.9998L14.8454 13.1513C15.314 13.6199 15.314 14.3797 14.8454 14.8483C14.3768 15.317 13.617 15.317 13.1483 14.8483L7.99687 9.69686L2.8454 14.8483C2.37677 15.317 1.61698 15.317 1.14835 14.8483C0.679718 14.3797 0.679718 13.6199 1.14835 13.1513L6.29982 7.9998L1.14835 2.84833C0.679718 2.3797 0.679718 1.61991 1.14835 1.15128Z"
        fill="#3B3B4F"
      />
    </svg>
  );
};

export default CloseIcon;
