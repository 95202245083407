import SideNavbar from "../../components/common/navbar/SideNavbar";
import { Link } from "react-router-dom";
import SidePanel from "templates/components/SidePanel";
import WarningIcon from "img/svg/warningIcon";

const DoorToDoor = () => {
  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />
      <SidePanel />
      <div className="wrapper active-cont">
        <main className="p-0">
          <div className="container-fluid p-0">
            <div className="map-wrapper">
              <div className="daily-address scrollToTo">
                <div className="voterGoal d-none d-lg-block">
                  <div className="voter-goal-item">
                    <span className="dot bg-success-s2"> </span>
                    <span className="fw-bold me-1"> 1 of 10</span>Daily door
                    goal
                  </div>
                  <div className="voter-goal-item">
                    <span className="dot bg-extended-e12"> </span>
                    <span className="fw-bold me-1">1 of 186</span>Weekly door
                    goal
                  </div>
                </div>

                {/* Address list */}
                <div className="voterAddress">
                  <div className="drag-control d-sm-block d-lg-none">
                    <div className="drag-control-item"></div>
                  </div>
                  <div className="voterAddress-header">
                    <span className="body-4 text-muted"> 10 Addresses </span>
                    <span className="dot bg-neutral-n3 ms-2"> </span>
                    <span className="body-4 text-muted"> 186 voters</span>
                  </div>
                  <div className="voterAddress-body">
                    <div className="voterAddress-body-item activ succes">
                      <div className="address-index">
                        <span className="icon-building"></span>
                      </div>
                     
                      <Link to="" className="address-content">
                        <div className="voter-address">
                          1586 1st Avenue #2A, New York
                        </div>
                        <div className="voter-count">3 voters </div>
                      </Link>
                    </div>
                    <div className="voterAddress-body-item">
                      <div className="address-index">2</div>
                      <Link
                        to=""
                        className="address-content"
                        data-bs-toggle="modal"
                        data-bs-target="#successModal"
                      >
                        <div className="voter-address">
                          1586 1st Avenue #2A, New York
                        </div>
                        <div className="voter-count">3 voters </div>
                      </Link>
                    </div>
                    <div className="voterAddress-body-item">
                      <div className="address-index">3</div>
                      <Link to="" className="address-content">
                        <div className="voter-address">
                          1586 1st Avenue #2A, New York
                        </div>
                        <div className="voter-count">3 voters </div>
                      </Link>
                    </div>
                    <div className="voterAddress-body-item">
                      <div className="address-index">4</div>
                      <Link to="" className="address-content">
                        <div className="voter-address">
                          1586 1st Avenue #2A, New York
                        </div>
                        <div className="voter-count">3 voters </div>
                      </Link>
                    </div>
                    <div className="voterAddress-body-item">
                      <div className="address-index">5</div>
                      <Link to="" className="address-content">
                        <div className="voter-address">
                          1586 1st Avenue #2A, New York
                        </div>
                        <div className="voter-count">3 voters </div>
                      </Link>
                    </div>
                    <div className="voterAddress-body-item">
                      <div className="address-index">6</div>
                      <Link to="" className="address-content">
                        <div className="voter-address">
                          1586 1st Avenue #2A, New York
                        </div>
                        <div className="voter-count">3 voters </div>
                      </Link>
                    </div>
                    <div className="voterAddress-body-item">
                      <div className="address-index">7</div>
                      <Link to="" className="address-content">
                        <div className="voter-address">
                          1586 1st Avenue #2A, New York
                        </div>
                        <div className="voter-count">3 voters </div>
                      </Link>
                    </div>
                    <div className="voterAddress-body-item">
                      <div className="address-index">7</div>
                      <Link to="" className="address-content">
                        <div className="voter-address">
                          1586 1st Avenue #2A, New York
                        </div>
                        <div className="voter-count">3 voters </div>
                      </Link>
                    </div>
                    <div className="voterAddress-body-item">
                      <div className="address-index">7</div>
                      <Link to="" className="address-content">
                        <div className="voter-address">
                          1586 1st Avenue #2A, New York
                        </div>
                        <div className="voter-count">3 voters </div>
                      </Link>
                    </div>
                    <div className="voterAddress-body-item">
                      <div className="address-index">7</div>
                      <Link to="" className="address-content">
                        <div className="voter-address">
                          1586 1st Avenue #2A, New York
                        </div>
                        <div className="voter-count">3 voters </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="map-address">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14132.466345175933!2d84.426752!3d27.682791299999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1692952960214!5m2!1sen!2snp"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen={true}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* if all door visited */}
      <div
        className="modal fade"
        id="successModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../img/success-modal.png")}
                width={56}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Great Work! </h2>
              <p>
                You have reached your daily door goal and knocked on{" "}
                <span className="fw-bold"> ‘10/10’ </span> doors. Continue to
                your next task for the day or add knocking more doors.
              </p>
            </div>

            <div className="modal-footer justify-content-end">
              <Link to="/" className="btn btn-link rounded-pill">
                Add more donor
              </Link>
              <Link to="/" className="btn btn-primary rounded-pill">
                Continue to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* If all doors visited but not talked to some voters */}
      <div
        className="modal fade"
        id="skipModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <WarningIcon />
              <h2 className="mt-3">
                {" "}
                Would you like to mark ‘20’ voters as ‘Not Home’?{" "}
              </h2>
              <p>
                <span className="fw-bold"> 156/186 </span> voters talked today.
              </p>
            </div>

            <div className="modal-footer justify-content-end">
              <Link to="/" className="btn btn-link rounded-pill">
                No, do nothing
              </Link>
              <Link to="/" className="btn btn-primary rounded-pill">
                Yes, continue
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* when user leave page  */}
      <div
        className="modal fade"
        id="leaveModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Do you want to leave this page? </h2>
              <p>
                By leaving the page, details filled in this page will be erased.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link btn-sm p-1 ps-0"
                data-bs-toggle="modal"
                data-bs-target="#skipModal"
              >
                Cancel
              </button>
              <button className="btn btn-primary rounded-pill btn-xs">
                Yes, Leave
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoorToDoor;
