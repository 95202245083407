import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import CreateAuthAxiosInstance from "utils/authAxios";
interface IVolunteersData {
    id: number;
    candidateId: number;
    fullName: string;
}

const VolunteerContext = createContext<IVolunteersData | undefined>(undefined)

let defaultVolunteerData: IVolunteersData = {
    id: 0,
    candidateId: 0,
    fullName: ""
}

export const VolunteerProvider = ({ children }: { children: ReactNode }) => {
    const authAxios = CreateAuthAxiosInstance();
    const {isAuthenticated} = useAuth0();

    const [volunteersData, setVolunteersData] = useState<IVolunteersData>(defaultVolunteerData)

    const {
        data: volunteerDetails,
        isPending: volunteerPending,
        refetch: refetchVolunteerDetails 
    } = useQuery({
        queryKey: ["volunteers-Detail"],
        queryFn: () => authAxios.get(`api/v1/volunteers/detail`).then(response => response.data).catch(error=> console.log("Error fetching volunteer details",error)),
        enabled:isAuthenticated,
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        if (volunteerDetails) {
            setVolunteersData({
                id: volunteerDetails?.id || 0,
                candidateId: volunteerDetails?.candidateId || 0,
                fullName: volunteerDetails?.fullName || ""
            })
        }
    }, [volunteerDetails, volunteerPending])

    useEffect(() => {
        if (!volunteerDetails && !volunteerPending) {
            refetchVolunteerDetails();
        }
    }, [volunteerDetails, volunteerPending, refetchVolunteerDetails])


    return (
        <VolunteerContext.Provider value={volunteersData}>
            {children}
        </VolunteerContext.Provider>
    )
}

export const useVolunteersDetail = () => {
    const context = useContext(VolunteerContext);
    if (context === undefined) {
        throw new Error("Context not found");
    }

    return context;
}