import { useAuth0 } from "@auth0/auth0-react";
import Confetti from "img/svg/confetti";
import { ElementRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

export interface IVolunteerData {
  id: number;
  candidateId: number;
  auth0Id?: string;
  email: string;
  fullName: string;
  roleId: number;
  isActive?: boolean;
  isArchived?: boolean;
  status?: string;
}

interface IWelcomeModal {
  setIsWelcomeModalClicked:React.Dispatch<React.SetStateAction<boolean>>;
}

export const CANDIDATE_ID = "userId";
export const VOLUNTEER_ID = "volunteerId";
export const VOLUNTEER_NAME = "volunteerName";

const WelcomeModal = ({setIsWelcomeModalClicked}:IWelcomeModal) => {
  const buttonRef = useRef<ElementRef<"button">>(null);

  useEffect(() => {
    buttonRef.current?.click();
  }, []);

  return (
    <>
      <button
        className="btn btn-primary d-none"
        ref={buttonRef}
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      >
        modal
      </button>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <Confetti />
              <h2 className="mt-3"> Welcome, Volunteer </h2>
            </div>
            <div className="modal-footer justify-content-center pb-4">
              <Link
                to=""
                className="btn btn-primary rounded-pill"
                data-bs-dismiss="modal"
                onClick={()=>setIsWelcomeModalClicked(true)}
              >
                {" "}
                Continue{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeModal;
