import InfoIconDanger from "img/svg/InfoIconDanger";
import CheckSuccess from "img/svg/checkSuccess";
import { toast } from "react-toastify";

const toastNotify = (status: string, msg: string) => {
  if (status === 'success') {
    if (!msg.length) msg = 'Success'

    toast.success(msg, {
      className: 'alert alert-success',
      position: toast.POSITION.TOP_RIGHT,
      theme: "light",
      hideProgressBar: true,
      autoClose: 1000,
      icon: <CheckSuccess />
    });
    return
  }

  if (status === 'error') {
    if (!msg?.length) msg = 'Error'

    toast.success(msg, {
      className: 'alert alert-danger',
      position: toast.POSITION.TOP_RIGHT,
      theme: "light",
      hideProgressBar: true,
      autoClose: 1000,
      icon: <InfoIconDanger />
    });
    return
  }

  if (status === 'connection-error') {
    if (!msg?.length) msg = 'Connection lost'

    toast.success(msg, {
      className: 'snackBar',
      position: toast.POSITION.TOP_RIGHT,
      theme: "light",
      hideProgressBar: true,
      autoClose: 1000,
      icon: <InfoIconDanger />
    });
    return
  }
}

export default toastNotify