import { useAuth0 } from "@auth0/auth0-react";

const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  // const env = process.env.REACT_APP_NODE_ENV;
  // let redirect_url = "http://localhost:3000/onboarding";
  // if (env === "dev") redirect_url = "https://dev.campaign-brain.com/onboarding";
  // if (env === "stage")
  //   redirect_url = "https://staging.campaign-brain.com/onboarding";

  return (
    <>
    <li className="nav-item">
      <button
        type="button"
        className="btn btn-link rounded-pill"
        style={{ color: "black" }}
        onClick={() => {
          localStorage.clear();
          loginWithRedirect();
        }}
      >
        Login
      </button>

    </li>

    <li className="nav-item d-none d-lg-block">
      <button
        type="button"
        className="btn btn-primary rounded-pill"
        onClick={() => {
          localStorage.clear();
          loginWithRedirect({
            authorizationParams: {
              screen_hint: "signup",
            },
          });
        }}
      >
        Create account
      </button>

    </li>

    </>
  );
};

export default LoginButton;
