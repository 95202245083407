// import VerificationCodeForm from "./VerificationCodeForm";
import VerificationDisabled from "./VerificationDisabled";
import VerificationFailed from "./VerificationFailed";
import VerificationNumberForm from "./VerificationNumberForm";
import VerificationSuccess from "./VerificationSuccess";

interface Props {
  modalStatus: boolean;
  onClickModal: () => void;
}

const TwilioCard = ({ modalStatus, onClickModal }: Props) => {
  return (
    <div className={modalStatus ? "modal fade show" : "modal fade"}>
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content">
          <div className="card card-flush card-twilio shadow-6">
          
           {/* <VerificationNumberForm onClickModal={onClickModal}/> */}
           {/* <VerificationCodeForm /> */}
           {/* <VerificationSuccess /> */}
           <VerificationDisabled onClickModal={onClickModal}/>
           {/* <VerificationFailed onClickModal={onClickModal}/> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwilioCard;
