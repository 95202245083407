import { ToastContainer, toast } from 'react-toastify';
import SideNavbar from "components/common/navbar/SideNavbar";
import PersonalDetails from "./personal-details/PersonalDetails";
import SecurityDetails from "./security/SecurityDetails";
import CheckSuccess from "img/svg/checkSuccess";
import CampaignDetails from './campaign-details/CampaignDetails';


const VolunteerProfile = () => {
  const notify = () => {
    // toast("Wow so easy!");

    toast.success("Changes saved", {
      className: 'alert alert-success',
      position: toast.POSITION.TOP_RIGHT,
      theme:"light",
      hideProgressBar:true,
      autoClose: 50000,
      icon: <CheckSuccess />
    });
  }

  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont bg-white">
        <main>
          <div className="container-fluid">
            {/* <button onClick={notify}> notify</button> */}
            <div className="row">
              <div className="col-md-8">
                <ul
                  className="nav nav-tabs nav-flush scroll-x nav-settings position-relative bg-transparent"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="personal-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#personal-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="personal-tab-pane"
                      aria-selected="true"
                    >
                      My account
                      
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="campaign-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#campaign-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="campaign-tab-pane"
                      aria-selected="true"
                    >
                      Campaign details
                      
                    </button>
                  </li>

                  
                 
                 

                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="security-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#security-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="security-tab-pane"
                      aria-selected="false"
                    >
                      Security
                      
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="personal-tab-pane"
                    role="tabpanel"
                    aria-labelledby="personal-tab"
                    tabIndex={0}
                  >
                    <PersonalDetails />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="campaign-tab-pane"
                    role="tabpanel"
                    aria-labelledby="campaign-tab"
                    tabIndex={0}
                  >
                    <CampaignDetails />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="security-tab-pane"
                    role="tabpanel"
                    aria-labelledby="story-tab"
                    tabIndex={0}
                  >
                    <SecurityDetails />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default VolunteerProfile;
