import { AxiosResponse } from "axios";
import { IVotersDetails } from "../DoortoDoor";
import DOMPurify from 'dompurify';
import { VOLUNTEER_NAME } from "components/WelcomeModal";
import { useVolunteersDetail } from "global-state/contexts/VolunteerContext";
import { splitFirstName } from "utils/getFirstName";
import { useContactByPhone } from "../../ContactByPhone/useContactByPhone";
import { useEffect, useState } from "react";
import { replaceMultipleWords } from "utils/replaceMultipleWords";
import { useQuery } from "@tanstack/react-query";
import CreateAuthAxiosInstance from "utils/authAxios";

interface IDoorToDoorScripts {
  voterName: any;
}

const DoortoDoorScript = ({voterName}: IDoorToDoorScripts) => {
  const authAxios = CreateAuthAxiosInstance()

  const [selectedOption, setSelectedOption] = useState<undefined | string>();
  const [finalScript, setFinalScript] = useState("");

  const {fullName} = useVolunteersDetail();

  const {
    data: volunteerDetail,
  } = useQuery({
    queryKey: ["volunteerdetail"],
    queryFn: () =>
      authAxios.get("api/v1/volunteers/detail").then((data) => data?.data),
  });

  const {
    data: candidateInfo,
  } = useQuery({
    queryKey: ["candidateInfo"],
    queryFn: () => authAxios.get(`api/v1/candidate/details/${volunteerDetail.candidateId}`),
    refetchOnWindowFocus: false,
  });

  const fetchScriptData = async () => {
    const resp = await authAxios.get("api/v1/call-scripts/volunteers");
    return resp.data;
  };

  const getScriptData = useQuery({
    queryKey: ["script-volunteer-door-data"],
    queryFn: fetchScriptData,
    refetchOnWindowFocus: false,
  });
  const { data: fetchScriptsData } = getScriptData;


  useEffect(() => {
    const filteredscript = fetchScriptsData?.find(
      (item: any) =>
        item.contactType == "By Door" && item.usageScope === "volunteer"
    );
    const script: string = filteredscript?.script;

    if (candidateInfo?.data.id && voterName) {
      const customScriptMappedObject: { [key: string]: string | undefined } = {
        "Voter Name": voterName ?? "",
        "Volunteer Name": fullName ?? "",
        "Candidate Name": candidateInfo?.data.name ?? "",
        "City": candidateInfo?.data?.city ?? "",
        "Position": candidateInfo?.data?.position ?? "",
        "Strength 1": candidateInfo?.data?.strengths[0] ?? "",
        "Strength 2": candidateInfo?.data?.strengths[1] ?? "",
      };
      const finalScriptTemp = replaceMultipleWords(
        script,
        customScriptMappedObject
      );
      setFinalScript(finalScriptTemp);
    }

    if (filteredscript?.isActive === true) {
      setSelectedOption("defaultScript");
      return;
    }
    if (filteredscript?.isActive === false) {
      setSelectedOption("customScript");
      return;
    }
    if (!filteredscript?.length) {
      setSelectedOption("defaultScript");
    }
  }, [fetchScriptsData, candidateInfo, voterName]);

  return (
    <div className="card p-3 scroll-y script-wrapper textEditor">
      {selectedOption === "defaultScript" && (
        <>
          <p>
            {" "}
            Hi, is{" "}
            <span className="text-bg">
              {" "}
              {voterName}{" "}
            </span>
            available?{" "}
          </p>
          <p>
            Hi, <span className="text-bg"> {voterName} </span>, my name is <span className="text-bg"> {fullName} </span> and I'm
            volunteering for
            <span className="text-bg"> {candidateInfo?.data?.name} </span> who is running for <span className="text-bg"> {candidateInfo?.data.position} </span>
            , right here in{" "}
            <span className="text-bg"> {candidateInfo?.data.city} </span>. How are you doing today?
          </p>
          <p>
            The reason I'm calling today is because we
            have a really important election upcoming for{" "}
            <span className="text-bg">
              {" "}
              {candidateInfo?.data?.position}{" "}
            </span>
            .{" "}
          </p>

          <p>
            Can we count on your support for <span className="text-bg">{candidateInfo?.data?.name}</span>?
          </p>
          <p>Thank you so much for your time today!</p>
        </>
      )}
      {selectedOption === "customScript" && (
        <>
          {
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(finalScript),
              }}
            />
          }
        </>
      )}
    </div>
  )
}

export default DoortoDoorScript;