import React from 'react'

const InfoIconSecondary = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.5 9.50037C18.5 14.4709 14.4706 18.5004 9.5 18.5004C4.52944 18.5004 0.5 14.4709 0.5 9.50037C0.5 4.5298 4.52944 0.500366 9.5 0.500366C14.4706 0.500366 18.5 4.5298 18.5 9.50037ZM10.625 14.0004C10.625 14.6217 10.1213 15.1254 9.5 15.1254C8.87868 15.1254 8.375 14.6217 8.375 14.0004C8.375 13.379 8.87868 12.8754 9.5 12.8754C10.1213 12.8754 10.625 13.379 10.625 14.0004ZM9.5 3.87537C8.87868 3.87537 8.375 4.37905 8.375 5.00037V9.50037C8.375 10.1217 8.87868 10.6254 9.5 10.6254C10.1213 10.6254 10.625 10.1217 10.625 9.50037V5.00037C10.625 4.37905 10.1213 3.87537 9.5 3.87537Z" fill="#858599"/>
    </svg>
  )
}

export default InfoIconSecondary
