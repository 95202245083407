import React from 'react'

const CallDrop = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="7" viewBox="0 0 16 7" fill="none">
<path d="M15.785 4.32513C16.0717 4.64859 16.0717 5.17303 15.785 5.49649L14.6677 6.75739C14.414 7.0437 14.014 7.08105 13.7221 6.84571L11.0361 4.6807C10.7739 4.46935 10.6645 4.08974 10.7664 3.74478L11.1682 2.3847C9.14913 1.41384 6.85087 1.41384 4.83182 2.3847L5.23357 3.74478C5.33546 4.08973 5.22607 4.46935 4.96387 4.6807L2.27794 6.84571C1.98597 7.08105 1.58603 7.0437 1.33232 6.75739L0.214977 5.49649C-0.0716589 5.17303 -0.0716594 4.64859 0.214977 4.32513L1.25298 3.15377C4.97926 -1.05126 11.0207 -1.05126 14.747 3.15377L15.785 4.32513Z" fill="white"/>
</svg>
  )
}

export default CallDrop
