import React from "react";
import PhoneDropped from "img/svg/PhoneDropped";
import VoiceMail from "img/svg/VoiceMail";
import { Tooltip } from "bootstrap";

const CallEnded = () => {
  return (
    <>
      <div className="callBox-status">
      
        <PhoneDropped /> 

        <div className="callBox-status-body">
          <div className="callBox-status-text"> Call Ended</div>
          <div className="callBox-status-duration">
            {" "}
            0:14
            {/* if not connected to call */}
            {/* <span className="text-danger"> Couldn’t connect </span> */}
          </div>
        </div>
      </div>
      <div className="callBox-btn">
        <button className="btn btn-success btn-cancel rounded-circle"  data-bs-html="true"
                                data-bs-custom-class="custom-tooltip" data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title="Leave voice mail">
          <VoiceMail />
        </button>
      </div>
    </>
  );
};

export default CallEnded;
