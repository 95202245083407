import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { VolunteerProvider } from "global-state/contexts/VolunteerContext";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

export const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
        <Auth0Provider
          domain={domain ?? ""}
          clientId={clientId ?? ""}
          authorizationParams={{ redirect_uri: window.location.origin }}
          useRefreshTokens
          cacheLocation="localstorage"
          >
          <VolunteerProvider>
          <App />
          </VolunteerProvider>
        </Auth0Provider>
    </QueryClientProvider>
  </React.StrictMode>
);
