import { Link, Navigate, useBlocker } from "react-router-dom";
import ByPhoneIcon from "../../img/svg/ByPhoneIcon";
import DoorToDoorIcon from "../../img/svg/DoorToDoorIcon";
import Confetti from "img/svg/confetti";
import WelcomeModal, {
  CANDIDATE_ID,
  IVolunteerData,
  VOLUNTEER_ID,
  VOLUNTEER_NAME,
} from "components/WelcomeModal";
import { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CreateAuthAxiosInstance from "utils/authAxios";
import TopNavbar from "components/common/navbar/TopNavbar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "index";
import { splitFirstName } from "utils/getFirstName";

function HomePage() {
  const authAxios = CreateAuthAxiosInstance();

  const { isAuthenticated, loginWithRedirect, isLoading, user } = useAuth0();

  const [isWelcomeModalClicked, setIsWelcomeModalClicked] = useState<boolean>(false);

  useEffect(() => {
    if (!isAuthenticated) return;
  }, [isAuthenticated]);

  async function fetchVolunteersData() {
    try{
      if(isAuthenticated){
        const response = await authAxios.get(`api/v1/volunteers/detail`);
        return response.data;
      }
    }catch(error){
      console.log("error",error)
    }
  }

  
  const volunteerDetail  = useQuery({
    queryKey: ["volunteersDetail"],
    queryFn: fetchVolunteersData,
    refetchOnWindowFocus: false,
  })
  const  { data: volunteersData } =  volunteerDetail; 
  
  useEffect(()=>{
    if(!volunteersData){
      volunteerDetail.refetch();
      return
    } 
  },[volunteersData,volunteerDetail])
  
  async function PostAccept(data: any) {
    try{
      const response = await authAxios.post(`api/v1/volunteers/accept`,
      data
    );
    return response.data;
  }catch(error){
    console.log("error",error);
    
  }
  }

  const { mutate: MutateAccept } = useMutation({
    mutationFn: PostAccept,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["volunteersDetail"],
      });
    }
  })

  useEffect(() => {
    if (isWelcomeModalClicked === true) {
      MutateAccept({
        email: user?.email,
        auth0Id: user?.sub,
        candidateId: volunteersData?.candidateId,
      })
    }
  }, [isWelcomeModalClicked])

  useEffect(() => {
    if (!isAuthenticated && !user && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  if (!isAuthenticated) return <></>;

  return (
    <>
      <TopNavbar />
      {volunteersData?.status === "Pending" && (
        <WelcomeModal setIsWelcomeModalClicked={setIsWelcomeModalClicked} />
      )}

      <div className="wrapper">
        <main className="outer-container">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6 mb-4">
                <h2 className="mb-2 text-center">
                  Welcome
                  <span className="welcome-text">
                    , {splitFirstName(volunteersData?.fullName)}!{" "}
                    {/* <span className="placeholder-glow">
                        <span className="placeholder col-3"></span>
                      </span> */}
                  </span>
                </h2>
                <p className="text-center">
                  Reach out to voters. Click either method to get started.
                </p>

                <div className="row mt-5">
                  <div className="col-md-6">
                    <Link
                      to="/by-phone"
                      className="card card-flush shadow-2 card-contactVoter"
                    >
                      <div className="card-body">
                        <div className="iconBox">
                          <ByPhoneIcon />
                        </div>
                        <h4 className="mt-3 mb-0"> By Phone </h4>
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-6">
                    <Link
                      to="/door-to-door"
                      className="card card-flush shadow-2 card-contactVoter"
                    >
                      <div className="card-body">
                        <div className="iconBox">
                          <DoorToDoorIcon />
                        </div>

                        <h4 className="mt-3 mb-0">Door to Door </h4>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default HomePage;
