import DeviceIcon from "img/svg/DeviceIcon";
import InfoIconDanger from "img/svg/InfoIconDanger";
import React from "react";
import { Link } from "react-router-dom";
import Spinner from "templates/components/Spinner";

const SecurityDetails = () => {
  return (
    <>
      <div className="mt-5">
        <div className="settings">
          <div className="settings-header d-flex justify-content-between">
            <div className="">
              <h4> Login </h4>
              <p className="body-4 text-muted">
                {" "}
                Set a unique password to protect your account{" "}
              </p>
            </div>
          </div>

          <div className="settings-body">
            <div className="passwordChange-info">
              <hr className="mt-3 mb-3" />
              <div className="row">
                <div className="col-md-4">
                  <span className="body-2 fs-7"> Change password </span>
                </div>
                <div className="col-md-8">
                  <div className="row justify-content-end">
                    <div className="col-md-8">
                      <span className="body-4 text-muted">
                        {" "}
                        Set a unique password to protect your account{" "}
                      </span>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn btn-link btn-xs px-1 py-0"
                          data-bs-toggle="modal"
                          data-bs-target="#editPasswordModal"
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-3 mb-3" />
            </div>
            
          </div>
        </div>
      </div>

      {/* password Modal */}
      <div
        className="modal fade"
        id="editPasswordModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-header border-1">
              <h5 className="modal-title body-2 mb-0">Change Password </h5>
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="mb-3 required">
                  <label htmlFor="inputCuPass" className="form-label">
                    Current password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputCuPass"
                    aria-describedby="passHelp"
                    placeholder="Enter current password"
                  />
                </div>
                <div className="mb-3 required">
                  <label htmlFor="newPassword" className="form-label">
                    New Password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="newPassword"
                    aria-describedby="newPassHelp"
                    placeholder=""
                  />
                </div>
                <div className="mb-3 required">
                  <label htmlFor="confirmPassword" className="form-label">
                    Confirm new password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="confirmPassword"
                    aria-describedby="confirmPass"
                    placeholder=""
                  />
                  <div className="invalidInput form-text text-danger">
                    {" "}
                    <InfoIconDanger /> Password does not match{" "}
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer border-1">
              <Link
                to=""
                className="btn btn-link text-primary btn-xs rounded-pill"
                data-bs-dismiss="modal"
              >
                Cancel
              </Link>
              {/* remove button disabled class after spinner goes off */}
              <Link
                to=""
                className="btn btn-primary rounded-pill btn-xs px-6 disabled"
              >
                <Spinner />
                Change password
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecurityDetails;
