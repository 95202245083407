import React from "react";

export const Loader: React.FC = () => {
  return (
    <div className="loader-demo-box">
      <div className="jumping-dots-loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Loader;
