import React from 'react'

const DoorToDoorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<g clipPath="url(#clip0_5939_97442)">
<path d="M13.5379 31.9327C12.936 31.936 12.3336 31.9425 11.7317 31.9425C8.09756 31.9436 4.46288 31.9381 0.828751 31.9501C0.470548 31.9512 0.185071 31.8947 0 31.5685C0 31.4643 0 31.3607 0 31.2565C0.0206238 31.1609 0.0597005 31.0649 0.0597005 30.9694C0.0624141 20.9706 0.0624141 10.9724 0.0597005 0.973661C0.0597005 0.87814 0.0206238 0.782076 0 0.686556V0H13.5357C13.5346 0.646936 13.5336 1.29333 13.5319 1.94027C12.8882 1.94081 12.2451 1.94189 11.6014 1.94244C11.5602 1.93864 11.5189 1.93212 11.4777 1.93212C8.39172 1.93158 5.3052 1.93158 2.21923 1.93212C2.14922 1.93212 2.07866 1.94352 1.98368 1.95166V16.5056C2.03036 16.5137 2.06021 16.524 2.0906 16.524C5.25961 16.5224 8.42916 16.5202 11.5982 16.518C11.6204 16.518 11.6432 16.518 11.6655 16.5186C12.2679 16.5186 12.8703 16.5186 13.4728 16.5186L13.5015 16.5278L13.5319 16.5256C13.5336 18.4426 13.5346 20.3595 13.5363 22.2764C12.8931 22.2786 12.2505 22.2808 11.6074 22.2824C8.50026 22.2797 5.39258 22.2786 2.28544 22.271C2.04013 22.2705 1.99237 22.3828 1.99237 22.5961C1.99671 24.9765 1.9978 27.3569 1.99074 29.7374C1.9902 29.9658 2.07486 30.0131 2.28219 30.0125C5.38987 30.0065 8.49701 30.0071 11.6047 30.006C12.2484 30.0076 12.8926 30.0087 13.5363 30.0103C13.5368 30.6513 13.5379 31.2923 13.5384 31.9332L13.5379 31.9327ZM3.86751 19.4434C3.86696 18.8512 3.50333 18.4648 2.94432 18.4616C2.38205 18.4583 2.00485 18.8572 1.98368 19.42C1.9636 19.9638 2.48517 20.3763 2.89059 20.3969C3.40347 20.4224 3.86805 19.9568 3.86751 19.4439V19.4434Z" fill="#B78567"/>
<path d="M13.5359 22.2764C13.5343 20.3595 13.5332 18.4426 13.5316 16.5256C13.5359 16.5066 13.5337 16.4882 13.5261 16.4703C13.5294 16.4393 13.5353 16.4084 13.5353 16.3775C13.5348 11.5651 13.5337 6.75267 13.5326 1.94027C13.5337 1.29333 13.5348 0.646936 13.5364 0C14.0558 0 14.5758 0 15.0957 0C15.2151 0.0835807 15.334 0.167161 15.4534 0.251285C15.4582 0.490629 15.4675 0.729974 15.4675 0.969319C15.468 10.974 15.4686 20.9793 15.4658 30.984C15.4658 31.2082 15.4528 31.4383 15.4023 31.6554C15.3459 31.9007 15.1142 31.9343 14.909 31.9387C14.4526 31.9484 13.9956 31.936 13.5386 31.9327C13.5381 31.2917 13.537 30.6508 13.5364 30.0098C13.5364 27.4318 13.5364 24.8539 13.5364 22.2759L13.5359 22.2764Z" fill="#AA6F51"/>
<path d="M28.5169 11.5836C29.1633 11.6102 29.8167 11.5857 30.4539 11.6769C31.2045 11.7849 31.6126 12.32 31.8249 13.0229C31.8487 13.101 31.9399 13.1591 32.0002 13.2264V13.3512C31.9589 13.4104 31.884 13.469 31.8818 13.5298C31.8732 13.8402 31.8862 14.1518 31.8862 14.4627C31.8873 16.7243 31.8862 18.9853 31.8867 21.2469C31.8867 21.5915 31.7907 21.8911 31.4981 22.1017C31.4759 22.1164 31.4542 22.131 31.4319 22.1462C30.8072 22.4822 30.4485 22.3747 29.9969 21.7164C29.998 21.6556 29.9986 21.5942 29.9991 21.5335C29.9801 20.9647 29.9465 20.3964 29.9448 19.8277C29.9383 17.6768 29.9421 15.526 29.9416 13.3751C29.9416 12.9284 29.7977 12.5241 29.4651 12.2343C29.1785 11.9841 28.8349 11.7985 28.5164 11.5841L28.5169 11.5836Z" fill="#5688FF"/>
<path d="M0 0.686029C0.0206238 0.781549 0.0597005 0.877613 0.0597005 0.973134C0.0624141 10.9719 0.0624141 20.9701 0.0597005 30.9688C0.0597005 31.0643 0.0206238 31.1604 0 31.2559C0 21.0656 0 10.8758 0 0.685486V0.686029Z" fill="#B78567"/>
<path d="M15.4531 0.251285C15.3337 0.167704 15.2149 0.0841234 15.0955 0C15.1997 0 15.3033 0 15.4075 0C15.4227 0.0835807 15.4379 0.167161 15.4531 0.251285Z" fill="#B17A5B"/>
<path d="M28.5169 11.5836C28.8355 11.798 29.1785 11.9836 29.4656 12.2338C29.7978 12.5242 29.9421 12.928 29.9421 13.3746C29.9421 15.5255 29.9383 17.6763 29.9454 19.8272C29.947 20.3959 29.9807 20.9642 29.9997 21.533C29.9877 21.5959 29.9758 21.6589 29.9633 21.7224C29.9606 24.5598 29.9595 27.3966 29.9519 30.234C29.9514 30.5103 29.941 30.7936 29.8813 31.0617C29.75 31.6555 29.1465 32.0354 28.537 31.9404C27.9997 31.8568 27.5063 31.2484 27.508 30.7008C27.5161 27.6658 27.5183 24.6314 27.5221 21.5965C27.5221 21.5449 27.5134 21.4939 27.5063 21.4125H25.6204C25.6204 21.5411 25.6204 21.6627 25.6204 21.7837C25.6204 24.6108 25.6231 27.4379 25.616 30.265C25.6155 30.5298 25.5753 30.799 25.5156 31.0579C25.3886 31.6066 24.9615 31.9317 24.396 31.942C23.8733 31.9518 23.3642 31.599 23.2394 31.0954C23.1797 30.8544 23.1905 30.5933 23.1905 30.3415C23.1884 24.8534 23.1889 19.3658 23.1862 13.8777C23.1862 13.7459 23.1493 13.6134 23.1298 13.4815C23.1526 13.449 23.1933 13.4175 23.1949 13.3839C23.2329 12.624 23.6361 12.1101 24.2766 11.7486C24.3297 11.7188 24.358 11.6433 24.3976 11.5896C25.4928 11.5793 26.5875 11.5662 27.6827 11.5592C27.9606 11.5576 28.2385 11.5744 28.5164 11.5831L28.5169 11.5836Z" fill="#5688FF"/>
<path d="M24.3974 11.59C24.3578 11.6443 24.3296 11.7192 24.2764 11.7491C23.636 12.1105 23.2327 12.6245 23.1948 13.3843C23.1931 13.418 23.1524 13.4494 23.1296 13.482C22.4957 13.4826 21.8624 13.4885 21.2284 13.482C20.1571 13.4712 19.337 12.6549 19.3321 11.5857C19.3256 10.1002 19.3294 8.61425 19.33 7.12879C19.33 6.92798 19.2909 6.71306 19.3479 6.52853C19.5112 5.99557 19.953 5.7166 20.4268 5.80235C20.9402 5.89516 21.2572 6.27996 21.2594 6.84494C21.2648 8.27884 21.2615 9.71274 21.2615 11.1461C21.2615 11.2677 21.2615 11.3898 21.2615 11.5537C21.5117 11.5537 21.7261 11.5515 21.941 11.5537C22.7595 11.5645 23.5785 11.577 24.3969 11.589L24.3974 11.59Z" fill="#5688FF"/>
<path d="M26.8246 9.59764C25.2133 9.70836 24.0936 8.70919 23.7343 7.32251C23.6361 6.94368 23.6567 6.49919 23.7446 6.11276C24.1327 4.40913 25.5584 3.76653 26.8263 3.81429C26.8485 3.83166 26.8702 3.84848 26.8925 3.86585L26.8908 3.85771C26.9109 3.92609 26.9158 4.00479 26.9543 4.06123C27.3359 4.62133 27.4651 5.27098 27.4949 5.91575C27.5541 7.18628 27.5942 8.47093 26.8246 9.59764Z" fill="#5688FF"/>
<path d="M26.8243 9.5976C27.5939 8.47034 27.5532 7.1857 27.4946 5.9157C27.4648 5.27094 27.3356 4.62129 26.9541 4.06119C26.9161 4.00529 26.9112 3.92605 26.8906 3.85767C28.3554 4.0118 29.4132 5.22264 29.4501 6.6815C29.4734 7.59492 29.0561 8.27279 28.4444 8.87848C28.3869 8.91375 28.3288 8.94903 28.2713 8.98431C28.224 9.03153 28.1768 9.07929 28.1296 9.12651C28.1074 9.14062 28.0851 9.15473 28.0629 9.16884C28.0433 9.16992 28.0249 9.17535 28.0075 9.18512C27.9494 9.22203 27.8919 9.25947 27.8338 9.29638C27.4973 9.39679 27.1608 9.49719 26.8243 9.5976Z" fill="#5688FF"/>
<path d="M18.2416 4.24206C18.4989 4.24206 18.7073 4.43364 18.7127 4.67462C18.7187 4.95467 18.5114 5.17176 18.2319 5.17773C17.9763 5.1837 17.7733 4.96986 17.7765 4.69795C17.7803 4.39619 17.9372 4.24152 18.2416 4.24152V4.24206Z" fill="#26DAC2"/>
<path d="M17.8416 6.78255C17.8308 7.03763 17.6012 7.2493 17.351 7.23519C17.11 7.22162 16.9011 6.99747 16.906 6.75812C16.9119 6.48187 17.1486 6.28323 17.4536 6.29952C17.7331 6.31471 17.8552 6.46614 17.8416 6.78255Z" fill="#26DAC2"/>
<path d="M20.2715 4.3065C19.9681 4.30541 19.8362 4.17407 19.8346 3.87231C19.833 3.59064 20.0441 3.36649 20.3079 3.37029C20.5472 3.37354 20.7654 3.59009 20.7703 3.82998C20.7757 4.09321 20.5521 4.30704 20.2715 4.3065Z" fill="#26DAC2"/>
<path d="M29.963 21.7224C29.975 21.6594 29.9869 21.5959 29.9994 21.533C29.9983 21.5937 29.9977 21.6551 29.9967 21.7159C29.9858 21.7202 29.9744 21.7224 29.963 21.7224Z" fill="#BEC8E6"/>
<path d="M31.4318 22.1461C31.454 22.1314 31.4757 22.1168 31.498 22.1016C31.4757 22.1162 31.454 22.1309 31.4318 22.1461Z" fill="#F9FAFD"/>
<path d="M11.5981 16.5178C8.42912 16.52 5.25957 16.5227 2.09056 16.5238C2.06017 16.5238 2.03032 16.5135 1.98364 16.5054V1.95201C2.07916 1.94387 2.14918 1.93247 2.21919 1.93247C5.30516 1.93193 8.39167 1.93193 11.4776 1.93247C11.5189 1.93247 11.5601 1.93899 11.6014 1.94279C11.6014 2.58592 11.6003 3.22906 11.5998 3.87165C10.9561 3.87274 10.3124 3.87383 9.66872 3.87491H3.93313V5.7609C3.98795 5.77393 4.02703 5.79075 4.0661 5.79075C5.93364 5.78804 7.80173 5.78424 9.66927 5.78044C10.3108 5.78261 10.9528 5.78424 11.5943 5.78641C11.5965 5.92155 11.6014 6.05615 11.6014 6.19129C11.6014 9.61756 11.6014 13.0433 11.6014 16.4695C11.5992 16.4858 11.5976 16.5021 11.597 16.5184L11.5981 16.5178Z" fill="#E3EBFE"/>
<path d="M11.6041 30.0061C8.49638 30.0072 5.38924 30.0066 2.28156 30.0126C2.07424 30.0126 1.98957 29.9659 1.99011 29.7374C1.99771 27.357 1.99609 24.9766 1.99174 22.5962C1.99174 22.3823 2.0395 22.27 2.28482 22.2711C5.39196 22.2792 8.49964 22.2797 11.6068 22.2825C11.6057 24.8566 11.6051 27.4314 11.6041 30.0061Z" fill="#AA6F51"/>
<path d="M11.6025 16.4692C11.6025 13.0429 11.6025 9.61719 11.6025 6.19092C11.6025 6.05578 11.5982 5.92118 11.5955 5.78604C11.5971 5.14779 11.5987 4.50954 11.6009 3.87129C11.6009 3.22815 11.602 2.58501 11.6025 1.94242C12.2462 1.94187 12.8893 1.94079 13.533 1.94025C13.5341 6.75265 13.5352 11.5651 13.5357 16.3775C13.5357 16.4084 13.5298 16.4393 13.5265 16.4703C13.5086 16.4865 13.4912 16.5023 13.4733 16.5186C12.8709 16.5186 12.2684 16.5186 11.666 16.5186C11.6448 16.5023 11.6242 16.4855 11.6031 16.4692H11.6025Z" fill="#E3EBFE"/>
<path d="M11.6042 30.0061C11.6053 27.4314 11.6059 24.8572 11.607 22.2825C12.2501 22.2803 12.8932 22.2787 13.5358 22.2765C13.5358 24.8545 13.5358 27.4324 13.5358 30.0104C12.8922 30.0088 12.2479 30.0077 11.6042 30.0061Z" fill="#AA6F51"/>
<path d="M3.86742 19.4433C3.86742 19.9562 3.40338 20.4218 2.8905 20.3963C2.48562 20.3763 1.96351 19.9638 1.98359 19.4194C2.00422 18.8566 2.38196 18.4577 2.94423 18.461C3.50379 18.4642 3.86687 18.8506 3.86742 19.4428V19.4433Z" fill="#FCBB6F"/>
<path d="M11.6025 16.4691C11.6237 16.4854 11.6443 16.5022 11.6654 16.5185C11.6432 16.5185 11.6204 16.5185 11.5981 16.518C11.5987 16.5017 11.6003 16.4854 11.6025 16.4691Z" fill="#C3C9D5"/>
<path d="M13.4727 16.5188C13.4906 16.5025 13.508 16.4867 13.5259 16.4705C13.534 16.4884 13.5357 16.5068 13.5313 16.5258L13.5009 16.528L13.4722 16.5188H13.4727Z" fill="#C3C9D5"/>
<path d="M9.66985 5.78016C7.80231 5.78396 5.93422 5.78831 4.06668 5.79048C4.02761 5.79048 3.98853 5.77365 3.93372 5.76063V3.87463C5.85716 3.87463 7.76323 3.87463 9.6693 3.87463C9.6693 4.50963 9.6693 5.14517 9.66985 5.78016Z" fill="#FCBB6F"/>
<path d="M9.66973 5.78006C9.66973 5.14507 9.66973 4.50953 9.66919 3.87453C10.3129 3.87345 10.9565 3.87236 11.6002 3.87128C11.5986 4.50953 11.597 5.14778 11.5948 5.78603C10.9533 5.78386 10.3112 5.78223 9.66973 5.78006Z" fill="#FCBB6F"/>
</g>
<defs>
<clipPath id="clip0_5939_97442">
<rect width="32" height="32" fill="white"/>
</clipPath>
</defs>
</svg>
  )
}

export default DoorToDoorIcon
