import React from 'react'

const PhoneDropped = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M1.60156 2.4001C1.60156 1.95827 1.95973 1.6001 2.40156 1.6001H4.12386C4.51493 1.6001 4.84868 1.88283 4.91298 2.26858L5.50443 5.81728C5.56216 6.1637 5.38721 6.50728 5.07308 6.66434L3.83457 7.28359C4.72761 9.50275 6.49891 11.2741 8.71807 12.1671L9.33732 10.9286C9.49438 10.6145 9.83796 10.4395 10.1844 10.4972L13.7331 11.0887C14.1188 11.153 14.4016 11.4867 14.4016 11.8778V13.6001C14.4016 14.0419 14.0434 14.4001 13.6016 14.4001H12.0016C6.2578 14.4001 1.60156 9.74386 1.60156 4.0001V2.4001Z" fill="#2E78F1"/>
    <path d="M13.3672 2.63441C13.6797 2.94683 13.6797 3.45336 13.3672 3.76578L12.3329 4.8001L13.3672 5.83441C13.6797 6.14683 13.6797 6.65336 13.3672 6.96578C13.0548 7.2782 12.5483 7.2782 12.2359 6.96578L11.2016 5.93147L10.1672 6.96578C9.85483 7.2782 9.3483 7.2782 9.03588 6.96578C8.72346 6.65336 8.72346 6.14683 9.03588 5.83441L10.0702 4.8001L9.03588 3.76578C8.72346 3.45336 8.72346 2.94683 9.03588 2.63441C9.3483 2.32199 9.85483 2.32199 10.1672 2.63441L11.2016 3.66873L12.2359 2.63441C12.5483 2.32199 13.0548 2.32199 13.3672 2.63441Z" fill="#2E78F1"/>
    </svg>
  )
}

export default PhoneDropped
