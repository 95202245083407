import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

function CreateAuthAxiosInstance() {
  const { getIdTokenClaims } = useAuth0();

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/`,
    timeout: 5000,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  // Add a request interceptor
  authAxios.interceptors.request.use(async function (config) {
    const token = await getIdTokenClaims();
    if (token) {
      config.headers.Authorization = `Bearer ${token?.__raw}`;
    }

    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  return authAxios
}

export default CreateAuthAxiosInstance
