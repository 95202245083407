import { Link } from "react-router-dom";
import WarningIcon from "img/svg/warningIcon";
// import BreadCrumbs from "components/common/BreadCrumbs";
import SidePanel, { IVoterContactHistory } from "./SidePannel";
import { useEffect, useRef, useState } from "react";
import Map from "./Map";
import { useLoadScript } from "@react-google-maps/api";
import Loader from "components/common/Loader";
import { CANDIDATE_ID, VOLUNTEER_ID } from "components/WelcomeModal";
import CreateAuthAxiosInstance from "utils/authAxios";
import { CONTACT_TYPE } from "../ContactByPhone/useContactByPhone";
import { useVolunteersDetail } from "global-state/contexts/VolunteerContext";

export interface IVotersDetails {
  id: number;
  address: string;
  name: string;
  geoCoordinates: number[] | null;
  partyAffiliation: string;
  age: string;
  sex: string;
  voterContactHistories: IVoterContactHistory[];
}

export interface IInfoWindow {
  id: number;
  voters: IVotersDetails[];
}

export const VOTERS_LIMIT = 10;

export const getUniqueArrayFromArrayofArrays = (
  arrayOfArrays: number[][]
): number[][] => {
  arrayOfArrays = arrayOfArrays.filter((item) => item);
  const uniqueSet = new Set(arrayOfArrays.map((item) => JSON.stringify(item)));
  const uniqueArray = Array.from(uniqueSet).map((item) =>
    JSON.parse(item)
  ) as number[][];
  return uniqueArray;
};

export const areArraysEqual = (arr1: number[], arr2: number[]): boolean => {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  for (let i = 0; i < arr1?.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

const DoortoDoor = () => {
  // const candidateId = localStorage.getItem(CANDIDATE_ID);
  // const volunteerId = localStorage.getItem(VOLUNTEER_ID);
  const { id, candidateId } = useVolunteersDetail();
  const volunteerId = id;
  console.log("volunteerId", volunteerId);
  console.log("candidateIddoor", candidateId);

  const [showSidePanel, setShowSidePanel] = useState(false);
  const [hasVisitedVoters, setHasVisitedVoters] = useState<Array<boolean>>([]);

  const [votersDetails, setVotersDetails] = useState<Array<IVotersDetails[]>>(
    []
  );
  const [activeVoters, setActiveVoters] = useState<Array<IVotersDetails>>([]);
  const [doorKnocked, setDoorKnocked] = useState(0);

  const [votersCoordinates, setVotersCordinates] = useState<Array<number[]>>(
    []
  );

  const [activeCoordinates, setActiveCoordinates] = useState<Array<number>>([]);
  const [activeAddressIndex, setActiveAddressIndex] = useState(-1);
  const [isVoterResponseSubmitted, setIsVoterResponseSubmitted] =
    useState(false);

  // const successModalRef = useRef<HTMLButtonElement>(null);
  const skipModalRef = useRef<HTMLButtonElement>(null);
  const [showSkipModal, setShowSkipModal] = useState(false);
  const [
    canSubmitAllVotersDefaultResponse,
    setCanSubmitAllVotersDefaultResponse,
  ] = useState(false);

  const scrollRefVoters = useRef<HTMLDivElement>(null);
  const [votersLimit, setVotersLimit] = useState(10);
  const [isVotersLoading, setIsVotersLoading] = useState(false);
  const [totalAddress, setTotalAddress] = useState(0);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? "",
    libraries: ["drawing"],
    mapIds: [process.env.REACT_APP_GOOGLE_MAP_ID ?? ""],
  });

  const authAxios = CreateAuthAxiosInstance();

  // const getVotersContactedHistory = async () => {
  //   try {
  //     const contactHistoryResponse = await authAxios.get(
  //       `api/v1/volunteers/voters/contact-histories/${candidateId}?contactType=${CONTACT_TYPE.DOOR_TO_DOOR}`
  //     );
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const getVoterDetails = async () => {
    try {
      const contactPlanResponse = await authAxios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/volunteers/voters/${candidateId}?contactType=${CONTACT_TYPE.DOOR_TO_DOOR}&limit=${votersLimit}`
      );
      setTotalAddress(contactPlanResponse.data.totalItems);
      const voters: IVotersDetails[] = contactPlanResponse.data.items;
      let voterInfo: IVotersDetails[] = [];
      const mappedVotersCoordinates = getUniqueArrayFromArrayofArrays(
        voters
          .map((item) => {
            const coordinates: Array<number[]> = [];
            if (item.geoCoordinates) coordinates.push(item.geoCoordinates);
            return coordinates;
          })
          .flat()
      );
      const voterInfos: IVotersDetails[][] = [];
      mappedVotersCoordinates.forEach((coordinates) => {
        voterInfo = [];
        voters?.forEach((voter) => {
          if (
            voter.geoCoordinates &&
            areArraysEqual(voter.geoCoordinates, coordinates)
          ) {
            voterInfo.push(voter);
          }
        });
        voterInfo?.length && voterInfos.push(voterInfo);
      });

      if (mappedVotersCoordinates.length)
        setVotersCordinates(mappedVotersCoordinates);

      setVotersDetails(voterInfos);
      setIsVotersLoading(false);

      // setIsVotersDetailsSet(true);
      if (isVoterResponseSubmitted)
        setActiveVoters(voterInfos[activeAddressIndex]);
      setIsVoterResponseSubmitted(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const loadMore = (scrollDiv: HTMLDivElement, totalAddressToLoad: number) => {
    if (scrollDiv) {
      const scrollHeight = scrollDiv.scrollHeight;
      const scrollTop = scrollDiv.scrollTop;
      const clientHeight = scrollDiv.clientHeight;
      const toBottom = scrollHeight - clientHeight - scrollTop;
      // When scroll position is at bottom then it will load more voters
      // scrollHeight - scrollTop === clientHeight
      if (toBottom <= 0) {
        if (totalAddressToLoad === votersDetails?.length) {
          setIsVotersLoading(false);
        } else {
          setIsVotersLoading(true);
          setVotersLimit(votersLimit + 10);
        }
      }
    }
  };

  useEffect(() => {
    // For triggering loadMore function
    const totalVotersScrollDiv = scrollRefVoters.current;
    if (totalVotersScrollDiv)
      totalVotersScrollDiv.addEventListener("scroll", () =>
        loadMore(totalVotersScrollDiv, totalAddress)
      );
  }, [totalAddress, votersDetails]);

  useEffect(() => {
    (async () => {
      if (candidateId && volunteerId)
        try {
          const volunteerContactStatsResponse = await authAxios.get(
            `api/v1/volunteers/voters/contact-stats/${candidateId}/${volunteerId}`
          );
          const doorKnocks: number =
            volunteerContactStatsResponse?.data?.doorKnocks;
          setDoorKnocked(doorKnocks);
        } catch (error) {
          console.log("error", error);
        }
    })();
  }, [candidateId, volunteerId, isVoterResponseSubmitted]);

  useEffect(() => {
    getVoterDetails();
    // getVotersContactedHistory();
  }, [votersLimit, candidateId]);

  useEffect(() => {
    if (isVoterResponseSubmitted) {
      getVoterDetails();
      // getVotersContactedHistory();
    }
  }, [isVoterResponseSubmitted]);

  useEffect(() => {
    if (votersDetails?.length) {
      const activeVoterDetails = votersDetails[activeAddressIndex];
      // set active voters details and coordinates
      if (activeAddressIndex !== -1) {
        setActiveVoters(activeVoterDetails);
        if (activeVoterDetails[0].geoCoordinates)
          setActiveCoordinates(activeVoterDetails[0].geoCoordinates);
        setShowSidePanel(true);
      } else {
        setActiveVoters([]);
        setActiveCoordinates([]);
        setShowSidePanel(true);
      }
    }
  }, [activeAddressIndex]);

  useEffect(() => {
    const visitedVoters = votersDetails.map((voters) => {
      return voters
        .map((voter) => voter.voterContactHistories?.length > 0)
        .every((value) => value === true);
    });

    setHasVisitedVoters(visitedVoters);
  }, [votersDetails]);

  const totalVoters = votersDetails.reduce(
    (result, voters) => result + voters?.length,
    0
  );
  const totalDoors = votersDetails?.length;

  const totalDoorsVisited = hasVisitedVoters.reduce((count, value) => {
    return count + (value ? 1 : 0);
  }, 0);

  // useEffect(() => {
  //   const successModalDiv = successModalRef.current;
  //   if (
  //     successModalDiv &&
  //     totalDoorsVisited &&
  //     totalDoors &&
  //     totalDoorsVisited === totalDoors
  //   ) {
  //     successModalDiv.click();
  //   }
  // }, [totalDoorsVisited, totalDoors]);

  useEffect(() => {
    const skipModalDiv = skipModalRef.current;

    if (showSkipModal && skipModalDiv) {
      skipModalDiv.click();
      setShowSkipModal(false);
    }
  }, [showSkipModal]);

  const totalActiveVoters = activeVoters?.length;
  const totalActiveVotersVisited = activeVoters?.reduce((count, value) => {
    return count + (value.voterContactHistories?.length ? 1 : 0);
  }, 0);
  const totalVisitedVoters = votersDetails.reduce((count, voters) => {
    voters.forEach((voter) => {
      count = count + (voter.voterContactHistories?.length ? 1 : 0);
    });
    return count;
  }, 0);

  // swipe
  const touchStart = useRef<number | null>(null);
  const touchEnd = useRef<number | null>(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 1;

  const onTouchStart = (e: React.TouchEvent) => {
    touchEnd.current = null;
    // touchStart.current = e.targetTouches[0].clientX;
    touchStart.current = e.targetTouches[0].clientY;
  };

  const onTouchMove = (e: React.TouchEvent) => {
    // touchEnd.current = e.targetTouches[0].clientX;
    touchEnd.current = e.targetTouches[0].clientY;
  };

  const onTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return;
    const distance = touchStart.current - touchEnd.current;
    const isTopSwipe = distance > minSwipeDistance;
    const isDownSwipe = distance < -minSwipeDistance;
    let addressSection = document.getElementById("address-js");
    if (isTopSwipe) {
      console.log("swipe", "up");
      console.log(addressSection);
      addressSection?.classList.add("scrollToTop");
    }

    if (isDownSwipe) {
      console.log("swipe", "down");
      addressSection?.classList.remove("scrollToTop");
    }
  };

  console.log(votersCoordinates);

  const leaveImage = require("../../../../img/leave.png");

  return (
    <div className="d-flex align-items-stretch">
      {activeVoters?.length > 0 && (
        <SidePanel
          show={showSidePanel}
          setShow={setShowSidePanel}
          voters={activeVoters}
          activeAddressIndex={activeAddressIndex}
          totalAddress={votersDetails.length}
          setActiveAddressIndex={setActiveAddressIndex}
          setIsVoterResponseSubmitted={setIsVoterResponseSubmitted}
          setShowSkipModal={setShowSkipModal}
          canSubmitAllVotersDefaultResponse={canSubmitAllVotersDefaultResponse}
          setCanSubmitAllVotersDefaultResponse={
            setCanSubmitAllVotersDefaultResponse
          }
        />
      )}
      <div className="wrapper active-cont">
        <main className="p-0">
          <div className="container-fluid p-0">
            <div className="map-wrapper">
              <div
                id="address-js"
                className="daily-address scrollToTo"
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              >
                {/* <div className="d-none d-lg-block">
                  <BreadCrumbs subtitle={"Contact Voters Door To Door"} />
                </div> */}

                {/*  <div className="voterGoal d-none d-lg-block">
                  <div className="voter-goal-item">
                    <span className="dot bg-success-s2"> </span>
                    <span className="fw-bold me-1"> {doorKnocked}</span>
                    Door Knocked
                  </div>
                   <div className="voter-goal-item">
                    <span className="dot bg-extended-e12"> </span>
                    <span className="fw-bold me-1">1 of 186</span>Weekly door
                    goal
                  </div> 
                </div>
                */}

                {/* Address list */}
                <div className="voterAddress" ref={scrollRefVoters}>
                  <div className="drag-control d-sm-block d-lg-none">
                    <div className="drag-control-item"></div>
                  </div>
                  <div className="voterAddress-header">
                    <span className="body-4 text-muted">
                      {totalDoors} Addresses{" "}
                    </span>
                    <span className="dot bg-neutral-n3 ms-2"> </span>
                    <span className="body-4 text-muted">
                      {totalVoters} voters
                    </span>
                  </div>
                  <div className="voterAddress-body">
                    {votersDetails.map((voters, index) => (
                      <div
                        className={`voterAddress-body-item  ${
                          hasVisitedVoters[index] ? "success" : ""
                        } ${activeAddressIndex === index ? "active" : ""}`}
                        key={index}
                        onClick={() => {
                          if (voters?.length) {
                            activeAddressIndex === index
                              ? setActiveAddressIndex(-1)
                              : setActiveAddressIndex(index);
                          }
                        }}
                      >
                        <div className="address-index">{index + 1}</div>
                        <Link to="" className="address-content">
                          <div className="voter-address">
                            {voters?.length && voters[0]?.address}
                          </div>
                          <div className="voter-count">
                            {" "}
                            {voters?.length} voters
                          </div>
                        </Link>
                      </div>
                    ))}
                    {isVotersLoading && <Loader />}
                  </div>
                </div>
              </div>
              {!isLoaded ? (
                <div className="map-address">
                  <div className="position-relative  top-50 start-50 translate-middle">
                    <Loader />
                  </div>
                </div>
              ) : (
                <Map
                  votersCoordinates={votersCoordinates}
                  votersDetails={votersDetails}
                  activeCoordinates={activeCoordinates}
                  setActiveAddressIndex={setActiveAddressIndex}
                  hasVisitedVoters={hasVisitedVoters}
                />
              )}
            </div>
          </div>
        </main>
      </div>

      {/* <button
        className="btn btn-primary d-none"
        ref={successModalRef}
        data-bs-toggle="modal"
        data-bs-target="#successModal"
      >
        Show Success Modal
      </button> */}

      <button
        className="btn btn-primary d-none"
        ref={skipModalRef}
        data-bs-toggle="modal"
        data-bs-target="#skipModal"
      >
        Show Skip Modal
      </button>
      {/* if all door visited */}

      {/* <div
        className="modal fade"
        id="successModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../img/success-modal.png")}
                width={56}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Great Work! </h2>
              <p>
                You have reached your daily door goal and knocked on{" "}
                <span className="fw-bold">
                  {" "}
                  {`${totalDoorsVisited}/${totalDoors}`}{" "}
                </span>{" "}
                doors. Continue to your next task for the day or add knocking
                more doors.
              </p>
            </div>

            <div
              className="modal-footer justify-content-end"
              data-bs-dismiss="modal"
            >
              <Link to="/voters/list" className="btn btn-link rounded-pill">
                Add more doors
              </Link>
              <Link to="/" className="btn btn-primary rounded-pill">
                Continue to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div> */}

      {/* If all doors visited but not talked to some voters */}
      <div
        className="modal fade"
        id="skipModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <WarningIcon />
              <h2 className="mt-3">
                {" "}
                Would you like to mark{" "}
                {totalActiveVoters - totalActiveVotersVisited} voters as ‘Not
                Home’?{" "}
              </h2>
              {/* <p>
                <span className="fw-bold">
                  {" "}
                  {`${totalVisitedVoters}/${totalVoters}`}{" "}
                </span>{" "}
                voters talked today.
              </p> */}
            </div>

            <div
              className="modal-footer justify-content-end"
              data-bs-dismiss="modal"
            >
              <button className="btn btn-link rounded-pill">
                No, do nothing
              </button>
              <button
                className="btn btn-primary rounded-pill"
                onClick={() => setCanSubmitAllVotersDefaultResponse(true)}
              >
                Yes, continue
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* when user leave page  */}
      <div
        className="modal fade"
        id="leaveModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img src={leaveImage} width={66} height={56} alt="leave alert" />
              <h2 className="mt-3"> Do you want to leave this page? </h2>
              <p>
                By leaving the page, details filled in this page will be erased.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link btn-sm p-1 ps-0"
                data-bs-toggle="modal"
                data-bs-target="#skipModal"
              >
                Cancel
              </button>
              <button className="btn btn-primary rounded-pill btn-xs">
                Yes, Leave
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoortoDoor;
