import PhoneFilledIcon from "img/svg/PhoneFilledIcon";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Device } from "@twilio/voice-sdk";
import OnCall from "./components/OnCall";
import CallEnded from "./components/CallEnded";
import { useMutation, useQuery } from "@tanstack/react-query";
import CreateAuthAxiosInstance from "utils/authAxios";
import toastNotify from "utils/toastNotify";
import { BlockerFunction, useBlocker } from "react-router-dom";

interface IPhoneComponentProps {
  receiverType: string;
  phoneNumber: string | null;
  autoDialerStatus: string | null;
  receiverName: string | null;
  receiverId: number | null;
  parentCallSId: string | null;
  handleParentCallSId: (acceptCallSId: string | null) => void;
  submitVoterButtonRef?: React.RefObject<HTMLButtonElement>;
  handlePatchCallConcurrency: (data: any) => void
}

//Voice call events:- ringing,accept,disconnect,cancel,error,mute,reconnected, reconnecting,reject

export const PhoneComponent = ({
  receiverId,
  receiverName,
  receiverType,

  autoDialerStatus,
  phoneNumber,

  parentCallSId,
  handleParentCallSId,
  submitVoterButtonRef,
  handlePatchCallConcurrency
}: IPhoneComponentProps) => {
  const authAxios = CreateAuthAxiosInstance();

  //Call state variables
  const [isCallEnabled, setIsCallEnabled] = useState<boolean>(false)
  const [isOnCall, setIsOnCall] = useState<boolean>(false)
  const [isCallAccepted, setIsCallAccepted] = useState<boolean>(false)
  const [isCallEnded, setIsCallEnded] = useState<boolean>(false)
  const [totalCallDuration, setTotalCallDuration] = useState<string | null>(null)
  const [isManuallyHungUp, setIsManuallyHungUp] = useState<boolean>(false)


  //Twilio variables
  const hangUpButtonRef = useRef<HTMLButtonElement>(null)
  const device = useRef<any>()
  const connectionRef = useRef<any>()
  const twilioAccessTokenRef = useRef<string | null>(null)


  const leavePageModalButtonRef = useRef<HTMLButtonElement>(null)

  const {
    data: twilioAccessTokenObj,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["volunteerTwilioAccessToken"],
    queryFn: () =>
      authAxios.get(
        `${process.env.REACT_APP_COMMUNICATION_BASE_URL}/api/v1/calls/volunteer/token`
      ),
    retry: false,
    enabled: autoDialerStatus === "Integrated"
  });

  const {
    mutate: postVoiceMailMutation,
    data: postVoiceMailMutationData,
    isPending,
  } = useMutation({
    mutationFn: () =>
      authAxios.post(
        `${process.env.REACT_APP_COMMUNICATION_BASE_URL}/api/v1/calls/volunteer/voicemail/${parentCallSId}?calleeType=${receiverType}`
      ),
    onSuccess: () => {
      // console.log("voice mail success");
      toastNotify('success', 'Voice Mail sent successfully!')
    },
    onError: (error: any) => {
      console.log("on error", error?.response?.data?.message);
      toastNotify('error', 'Error! Could not send voice mail!')
    },
  });

  function handlePostVoiceMailMutation() {
    // console.log("voice mail invoked...", parentCallSId)
    if (parentCallSId && receiverType) postVoiceMailMutation();
  }

  useEffect(() => {
    const token = twilioAccessTokenObj?.data?.token;
    if (token) {
      twilioAccessTokenRef.current = token

      device.current = new Device(token);
      if (device.current) {
        device.current.audio.outgoing(false)
        device.current.audio.disconnect(false)
      }
    }
  }, [twilioAccessTokenObj, isLoading, isFetching]);

  const makeCall = async () => {
    if (!(phoneNumber && receiverName && receiverId && device.current)) return;

    setIsManuallyHungUp(false)
    setIsOnCall(true);
    if (parentCallSId) handleParentCallSId(null)

    connectionRef.current = await device.current.connect({
      params: {
        To: phoneNumber,
        CallReceiver: receiverName,
        [receiverType === "voter" ? "VoterId" : "DonorId"]: receiverId,
      }
    });

    connectionRef.current.on("ringing", handleCallRingingEvent)
    connectionRef.current.on('accept', handleCallAcceptEvent)
    connectionRef.current.on('disconnect', handleCallDisconnectEvent)
    connectionRef.current.on('cancel', () => { console.log('cancel event ---') })
    connectionRef.current.on('error', (error: any) => { console.log('error event---', error) })
    connectionRef.current.on('reject', () => { console.log('reject event---') })

    hangUpButtonRef.current?.addEventListener("click", () => {
      connectionRef.current.disconnect();
      setIsManuallyHungUp(true)

      hangUpButtonRef.current?.removeEventListener("click", () => { });
    });
  };

  function handleCallRingingEvent() {
    handlePatchCallConcurrency({ contactStatus: 'call-in-progress' })
  }

  function handleCallAcceptEvent() {
    console.log("accept event---", phoneNumber, connectionRef.current?.parameters?.CallSid);

    handleParentCallSId(connectionRef.current?.parameters?.CallSid)
    setIsCallAccepted(true);
  }

  function handleCallDisconnectEvent() {
    console.log("disconnect event--");
    handlePatchCallConcurrency({ contactStatus: 'viewing' })


    setIsOnCall(false);
    setIsCallAccepted(false);
    setIsCallEnded(true);

    setTimeout(() => {
      setIsCallEnded(false);
    }, 2500);
  }

  useEffect(() => {
    if (
      autoDialerStatus === "Integrated" &&
      typeof phoneNumber === "string" &&
      twilioAccessTokenRef.current &&
      device.current
    ) {
      setIsCallEnabled(true)
    }
  }, [autoDialerStatus, phoneNumber, twilioAccessTokenObj, device.current])


  //Handling blocker for leaving page while making call
  const [showLeaveModal, setShowLeaveModal] = useState(false);

  let shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      isOnCall == true && currentLocation.pathname !== nextLocation.pathname,
    [isOnCall]
  );
  let blocker = useBlocker(shouldBlock);

  // Reset the blocker if the user cleans the form
  useEffect(() => {
    if (blocker.state === "blocked") {
      setShowLeaveModal(true);
    }
    if (blocker.state === "blocked" && isOnCall !== true) {
      blocker.reset();
    }
  }, [blocker.state, isOnCall]);

  const handleLeavePageEvent = () => {
    blocker.proceed?.();
    connectionRef.current.disconnect();

    if (isCallAccepted) {
      if (receiverId) {
        if (submitVoterButtonRef?.current) {
          submitVoterButtonRef?.current.click();
        }
        return;
      }
    }
  };

  //when refreshing page
  const beforeUnloadHandler = (event: any) => {
    event.preventDefault();
    event.returnValue = "";
  };

  useEffect(() => {
    if (isOnCall) {
      window.addEventListener("beforeunload", beforeUnloadHandler);
    }
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [isOnCall]);

  useEffect(() => {
    if (showLeaveModal && leavePageModalButtonRef?.current) {
      leavePageModalButtonRef.current.click();
    }
  }, [showLeaveModal]);

  return (
    <>
      {isOnCall ? (
        <div className="callBox">
          <OnCall
            hangUpButtonRef={hangUpButtonRef}
            isCallAccepted={isCallAccepted}
            setTotalCallDuration={setTotalCallDuration}
            handlePostVoiceMailMutation={handlePostVoiceMailMutation}
            receiverType={receiverType}
          />
        </div>
      ) : (
        <>
          {isCallEnded ? (
            <div className="callBox">
              <CallEnded
                isManuallyHungUp={isManuallyHungUp}
                totalCallDuration={totalCallDuration}
              />
            </div>
          ) : (
            <button
              className="btn btn-success btn-xs px-4 rounded-pill text-white"
              onClick={makeCall}
              disabled={!isCallEnabled}
            >
              <PhoneFilledIcon />
              Call
            </button>
          )}

        </>
      )}

      {/* Leave page modal button reference */}
      <span
        ref={leavePageModalButtonRef}
        data-bs-toggle={"modal"}
        data-bs-target={"#leaveModal"}
      ></span>

      {/* leave page Modal */}
      <div
        className="modal fade "
        id="leaveModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span
                  className="icon-close text-n1 h2"
                  onClick={() => {
                    blocker.reset?.();
                    setShowLeaveModal(false);
                  }}
                ></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../../../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Do you want to leave the page? </h2>
              <p>
                By leaving the page,the call will get disconnected and the
                contact will be marked as connected.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link btn-sm p-1 ps-0"
                onClick={() => {
                  blocker.reset?.();
                  setShowLeaveModal(false);
                }}
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary rounded-pill btn-xs"
                onClick={handleLeavePageEvent}
                data-bs-dismiss="modal"
              >
                Yes, leave
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

