import React, { useEffect } from "react";
import CloseIcon from "img/svg/closeIcon";
import LocationIcon from "img/svg/locationIcon";
import { Link } from "react-router-dom";
import ChevronDownIcon from "img/svg/chevronDownIcon";
import HelpIcon from "img/svg/helpIcon";
import InfoIconSecondary from "img/svg/InfoIconSecondary";

const SidePanel = () => {
  useEffect(() => {
    const accordionItems = document.querySelectorAll(".accordion-collapse");
    const acc = document.getElementById("accordionExample");

    if (acc) {
      accordionItems?.forEach((el: any) => {
        el.addEventListener("shown.bs.collapse", (e: any) => {
          var scrollOffset = acc.scrollTop + el.parentNode.offsetTop;

          window.scrollTo({
            top: scrollOffset,
            behavior: "smooth",
          });
          console.log("acc", acc.scroll);
        });
      });
    }
  }, []);

  return (
    <aside className="sidePanel control-sidebar open">
      <div className="sidePanel-header shadow-4">
        <div className="d-flex align-items-center justify-content-between gap-2">
          <div className="location-info">
            <LocationIcon />
            <div className="location-content">
              <div className="voter-location">1586 1st Avenue #2A</div>
              <div className="voter-count">3 voters </div>
            </div>
          </div>

          <div className="panel-close">
            <Link to="" className="close">
              <CloseIcon />
            </Link>
          </div>
        </div>
      </div>
      <div className="sidePanel-body scroll-y">

        {/* loading  */}
        {/* <div className="d-flex h-100 justify-content-center align-items-center">
          <div className="loader-demo-box">
            <div className="jumping-dots-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            
            </div>

            <h4 className="body-4 text-center"> Loading next address </h4>
          </div>

        </div> */}


        <div className="accordion accordion-flush" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <div>
                  <span className="body-4"> Jane Cooper </span>

                  <div className="body-4 text-muted mb-0 n-mt-2">
                    Female <span className="dot bg-neutral-n3 ms-2"></span>
                    28 <span className="dot bg-neutral-n3 ms-2"></span>
                    Republic
                  </div>
                  <div className="body-4 text-muted mb-0 n-mt-2">
                    Marked as <span className="text-s2 fw-semibold"> Not Home </span>
                   
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul
                  className="nav nav-tabs nav-flush position-relative ps-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    >
                      Voter Response
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab-pane"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab-pane"
                      aria-selected="false"
                    >
                      Script
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home-tab-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    tabIndex={0}
                  >
                    <form action="">
                      <div className="voter-status">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Voter Status </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="notHome"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="notHome"
                              >
                                Not Home
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="moved"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="moved"
                              >
                                Moved
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="deceased"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="deceased"
                              >
                                Deceased
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="wrongNumber"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="wrongNumber"
                              >
                                Wrong Number
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center py-3">
                          <hr className="w-100" />
                          <div className="px-4 text-muted">or</div>
                          <hr className="w-100" />
                        </div>
                      </div>

                      <div className="level-of-support">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Level of Support </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                        <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongSupport"
                              >
                                Strong Support
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanSupport"
                              >
                                Lean Support
                              </label>
                            </div>
                          </div>

                         

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="undecided"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="undecided"
                              >
                                Undecided
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanAgainst"
                              >
                                Lean Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongAgainst"
                              >
                                Strong Against
                              </label>
                            </div>
                          </div>
                          
                         
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                    tabIndex={0}
                  >
                    <h5 className="body-5"> Contact History </h5>
                    <div className="contact-history">
                      <div className="timeline timeline-sm">
                        <div className="timeline-item pb-4">
                          <div className="timeline-step">
                            <span> </span>
                          </div>
                          <div className="timeline-content">
                            <h4 className="mb-1 body-4">
                              {" "}
                              Marked as{" "}
                              <span className="text-success body-5">
                                {" "}
                                Not Home{" "}
                              </span>{" "}
                            </h4>
                            <p className="text-muted fs-8">
                              Called on Aug 4, 2023 {" "}
                              <span className="dot bg-neutral-n3 ms-1"> </span>{" "}
                              3:30pm
                            </p>
                          </div>
                        </div>

                        <div className="timeline-item pb-4">
                          <div className="timeline-step">
                            <span></span>
                          </div>
                          <div className="timeline-content">
                            <h4 className="mb-1 body-4">
                              {" "}
                              Marked as{" "}
                              <span className="text-success body-5">
                                {" "}
                                Not Home{" "}
                              </span>{" "}
                            </h4>
                            <p className="text-muted fs-8">
                              Called on Aug 5, 2023{" "}
                              <span className="dot bg-neutral-n3 ms-1"> </span>{" "}
                              3:30pm
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* if no history */}

                    {/* <div className="d-flex gap-2 flex-row align-items-center">
                        <InfoIconSecondary />
                        <p className="m-0 text-muted fs-7"> No tasks available for today </p>
                    </div>  */}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                    tabIndex={0}
                  >
                    <div className="card p-3 scroll-y script-wrapper">
                        <p> Hi, is [Voter Name] available?  </p>
                        <p>Hi, [Voter Name], my name is [Candidate Name] and I'm running for [Position], right here in [city voter lives in]. How are you doing today?</p>
                        <p>The reason I'm calling today is because we have a really important election upcoming for [Position]. </p>
                        <p>I'm running for [Position] because [Strength 1] and [Strength 2].</p>
                        <p>Can I count on your support?</p>
                        <p>Thanks so much for your time today!</p>
                    </div>
                  </div>
                </div>

                <div className="card-footer d-flex shadow-6 justify-content-end">
                  <button className="btn btn-link btn-xs"> Cancel </button>
                  <button className="btn btn-primary rounded-pill btn-xs py-1 px-4 disabled">
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <div>
                  <span className="body-4"> John Doe </span>

                  <div className="body-4 text-muted mb-0 n-mt-2">
                    Male <span className="dot bg-neutral-n3 ms-2"></span>
                    30 <span className="dot bg-neutral-n3 ms-2"></span>
                    Republic
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul
                  className="nav nav-tabs nav-flush position-relative ps-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab1"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab1-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab1-pane"
                      aria-selected="true"
                    >
                      Voter Response
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab1"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab1-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab1-pane"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab1"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab1-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab1-pane"
                      aria-selected="false"
                    >
                      Script
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade"
                    id="home-tab1-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab1"
                    tabIndex={0}
                  >
                    <form action="">
                      <div className="voter-status">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Voter Status </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="notHome"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="notHome"
                              >
                                Not Home
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="moved"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="moved"
                              >
                                Moved
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="deceased"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="deceased"
                              >
                                Deceased
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="wrongNumber"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="wrongNumber"
                              >
                                Wrong Number
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center py-3">
                          <hr className="w-100" />
                          <div className="px-4 text-muted">or</div>
                          <hr className="w-100" />
                        </div>
                      </div>

                      <div className="level-of-support">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Level of Support </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongAgainst"
                              >
                                Strong Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanAgainst"
                              >
                                Lean Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="undecided"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="undecided"
                              >
                                Undecided
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanSupport"
                              >
                                Lean Support
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongSupport"
                              >
                                Strong Support
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab1-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab1"
                    tabIndex={0}
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab1-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab1"
                    tabIndex={0}
                  >
                    ...
                  </div>
                </div>

                <div className="card-footer d-flex shadow-6 justify-content-end">
                  <button className="btn btn-link btn-xs"> Cancel </button>
                  <button className="btn btn-primary rounded-pill btn-xs py-1 px-4 disabled">
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                <div>
                  <span className="body-4"> Sarah Hyland </span>

                  <div className="body-4 text-muted mb-0 n-mt-2">
                    Female <span className="dot bg-neutral-n3 ms-2"></span>
                    22 <span className="dot bg-neutral-n3 ms-2"></span>
                    Republic
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul
                  className="nav nav-tabs nav-flush position-relative ps-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab2"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab2-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab2-pane"
                      aria-selected="true"
                    >
                      Voter Response
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab2"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab2-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab2-pane"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab2"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab2-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab2-pane"
                      aria-selected="false"
                    >
                      Script
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade"
                    id="home-tab2-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab2"
                    tabIndex={0}
                  >
                    <form action="">
                      <div className="voter-status">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Voter Status </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="notHome"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="notHome"
                              >
                                Not Home
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="moved"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="moved"
                              >
                                Moved
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="deceased"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="deceased"
                              >
                                Deceased
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="wrongNumber"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="wrongNumber"
                              >
                                Wrong Number
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center py-3">
                          <hr className="w-100" />
                          <div className="px-4 text-muted">or</div>
                          <hr className="w-100" />
                        </div>
                      </div>

                      <div className="level-of-support">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Level of Support </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongAgainst"
                              >
                                Strong Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanAgainst"
                              >
                                Lean Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="undecided"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="undecided"
                              >
                                Undecided
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanSupport"
                              >
                                Lean Support
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongSupport"
                              >
                                Strong Support
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab2-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab2"
                    tabIndex={0}
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab2-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab2"
                    tabIndex={0}
                  >
                    ...
                  </div>
                </div>

                <div className="card-footer d-flex shadow-6 justify-content-end">
                  <button className="btn btn-link btn-xs"> Cancel </button>
                  <button className="btn btn-primary rounded-pill btn-xs py-1 px-4 disabled">
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="true"
                aria-controls="collapseFour"
              >
                <div>
                  <span className="body-4"> Marcus Jordand </span>

                  <div className="body-4 text-muted mb-0 n-mt-2">
                    Male <span className="dot bg-neutral-n3 ms-2"></span>
                    25 <span className="dot bg-neutral-n3 ms-2"></span>
                    Republic
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul
                  className="nav nav-tabs nav-flush position-relative ps-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab3"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab3-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab3-pane"
                      aria-selected="true"
                    >
                      Voter Response
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab3"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab3-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab3-pane"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab3"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab3-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab3-pane"
                      aria-selected="false"
                    >
                      Script
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade"
                    id="home-tab3-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab3"
                    tabIndex={0}
                  >
                    <form action="">
                      <div className="voter-status">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Voter Status </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="notHome"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="notHome"
                              >
                                Not Home
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="moved"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="moved"
                              >
                                Moved
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="deceased"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="deceased"
                              >
                                Deceased
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="wrongNumber"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="wrongNumber"
                              >
                                Wrong Number
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center py-3">
                          <hr className="w-100" />
                          <div className="px-4 text-muted">or</div>
                          <hr className="w-100" />
                        </div>
                      </div>

                      <div className="level-of-support">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Level of Support </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongAgainst"
                              >
                                Strong Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanAgainst"
                              >
                                Lean Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="undecided"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="undecided"
                              >
                                Undecided
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanSupport"
                              >
                                Lean Support
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongSupport"
                              >
                                Strong Support
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab3-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab3"
                    tabIndex={0}
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab3-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab3"
                    tabIndex={0}
                  >
                    ...
                  </div>
                </div>

                <div className="card-footer d-flex shadow-6 justify-content-end">
                  <button className="btn btn-link btn-xs"> Cancel </button>
                  <button className="btn btn-primary rounded-pill btn-xs py-1 px-4 disabled">
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="true"
                aria-controls="collapseFive"
              >
                <div>
                  <span className="body-4"> Richard Mille </span>

                  <div className="body-4 text-muted mb-0 n-mt-2">
                    Male <span className="dot bg-neutral-n3 ms-2"></span>
                    25 <span className="dot bg-neutral-n3 ms-2"></span>
                    Republic
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul
                  className="nav nav-tabs nav-flush position-relative ps-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab4"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab4-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab4-pane"
                      aria-selected="true"
                    >
                      Voter Response
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab4"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab4-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab4-pane"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab4"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab4-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab4-pane"
                      aria-selected="false"
                    >
                      Script
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade"
                    id="home-tab4-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab4"
                    tabIndex={0}
                  >
                    <form action="">
                      <div className="voter-status">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Voter Status </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="notHome"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="notHome"
                              >
                                Not Home
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="moved"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="moved"
                              >
                                Moved
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="deceased"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="deceased"
                              >
                                Deceased
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="wrongNumber"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="wrongNumber"
                              >
                                Wrong Number
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center py-3">
                          <hr className="w-100" />
                          <div className="px-4 text-muted">or</div>
                          <hr className="w-100" />
                        </div>
                      </div>

                      <div className="level-of-support">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Level of Support </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongAgainst"
                              >
                                Strong Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanAgainst"
                              >
                                Lean Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="undecided"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="undecided"
                              >
                                Undecided
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanSupport"
                              >
                                Lean Support
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongSupport"
                              >
                                Strong Support
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab4-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab4"
                    tabIndex={0}
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab4-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab4"
                    tabIndex={0}
                  >
                    ...
                  </div>
                </div>

                <div className="card-footer d-flex shadow-6 justify-content-end">
                  <button className="btn btn-link btn-xs"> Cancel </button>
                  <button className="btn btn-primary rounded-pill btn-xs py-1 px-4 disabled">
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="true"
                aria-controls="collapseSix"
              >
                <div>
                  <span className="body-4"> Jonah Hill </span>

                  <div className="body-4 text-muted mb-0 n-mt-2">
                    Male <span className="dot bg-neutral-n3 ms-2"></span>
                    25 <span className="dot bg-neutral-n3 ms-2"></span>
                    Republic
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul
                  className="nav nav-tabs nav-flush position-relative ps-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab5"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab5-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab5-pane"
                      aria-selected="true"
                    >
                      Voter Response
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab5"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab5-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab5-pane"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab5"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab5-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab5-pane"
                      aria-selected="false"
                    >
                      Script
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade"
                    id="home-tab5-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab5"
                    tabIndex={0}
                  >
                    <form action="">
                      <div className="voter-status">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Voter Status </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="notHome"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="notHome"
                              >
                                Not Home
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="moved"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="moved"
                              >
                                Moved
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="deceased"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="deceased"
                              >
                                Deceased
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="wrongNumber"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="wrongNumber"
                              >
                                Wrong Number
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center py-3">
                          <hr className="w-100" />
                          <div className="px-4 text-muted">or</div>
                          <hr className="w-100" />
                        </div>
                      </div>

                      <div className="level-of-support">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Level of Support </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongAgainst"
                              >
                                Strong Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanAgainst"
                              >
                                Lean Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="undecided"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="undecided"
                              >
                                Undecided
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanSupport"
                              >
                                Lean Support
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongSupport"
                              >
                                Strong Support
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab5-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab5"
                    tabIndex={0}
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab5-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab5"
                    tabIndex={0}
                  >
                    ...
                  </div>
                </div>

                <div className="card-footer d-flex shadow-6 justify-content-end">
                  <button className="btn btn-link btn-xs"> Cancel </button>
                  <button className="btn btn-primary rounded-pill btn-xs py-1 px-4 disabled">
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="true"
                aria-controls="collapseSeven"
              >
                <div>
                  <span className="body-4"> David Stakson </span>

                  <div className="body-4 text-muted mb-0 n-mt-2">
                    Male <span className="dot bg-neutral-n3 ms-2"></span>
                    27 <span className="dot bg-neutral-n3 ms-2"></span>
                    Republic
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul
                  className="nav nav-tabs nav-flush position-relative ps-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab6"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab6-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab6-pane"
                      aria-selected="true"
                    >
                      Voter Response
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab6"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab6-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab6-pane"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab6"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab6-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab6-pane"
                      aria-selected="false"
                    >
                      Script
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade"
                    id="home-tab6-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab6"
                    tabIndex={0}
                  >
                    <form action="">
                      <div className="voter-status">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Voter Status </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="notHome"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="notHome"
                              >
                                Not Home
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="moved"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="moved"
                              >
                                Moved
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="deceased"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="deceased"
                              >
                                Deceased
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="wrongNumber"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="wrongNumber"
                              >
                                Wrong Number
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center py-3">
                          <hr className="w-100" />
                          <div className="px-4 text-muted">or</div>
                          <hr className="w-100" />
                        </div>
                      </div>

                      <div className="level-of-support">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Level of Support </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongAgainst"
                              >
                                Strong Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanAgainst"
                              >
                                Lean Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="undecided"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="undecided"
                              >
                                Undecided
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanSupport"
                              >
                                Lean Support
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongSupport"
                              >
                                Strong Support
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab6-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab6"
                    tabIndex={0}
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab6-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab6"
                    tabIndex={0}
                  >
                    ...
                  </div>
                </div>

                <div className="card-footer d-flex shadow-6 justify-content-end">
                  <button className="btn btn-link btn-xs"> Cancel </button>
                  <button className="btn btn-primary rounded-pill btn-xs py-1 px-4 disabled">
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="true"
                aria-controls="collapseEight"
              >
                <div>
                  <span className="body-4"> Jonas Strand </span>

                  <div className="body-4 text-muted mb-0 n-mt-2">
                    Male <span className="dot bg-neutral-n3 ms-2"></span>
                    27 <span className="dot bg-neutral-n3 ms-2"></span>
                    Republic
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseEight"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul
                  className="nav nav-tabs nav-flush position-relative ps-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab7"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab7-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab7-pane"
                      aria-selected="true"
                    >
                      Voter Response
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab7"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab7-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab7-pane"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab7"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab7-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab7-pane"
                      aria-selected="false"
                    >
                      Script
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade"
                    id="home-tab7-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab7"
                    tabIndex={0}
                  >
                    <form action="">
                      <div className="voter-status">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Voter Status </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="notHome"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="notHome"
                              >
                                Not Home
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="moved"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="moved"
                              >
                                Moved
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="deceased"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="deceased"
                              >
                                Deceased
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="wrongNumber"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="wrongNumber"
                              >
                                Wrong Number
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center py-3">
                          <hr className="w-100" />
                          <div className="px-4 text-muted">or</div>
                          <hr className="w-100" />
                        </div>
                      </div>

                      <div className="level-of-support">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Level of Support </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongAgainst"
                              >
                                Strong Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanAgainst"
                              >
                                Lean Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="undecided"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="undecided"
                              >
                                Undecided
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanSupport"
                              >
                                Lean Support
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongSupport"
                              >
                                Strong Support
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab7-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab7"
                    tabIndex={0}
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab7-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab7"
                    tabIndex={0}
                  >
                    ...
                  </div>
                </div>

                <div className="card-footer d-flex shadow-6 justify-content-end">
                  <button className="btn btn-link btn-xs"> Cancel </button>
                  <button className="btn btn-primary rounded-pill btn-xs py-1 px-4 disabled">
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseNine"
                aria-expanded="true"
                aria-controls="collapseNine"
              >
                <div>
                  <span className="body-4"> Emma Bones </span>

                  <div className="body-4 text-muted mb-0 n-mt-2">
                    Male <span className="dot bg-neutral-n3 ms-2"></span>
                    27 <span className="dot bg-neutral-n3 ms-2"></span>
                    Republic
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseNine"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul
                  className="nav nav-tabs nav-flush position-relative ps-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab8"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab8-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab8-pane"
                      aria-selected="true"
                    >
                      Voter Response
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab8"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab8-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab8-pane"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab8"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab8-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab8-pane"
                      aria-selected="false"
                    >
                      Script
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade"
                    id="home-tab8-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab8"
                    tabIndex={0}
                  >
                    <form action="">
                      <div className="voter-status">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Voter Status </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="notHome"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="notHome"
                              >
                                Not Home
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="moved"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="moved"
                              >
                                Moved
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="deceased"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="deceased"
                              >
                                Deceased
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="wrongNumber"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="wrongNumber"
                              >
                                Wrong Number
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center py-3">
                          <hr className="w-100" />
                          <div className="px-4 text-muted">or</div>
                          <hr className="w-100" />
                        </div>
                      </div>

                      <div className="level-of-support">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Level of Support </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongAgainst"
                              >
                                Strong Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanAgainst"
                              >
                                Lean Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="undecided"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="undecided"
                              >
                                Undecided
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanSupport"
                              >
                                Lean Support
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongSupport"
                              >
                                Strong Support
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab7-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab6"
                    tabIndex={0}
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab7-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab6"
                    tabIndex={0}
                  >
                    ...
                  </div>
                </div>

                <div className="card-footer d-flex shadow-6 justify-content-end">
                  <button className="btn btn-link btn-xs"> Cancel </button>
                  <button className="btn btn-primary rounded-pill btn-xs py-1 px-4 disabled">
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTen"
                aria-expanded="true"
                aria-controls="collapseTen"
              >
                <div>
                  <span className="body-4"> Matt Long </span>

                  <div className="body-4 text-muted mb-0 n-mt-2">
                    Male <span className="dot bg-neutral-n3 ms-2"></span>
                    34<span className="dot bg-neutral-n3 ms-2"></span>
                    Republic
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseTen"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul
                  className="nav nav-tabs nav-flush position-relative ps-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab9"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab9-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab9-pane"
                      aria-selected="true"
                    >
                      Voter Response
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab9"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab9-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab9-pane"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab9"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab9-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab9-pane"
                      aria-selected="false"
                    >
                      Script
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade"
                    id="home-tab9-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab9"
                    tabIndex={0}
                  >
                    <form action="">
                      <div className="voter-status">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Voter Status </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="notHome"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="notHome"
                              >
                                Not Home
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="moved"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="moved"
                              >
                                Moved
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="deceased"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="deceased"
                              >
                                Deceased
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="wrongNumber"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="wrongNumber"
                              >
                                Wrong Number
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center py-3">
                          <hr className="w-100" />
                          <div className="px-4 text-muted">or</div>
                          <hr className="w-100" />
                        </div>
                      </div>

                      <div className="level-of-support">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Level of Support </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongAgainst"
                              >
                                Strong Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanAgainst"
                              >
                                Lean Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="undecided"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="undecided"
                              >
                                Undecided
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanSupport"
                              >
                                Lean Support
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongSupport"
                              >
                                Strong Support
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab9-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab9"
                    tabIndex={0}
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab9-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab9"
                    tabIndex={0}
                  >
                    ...
                  </div>
                </div>

                <div className="card-footer d-flex shadow-6 justify-content-end">
                  <button className="btn btn-link btn-xs"> Cancel </button>
                  <button className="btn btn-primary rounded-pill btn-xs py-1 px-4 disabled">
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEleven"
                aria-expanded="true"
                aria-controls="collapseEleven"
              >
                <div>
                  <span className="body-4"> Joseph Dallas </span>

                  <div className="body-4 text-muted mb-0 n-mt-2">
                    Male <span className="dot bg-neutral-n3 ms-2"></span>
                    27 <span className="dot bg-neutral-n3 ms-2"></span>
                    Republic
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseEleven"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul
                  className="nav nav-tabs nav-flush position-relative ps-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab10"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab10-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab10-pane"
                      aria-selected="true"
                    >
                      Voter Response
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab10"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab10-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab10-pane"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab10"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab10-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab10-pane"
                      aria-selected="false"
                    >
                      Script
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade"
                    id="home-tab10-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab10"
                    tabIndex={0}
                  >
                    <form action="">
                      <div className="voter-status">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Voter Status </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="notHome"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="notHome"
                              >
                                Not Home
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="moved"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="moved"
                              >
                                Moved
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="deceased"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="deceased"
                              >
                                Deceased
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="wrongNumber"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="wrongNumber"
                              >
                                Wrong Number
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center py-3">
                          <hr className="w-100" />
                          <div className="px-4 text-muted">or</div>
                          <hr className="w-100" />
                        </div>
                      </div>

                      <div className="level-of-support">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Level of Support </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongAgainst"
                              >
                                Strong Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanAgainst"
                              >
                                Lean Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="undecided"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="undecided"
                              >
                                Undecided
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanSupport"
                              >
                                Lean Support
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongSupport"
                              >
                                Strong Support
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab10-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab10"
                    tabIndex={0}
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab10-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab10"
                    tabIndex={0}
                  >
                    ...
                  </div>
                </div>

                <div className="card-footer d-flex shadow-6 justify-content-end">
                  <button className="btn btn-link btn-xs"> Cancel </button>
                  <button className="btn btn-primary rounded-pill btn-xs py-1 px-4 disabled">
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwelve"
                aria-expanded="true"
                aria-controls="collapseTwelve"
              >
                <div>
                  <span className="body-4"> Joseph Dallas </span>

                  <div className="body-4 text-muted mb-0 n-mt-2">
                    Male <span className="dot bg-neutral-n3 ms-2"></span>
                    27 <span className="dot bg-neutral-n3 ms-2"></span>
                    Republic
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseTwelve"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul
                  className="nav nav-tabs nav-flush position-relative ps-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab11"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab11-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab11-pane"
                      aria-selected="true"
                    >
                      Voter Response
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab11"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab11-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab11-pane"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab11"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab11-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab11-pane"
                      aria-selected="false"
                    >
                      Script
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade"
                    id="home-tab11-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab11"
                    tabIndex={0}
                  >
                    <form action="">
                      <div className="voter-status">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Voter Status </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="notHome"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="notHome"
                              >
                                Not Home
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="moved"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="moved"
                              >
                                Moved
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="deceased"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="deceased"
                              >
                                Deceased
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="wrongNumber"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="wrongNumber"
                              >
                                Wrong Number
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center py-3">
                          <hr className="w-100" />
                          <div className="px-4 text-muted">or</div>
                          <hr className="w-100" />
                        </div>
                      </div>

                      <div className="level-of-support">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Level of Support </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongAgainst"
                              >
                                Strong Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanAgainst"
                              >
                                Lean Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="undecided"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="undecided"
                              >
                                Undecided
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanSupport"
                              >
                                Lean Support
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongSupport"
                              >
                                Strong Support
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab11-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab11"
                    tabIndex={0}
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab11-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab11"
                    tabIndex={0}
                  >
                    ...
                  </div>
                </div>

                <div className="card-footer d-flex shadow-6 justify-content-end">
                  <button className="btn btn-link btn-xs"> Cancel </button>
                  <button className="btn btn-primary rounded-pill btn-xs py-1 px-4 disabled">
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThirteen"
                aria-expanded="true"
                aria-controls="collapseThirteen"
              >
                <div>
                  <span className="body-4"> Luna Blaise </span>

                  <div className="body-4 text-muted mb-0 n-mt-2">
                    Female <span className="dot bg-neutral-n3 ms-2"></span>
                    32 <span className="dot bg-neutral-n3 ms-2"></span>
                    Republic
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseThirteen"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul
                  className="nav nav-tabs nav-flush position-relative ps-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab12"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab12-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab12-pane"
                      aria-selected="true"
                    >
                      Voter Response
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab12"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab12-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab12-pane"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab12"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab12-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab12-pane"
                      aria-selected="false"
                    >
                      Script
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade"
                    id="home-tab12-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab12"
                    tabIndex={0}
                  >
                    <form action="">
                      <div className="voter-status">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Voter Status </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="notHome"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="notHome"
                              >
                                Not Home
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="moved"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="moved"
                              >
                                Moved
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="deceased"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="deceased"
                              >
                                Deceased
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="wrongNumber"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="wrongNumber"
                              >
                                Wrong Number
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center py-3">
                          <hr className="w-100" />
                          <div className="px-4 text-muted">or</div>
                          <hr className="w-100" />
                        </div>
                      </div>

                      <div className="level-of-support">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Level of Support </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongAgainst"
                              >
                                Strong Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanAgainst"
                              >
                                Lean Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="undecided"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="undecided"
                              >
                                Undecided
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanSupport"
                              >
                                Lean Support
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongSupport"
                              >
                                Strong Support
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab12-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab12"
                    tabIndex={0}
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab12-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab12"
                    tabIndex={0}
                  >
                    ...
                  </div>
                </div>

                <div className="card-footer d-flex shadow-6 justify-content-end">
                  <button className="btn btn-link btn-xs"> Cancel </button>
                  <button className="btn btn-primary rounded-pill btn-xs py-1 px-4 disabled">
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFourteen"
                aria-expanded="true"
                aria-controls="collapseFourteen"
              >
                <div>
                  <span className="body-4"> Omar Rudberg </span>

                  <div className="body-4 text-muted mb-0 n-mt-2">
                    Female <span className="dot bg-neutral-n3 ms-2"></span>
                    32 <span className="dot bg-neutral-n3 ms-2"></span>
                    Republic
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseFourteen"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul
                  className="nav nav-tabs nav-flush position-relative ps-0 justify-content-between"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab13"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab13-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab13-pane"
                      aria-selected="true"
                    >
                      Voter Response
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab13"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab13-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab13-pane"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab13"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab13-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab13-pane"
                      aria-selected="false"
                    >
                      Script
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade"
                    id="home-tab13-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab13"
                    tabIndex={0}
                  >
                    <form action="">
                      <div className="voter-status">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Voter Status </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="notHome"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="notHome"
                              >
                                Not Home
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="moved"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="moved"
                              >
                                Moved
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="deceased"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="deceased"
                              >
                                Deceased
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="wrongNumber"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="wrongNumber"
                              >
                                Wrong Number
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center py-3">
                          <hr className="w-100" />
                          <div className="px-4 text-muted">or</div>
                          <hr className="w-100" />
                        </div>
                      </div>

                      <div className="level-of-support">
                        <div className="d-flex justify-content-between">
                          <label className="body-5"> Level of Support </label>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title=""
                            >
                              {" "}
                              <HelpIcon />
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongAgainst"
                              >
                                Strong Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanAgainst"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanAgainst"
                              >
                                Lean Against
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="undecided"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="undecided"
                              >
                                Undecided
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="leanSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="leanSupport"
                              >
                                Lean Support
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                              <input
                                type="radio"
                                className="btn-check form-check-input"
                                name="statusOption"
                                id="strongSupport"
                                autoComplete="off"
                              />
                              <label
                                className="btn btn-outline-primary w-100 ps-5 text-start"
                                htmlFor="strongSupport"
                              >
                                Strong Support
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab13-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab13"
                    tabIndex={0}
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab13-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab13"
                    tabIndex={0}
                  >
                    ...
                  </div>
                </div>

                <div className="card-footer d-flex shadow-6 justify-content-end">
                  <button className="btn btn-link btn-xs"> Cancel </button>
                  <button className="btn rounded-pill btn-xs py-1 px-4 disabled">
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </div>
      <div className="sidePanel-footer card-footer d-flex justify-content-center">
        <Link
          to=""
          className="btn fw-bold bg-primary-p5 btn-xs rounded-pill disabled py-2 px-4 w-100"
        >
          {" "}
          Save and next door{" "}
        </Link>
      </div>
    </aside>
  );
};

export default SidePanel;
