import { useMutation, useQuery } from "@tanstack/react-query";
import { Tooltip } from "bootstrap";
import { CANDIDATE_ID, VOLUNTEER_ID, VOLUNTEER_NAME } from "components/WelcomeModal";
import { useVolunteersDetail } from "global-state/contexts/VolunteerContext";
import InfoIconLight from "img/svg/InfoIconLight";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import CreateAuthAxiosInstance from "utils/authAxios";
import { splitFirstName } from "utils/getFirstName";
import { replaceMultipleWords } from "utils/replaceMultipleWords";
import toastNotify from "utils/toastNotify";
import * as yup from "yup";

export interface IVotersResponse {
  voterStatus: string | null;
  levelOfSupport: string | null;
  contactStatus: string | null;
}
export interface IVoterContactHistory {
  id: number;
  createdAt: string;
  updatedAt: string;
  voterId: number;
  levelOfSupport: string;
  status: string;
  contactorType: string;
}
export interface IVotersToContactByPhone {
  id: number;
  name: string;
  address: string;
  partyAffiliation: string;
  age: number | null;
  sex: string;
  phoneNumber: string | null;
  voterContactHistories: IVoterContactHistory[];
}

export interface IVoterCount {
  doorknocks: number;
  phoneCalls: number;
}

export enum CONTACT_TYPE {
  BY_PHONE = "By Phone",
  DOOR_TO_DOOR = "Door to Door",
}

const VOTER_STATUS = ["Not Home", "Moved", "Deceased", "Wrong Number"];
const LEVEL_OF_SUPPORT = [
  "Strong Support",
  "Lean Support",
  "Undecided",
  "Lean Against",
  "Strong Against",
];
const voterStatusTooltip = `Not Home: the voter did not pick up the phone, or you reached someone besides the target voter. We will try again with this voter soon. <br /> <br /> Moved: the voter no longer lives at this address. We will not target this voter further. <br /> <br /> Deceased: the voter has passed away. We will not target this voter further. <br /> <br /> Wrong Number: the phone number was not valid.. We will target this voter door-to-door instead.`;
const losTooltip = `Strong Support: The voter clearly communicated they will definitely be supporting you. <br /> <br /> Lean Support:  The voter communicated they will likely be supporting you <br /> <br /> Undecided: The votercommunicated they aren’t sure who they will vote for or they haven’t decided yet. <br /> <br /> Lean Against: The voter communicated they will likely NOT be supporting you. <br /> <br /> Strong Against: The voter clearly communicated they will definitely NOT be supporting you. `;

export const votersResponseFormSchema = yup.object().shape(
  {
    voterStatus: yup.string().when("levelOfSupport", {
      is: (voterStatus: string) => !voterStatus,
      then: () => {
        return yup
          .string()
          .required("Select either Voter Status or Level of Support");
      },
      otherwise: () => yup.string().notRequired(),
    }),
    levelOfSupport: yup.number().when("voterStatus", {
      is: (voterStatus: string) => !voterStatus,
      then: () => {
        return yup
          .string()
          .required("Select either Voter Status or Level of Support");
      },
      otherwise: () => yup.string().notRequired(),
    }),
  },
  [["voterStatus", "levelOfSupport"]]
);

export const useContactByPhone = () => {
  const authAxios = CreateAuthAxiosInstance();

  const { id, fullName, candidateId } = useVolunteersDetail();
  const volunteerId = id;

  const submitRef = useRef<HTMLButtonElement>(null);

  const [activeIndex, setActiveIndex] = useState(0);
  // const [voterId, setVoterId] = useState(0);
  const [isScriptActive, setIsScriptActive] = useState(true);

  const [selectedVoterStatus, setSelectedVoterStatus] = useState("");
  const [selectedLevelOfSupport, setSelectedLevelOfSupport] = useState("");
  const [votersToContactByPhone, setVotersToContactByPhone] = useState<
    Array<IVotersToContactByPhone>
  >([]);
  const [votersCount, setVotersCount] = useState<IVoterCount>();

  const [selectedOption, setSelectedOption] = useState<undefined | string>(
    "defaultScript"
  );
  const [finalScript, setFinalScript] = useState("");

  const latestVoterContactHistoryId = useRef<number | null>(null)
  const currentVoterId = useRef<number | null | undefined>(null)

  const buttonText = isScriptActive ? "Hide Script" : "Show Script";

  const handleScript = () => {
    setIsScriptActive(!isScriptActive);
  };

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  const getVotersDetails = async () => {
    try {
      setActiveIndex(0);
      const volunteerData = await authAxios.get(
        `api/v1/volunteers/voters/${candidateId}?contactType=${CONTACT_TYPE.BY_PHONE}`
      );
      if (volunteerData) {
        const mappedData = volunteerData?.data?.items?.map(
          (item: IVotersToContactByPhone) => ({
            id: item.id,
            name: item.name,
            address: item.address,
            phoneNumber: item.phoneNumber,
            age: item.age,
            sex:
              item.sex === "F"
                ? "Female"
                : item.sex === "M"
                  ? "Male"
                  : item.sex,
            partyAffiliation: item.partyAffiliation,
            voterContactHistories: item.voterContactHistories,
          })
        );
        setVotersToContactByPhone(mappedData);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    (async () => {
      if (candidateId) {
        await getVotersDetails();
        await getContactedVotersCount();
      }
    })();
  }, [candidateId]);

  const submitVoterResponse = async (data: IVotersResponse) => {
    try {
      if (votersToContactByPhone?.length > 0) {
        // api/v1/volunteers/voters/contacts
        const voterID = votersToContactByPhone[activeIndex]?.id;
        const res = await authAxios.patch(
          `api/v1/volunteers/voters/contacts/${latestVoterContactHistoryId.current}`,
          {
            levelOfSupport: data.levelOfSupport || null,
            status: data.voterStatus || null,
            voterId: voterID,
            contactedBy: Number(volunteerId),
            contactStatus:
              data.contactStatus ||
              (data.voterStatus === "Not Home" ? "failed" : "completed"),
          }
        );
        if (res) {
          // toast.success("Successfully submitted the response", {
          //   toastId: 1,
          //   className: "snackBar",
          //   position: toast.POSITION.BOTTOM_RIGHT,
          //   theme: "dark",
          //   hideProgressBar: true,
          //   autoClose: 5000,
          //   icon: <InfoIconLight />,
          // });
          toastNotify("success", "Successfully submitted the response");


          refetchVolunteerDetail()
          latestVoterContactHistoryId.current = null;
          await getContactedVotersCount();

          if (activeIndex === votersToContactByPhone?.length - 1) {
            await getVotersDetails();
          } else {
            setActiveIndex(activeIndex + 1);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getContactedVotersCount = async () => {
    try {
      const voterCount = await authAxios.get(
        `api/v1/volunteers/voters/contact-stats/${candidateId}/${volunteerId}`
      );
      if (voterCount) {
        const phoneCount = voterCount.data;
        setVotersCount(phoneCount);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const [isActivePopup, setIsActivePopup] = useState<boolean>(true);
  const [autoDialerStatus, setAutoDialerStatus] = useState<string | null>(null);
  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState<string | null>(
    null
  );


  const {
    data: volunteerDetail,
    refetch: refetchVolunteerDetail,
    isPending: isPendingVolunteerDetail,
    isError: isErrorVolunteerDetail,
    status: volunteerDetailQueryStatus

  } = useQuery({
    queryKey: ["volunteerDetail", "contactByPhone"],
    queryFn: () =>
      authAxios.get("api/v1/volunteers/detail").then((data) => data?.data),
  });

  useEffect(() => {
    if (volunteerDetailQueryStatus === "success") {
      const autoDialerStatusFromApi = volunteerDetail?.autoDialerStatus;
      const hasSeenAutoDialerStatusPopup = volunteerDetail?.hasSeenAutoDialerStatusPopup;

      if (autoDialerStatusFromApi) setAutoDialerStatus(autoDialerStatusFromApi);
      if (
        autoDialerStatusFromApi === "Integrated" ||
        autoDialerStatusFromApi === "Rejected" ||
        (
          (
            autoDialerStatusFromApi === 'Disabled' ||
            autoDialerStatusFromApi === 'Inactive' ||
            autoDialerStatusFromApi === 'Blocked'
          )
          && hasSeenAutoDialerStatusPopup
        )
      ) {
        setIsActivePopup(false);
      }
      if (volunteerDetail?.phoneNumber?.phoneNumber)
        setVerifiedPhoneNumber(volunteerDetail?.phoneNumber?.phoneNumber);
    }
  }, [volunteerDetail, isPendingVolunteerDetail, volunteerDetailQueryStatus]);

  const handleModalClick = () => setIsActivePopup(true);
  const handleCancelModal = () => setIsActivePopup(false);

  //Handling voters contact status concurrency

  const {
    mutate: postCallConcurrency,
    isPending: isPendingPostCallConcurrency,
    data: postCallConcurrencyResponseData,
  } = useMutation({
    mutationFn: (data: any) =>
      authAxios
        .post(`api/v1/volunteers/voters/contacts`, data)
        .then((data) => data?.data),
    onSuccess: (data) => {
      // console.log("Concurrency response", data?.id);
      if (data?.id) latestVoterContactHistoryId.current = data.id;
    },
    onError: (error: unknown) => {
      // console.log("on error", error?.response?.data?.message);
    },
  });

  const {
    mutate: patchCallConcurrency,
    isPending: isPendingPatchCallConcurrency,
  } = useMutation({
    mutationFn: (data: any) =>
      authAxios
        .patch(
          `api/v1/volunteers/voters/contacts/${latestVoterContactHistoryId.current}`,
          data
        )
        .then((data) => data?.data),
    onSuccess: (data) => {
      // console.log("Concurrency patch response", data);
    },
    onError: (error: unknown) => {
      // console.log("on error", error?.response?.data?.message);
    },
  });

  function handlePatchCallConcurrency(data: any) {
    if (latestVoterContactHistoryId.current) {
      patchCallConcurrency(data);
    }
  }

  //Run when voter detailpage is loaded
  useEffect(() => {
    if (
      volunteerId &&
      votersToContactByPhone[activeIndex]?.id &&
      latestVoterContactHistoryId.current === null &&
      isPendingPostCallConcurrency !== true
    ) {
      currentVoterId.current = votersToContactByPhone[activeIndex]?.id;
      postCallConcurrency({
        contactedBy: Number(volunteerId),
        voterId: currentVoterId.current,
        status: null,
        levelOfSupport: null,
        contactStatus: "viewing",
      });
    }
  }, [volunteerId, votersToContactByPhone, activeIndex]);

  //Run when user move to next page without any interaction in voter detail page
  useEffect(() => {
    return () => {
      handlePatchCallConcurrency({
        voterId: currentVoterId.current,
        contactStatus: null,
      });
    };
  }, []);

  const fetchScriptData = async () => {
    const resp = await authAxios.get("api/v1/call-scripts/volunteers");
    return resp.data;
  };

  const getScriptData = useQuery({
    queryKey: ["script-volunteer-data"],
    queryFn: fetchScriptData,
    refetchOnWindowFocus: false,
  });
  const { data: fetchScriptsData } = getScriptData;

  const {
    data: candidateInfo,
    status: candidateInfoStatus
  } = useQuery({
    queryKey: ["candidateInfo"],
    queryFn: () => authAxios.get(`api/v1/candidate/details/${volunteerDetail.candidateId}`),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const filteredscript = fetchScriptsData?.find(
      (item: any) =>
        item.contactType == "By Phone" && item.usageScope === "volunteer"
    );
    const script: string = filteredscript?.script;

    if (candidateInfo?.data?.id && votersToContactByPhone?.length) {
      const customScriptMappedObject: { [key: string]: string | undefined } = {
        "Volunteer Name": fullName ?? "",
        "Voter Name": votersToContactByPhone[0].name ?? "",
        "Candidate Name": candidateInfo?.data.name ?? "",
        "City": candidateInfo?.data.city ?? "",
        "Position": candidateInfo?.data.position ?? "",
        "Strength 1":
          candidateInfo?.data?.strengths[0] ?? "",
        "Strength 2":
          candidateInfo?.data?.strengths[1] ?? "",
      };
      const finalScriptTemp = replaceMultipleWords(
        script,
        customScriptMappedObject
      );
      setFinalScript(finalScriptTemp);
    }
    if (filteredscript?.isActive === true) {
      setSelectedOption("defaultScript");
      return;
    }
    if (filteredscript?.isActive === false) {
      setSelectedOption("customScript");
    }
  }, [fetchScriptsData, activeIndex, candidateInfo, votersToContactByPhone]);

  return {
    votersToContactByPhone,
    activeIndex,
    votersCount,
    isScriptActive,
    buttonText,
    voterStatusTooltip,
    losTooltip,
    submitRef,
    VOTER_STATUS,
    LEVEL_OF_SUPPORT,
    selectedVoterStatus,
    selectedLevelOfSupport,
    handleScript,
    setSelectedVoterStatus,
    setSelectedLevelOfSupport,
    submitVoterResponse,
    isActivePopup,
    autoDialerStatus,
    verifiedPhoneNumber,
    volunteerDetailQueryStatus,
    handleModalClick,
    handleCancelModal,
    handlePatchCallConcurrency,
    replaceMultipleWords,
    setSelectedOption,
    selectedOption,
    finalScript,
    candidateInfo,
    fetchScriptsData
  };
};
