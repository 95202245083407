import React from 'react'

const InfoIconLight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M18 9.00049C18 13.9711 13.9706 18.0005 9 18.0005C4.02944 18.0005 0 13.9711 0 9.00049C0 4.02993 4.02944 0.000488281 9 0.000488281C13.9706 0.000488281 18 4.02993 18 9.00049ZM10.125 13.5005C10.125 14.1218 9.62132 14.6255 9 14.6255C8.37868 14.6255 7.875 14.1218 7.875 13.5005C7.875 12.8792 8.37868 12.3755 9 12.3755C9.62132 12.3755 10.125 12.8792 10.125 13.5005ZM9 3.37549C8.37868 3.37549 7.875 3.87917 7.875 4.50049V9.00049C7.875 9.62181 8.37868 10.1255 9 10.1255C9.62132 10.1255 10.125 9.62181 10.125 9.00049V4.50049C10.125 3.87917 9.62132 3.37549 9 3.37549Z" fill="white"/>
</svg>
  )
}

export default InfoIconLight
