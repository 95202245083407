interface ICallerId {
  phoneNumber: string | null
}

const CallerId = ({ phoneNumber }: ICallerId) => {
  return (
    <div className="VoterCallId bg-white shadow-2 px-2 py-1 rouded-1">
      {phoneNumber ? (
        <p>Your caller id is {formatPhoneNumber(phoneNumber)}</p>
      ) : (
        <p>No phone number!</p>
      )}
    </div>
  )
}

export default CallerId

const formatPhoneNumber = (input: string) => {
  const cleanedValue: string = input.replace(/\D/g, '').slice(-10)
  let formattedValue: string = ''

  if (cleanedValue.length <= 3) {
    formattedValue = cleanedValue
  }
  else if (cleanedValue.length > 3 && cleanedValue.length <= 6) {
    formattedValue = `(${cleanedValue.substring(0, 3)}) ${cleanedValue.substring(3)}`
  }
  else if (cleanedValue.length >= 6) {
    formattedValue = `(${cleanedValue.substring(0, 3)}) ${cleanedValue.substring(3, 6)} - ${cleanedValue.substring(6)}`
  }

  return formattedValue
};
