import React from 'react'

const HelpIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
<path d="M8.47766 9C9.02678 7.83481 10.5084 7 12.2501 7C14.4592 7 16.2501 8.34315 16.2501 10C16.2501 11.3994 14.9724 12.5751 13.2443 12.9066C12.7019 13.0106 12.2501 13.4477 12.2501 14M12.25 17H12.26M21.25 12C21.25 16.9706 17.2206 21 12.25 21C7.27944 21 3.25 16.9706 3.25 12C3.25 7.02944 7.27944 3 12.25 3C17.2206 3 21.25 7.02944 21.25 12Z" stroke="#858599" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default HelpIcon
